import ApiService from "../../../core/services/ApiService";

const state = {
  data: []
};

const actions = {
  downloadRosterFile({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let queryString = "";
      if (payload.jobs) {
        payload.jobs.forEach(jobId => {
          queryString =
            queryString === "" ? `jobs=${jobId}` : queryString + `,${jobId}`;
        });
      } else if (payload.client) {
        queryString = `client=${payload.client}&start=${payload.startDate}&end=${payload.endDate}`;
      }
      ApiService.download(`/api/v2`, `roster?${queryString}`)
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Crew-roster.xlsx"); // or any other extension
          document.body.appendChild(link);
          link.click();
          resolve();
        })
        .catch(err => {
          let message =
            "An error occurred while downloading the file. Please try again.";
          if (err.code === 404) {
            message = "No data found for the selected date range";
          }
          reject(message);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  actions
};
