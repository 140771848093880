import ApiService from "../../../core/services/ApiService";
import Swal from "sweetalert2";

/* eslint-disable */
function initialState() {
    return {
        isAwaitingResponse: false,
        data: [],
        loading: false,
        userID: ""
    }
}

const getters = {
    isAwaitingResponse: state => state.isAwaitingResponse,
    data: state => state.data,
    loading: state => state.loading
}

const actions = {
    fetchSkills({commit}, userId) {
        commit('setUserID', userId)
        return new Promise((resolve, reject) => {
         ApiService.get('api/v2' , `users/showSkills/${userId}`).then(
                response => {
                    commit('setData' , response.data.data)
                }).catch(error => {
                    commit('setError', error)
                    reject();
                }).finally(() => {
                    resolve();
            })})
    },
    setLoading({ commit }, value) {
        commit("setLoading", value)
    },
    removeSkill({state, dispatch, commit }, payload) {
        commit("setAwaitResponse", true)
        return new Promise((resolve, reject) => {
             ApiService
            .post(
                "/api/v2/users/skills" , payload
            )
            .then(() => {
                commit("setAwaitResponse", false)
                dispatch("fetchSkills", state.userID).then(() => { 
                    resolve();              
                    Swal.fire({
                        toast: true,
                        position: 'top-end',
                        icon: 'success',
                        title: `Skill deleted`,
                        showConfirmButton: false,
                        timer: 3000
                    })
                })
            })
            .catch(error => {
                commit("setError", error.response.data.message);
                reject();
            })});
    }, 
    addNewSkill({ state, commit, dispatch }, values) {
        const payload = {
            skills: values.skills,
            user_id: values.user_id
        };
        return new Promise((resolve, reject) => {
        ApiService.post(
            "/api/v2/users/skills" , payload
        )
        .then(() => {
            const payloadXPEndpoint = {
                user_id: values.user_id,
                experience: values.experience,
                skill_id: values.newSkillID,
                level_id: 1
            }
            ApiService
            .post(
                "/api/v2/users/experience" , payloadXPEndpoint
            )
            .then(() => {
                //...
            })
            .catch(error => {
                commit("setError", error.response.data.message);
            })
            .finally(() => {
                //...
            });
        })
        .catch(error => {
            commit("setError", error.response.data.message);
            reject();
        })
        .then(() => {
            commit("setAwaitResponse", false)
            dispatch("fetchSkills", state.userID).then(() => {
                resolve();
                Swal.fire({
                    toast: true,
                    position: 'top-end',
                    icon: 'success',
                    title: `Skill added succesfully`,
                    showConfirmButton: false,
                    timer: 3000
                })
            })
        })});
      
    },
    editSkill({ state, commit, dispatch }, values) {
        const payload = { 
            experience: values.data.experience,
            user_id: values.userId,
            level_id: values.data.levelId,
            skill_id: values.pivotData.skill_id
        }
        ApiService
            .post(
                "/api/v2/users/experience" , payload
            )
            .then(() => {
                //...
            })
            .catch(error => {
                commit("setError", error.response.data.message);
            })
            .finally(() => {
                dispatch("fetchSkills", state.userID)
            });
    },
    resetState({ commit }) {
        commit("resetState")
    }
}
const mutations = {
    setError(state, value) {
        state.error = value
    },
    setData(state, value) {
        state.data = value;
    },
    setAwaitResponse(state, value) {
        state.isAwaitingResponse = value;
    },
    setLoading(state, value) {
        state.loading = value
    },
    resetState(state) {
        state = Object.assign(state, initialState());
    },
    setUserID(state, value) {
        state.userID = value;
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}

