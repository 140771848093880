import ApiService from "../../../core/services/ApiService";

/* eslint-disable */
function initialState() {
    return {
        freelancers: {},
        loading: false,
        pagination: {
            currentPage: 1,
            lastPage: 1
        },
        error: '',
        searchedSkillIDs: [],
        searchString: '',
        searchedBranchID: '',
        sortParams: {
            sortedBy: "last_name", //These were the default values used in wci2020 - v1
            isOrderAsc: true
        }
    };
};

const getters = {
    loading: state => state.loading,
    getFreelancersList: state => state.freelancers,
    getPaginationData: state => state.pagination,
    getSortData: state => state.sortParams,
    getSearchString: state => state.searchString,
    getBranchID: state => state.searchedBranchID
}

const actions = {
    fetchData({ commit , state}) {
        commit("setLoading", true);
            ApiService
            .get(
                "/api/v2",
                "freelancers?perPage=10" + 
                '&page='+ state.pagination.currentPage +
                "&sort=" + state.sortParams.sortedBy + 
                "&order=" + ((state.sortParams.isOrderAsc) ? "asc" : "desc") +
                ((state.searchString == '') ? '' : "&filter[search]=" + state.searchString) +
                ((state.searchedBranchID == '') ? '' : "&filter[branch]=" + state.searchedBranchID) +
                ((state.searchedSkillIDs === "" || state.searchedSkillIDs.length == 0) ? '' : "&filter[skills]=" + state.searchedSkillIDs)
              )
                .then(response => {
                    commit('setFreelancersList' , response.data.data);
                    commit('setPaginationData', response.data.meta);
                })
                .catch(error => {
                    commit("setError", error);
                })
                .finally(() => {
                    commit("setLoading", false);
                });
    },
    fetchFreelancersWithSearchedSkills({commit, state , dispatch}, query) {
        if(query.skill !== "") {
            ApiService.get(`/api/v2`, 
            `sub-skills?filter[parent_id]=` + ((query.skill == '') ? null : query.skill)
            ).then(response => {
              if(query.reset) commit('resetPageNumber');
              commit('setCurrentSearchedSkillIDs' , response.data.data);
              commit('setPaginationData', response.data);
            }).finally(() => {
              dispatch('fetchData');
             })
        }
        else {
            commit("setCurrentSearchedSkillIDs" , '');
            dispatch('fetchData');
        }
    },
    setCurrentPageNumber({commit}, value) {
        commit("setCurrentPage" , value);
    },
    setSearchBySkill({commit} , value) {
        commit('setSkillSearchFilter', value);
    },
    setSearchString({commit} , value) {
        commit('setSearchString', value);
    },
    setSearchByBranch({commit}, value) {
        commit('setSearchByBranch', value);
    },
    setSortParams({commit}, value){
        commit("setSortParams" , value);
        commit('resetPageNumber');
    },
    setLoading({commit}, value) {
        commit('setLoading', value);
    },
    resetPageNumber({commit}){
        commit("resetPageNumber")
    },
    resetSortParams({commit}){
        commit("resetSortParams")
    },
    resetState({ commit }) {
        commit("resetState");
    }
}
const mutations = {
    setLoading(state, value) {
        state.loading = value;
    },
    setFreelancersList(state, value) {
        state.freelancers = value;
    },
    setCurrentSearchedSkillIDs(state , value) {
      if(value !== '') {
         state.searchedSkillIDs = [];
         for(let i = 0 ; i < value.length ; i ++) {
             state.searchedSkillIDs.push(value[i].id);
         }
      }else state.searchedSkillIDs = value
    },
    setSkillSearchFilter(state, value) {
        state.searchedSkillID = value;
    },
    setError(state , value) {
        state.error = value;
    },
    setPaginationData(state, value) {
        state.pagination.lastPage = value.last_page;
    },
    resetPageNumber(state) {
        state.pagination.currentPage = 1;
    },
    setCurrentPage(state, value) {
        state.pagination.currentPage = value;
    },
    setSearchString(state, value) {
        state.searchString = value;
    },
    setSearchByBranch(state, value) {
        state.searchedBranchID = value;
    },
    resetState(state) {
        state = Object.assign(state, initialState());
    },
    setSortParams(state , value) {
        state.sortParams = value;
    },
    resetSortParams(state){
        state.sortParams.sortedBy = "last_name",
        state.sortParams.isOrderAsc = true;
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
