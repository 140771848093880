
import { defineComponent, ref } from "vue";
import { Form, ErrorMessage, Field } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import Swal from "sweetalert2";

export default defineComponent({
  name: "invite-a-friend-modal",
  components: {
    Form,
    Field,
    ErrorMessage
  },
  setup(props, { emit }) {
    const store = useStore();
    const email = ref<string>("");
    const loading = ref<boolean>(false);
    const validationSchema = Yup.object().shape({
      email: Yup.string()
        .required()
        .email()
        .label("Email")
    });

    function handleSubmit() {
      loading.value = true;
      store
        .dispatch("UsersModule/sendInviteAFriendMail", email.value)
        .then(() => {
          loading.value = false;
          emit("success");
          Swal.fire({
            toast: true,
            position: "top-right",
            icon: "success",
            title: "Invite send successfully",
            showConfirmButton: false,
            timer: 1300
          });
        })
        .catch(error => {
          loading.value = false;
          Swal.fire({
            toast: true,
            position: "top-right",
            icon: "error",
            title: error,
            showConfirmButton: false,
            timer: 1300
          });
        });
    }

    return {
      email,
      loading,
      handleSubmit,
      validationSchema
    };
  }
});
