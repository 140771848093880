import ApiService from "../../../core/services/ApiService";

/* eslint-disable */
function initialState() {
    return {
        rates: [],
        skills: [],
        levels: [],
        custom_rates: [],
        isLoading: false,
        isFetchingSingle: false,
        isSaving: false
    }
}

const getters = {
    skills: state => state.skills,
    levels: state => state.levels,
    loading: state => state.isLoading,
    isSaving: state => state.isSaving,
    isFetchingSingle: state => state.isFetchingSingle,
    existingRates: state => state.rates,
    customRates: state => state.custom_rates
}

const actions = {
    fetchLevels({ commit }) {
        ApiService.get(`/api/v2`, `levels`).then(response => {
            commit('setLevels', response.data.data)
        })
    },
    fetchSkills({ dispatch ,commit }) {
        ApiService.get(`/api/v2`, `skills?sort=order&order=asc`
            ).then(response => {
                commit('setSkills', response.data.data);
                dispatch("fetchFrequentSkills");
            })
    },
    fetchFrequentSkills({ commit }) {
        ApiService.get('api/v2', 'rates/workers/skills').then(response => {
            commit("setFreqSkills", response.data.data);
        })
    },
    store({ commit }, data) {
        commit("setIsSaving", true);
        return new Promise((res, rej) => {
            const payload = {
                rates: data.rates,
                currency_id: data.currencyId
            }
            ApiService.post("api/v2/worker-rates/save", payload)
                    .then(response => {
                        res(response.data.message);
                        commit("setIsSaving", false);
                    })
                    .catch(err => {
                        rej(err);
                        commit("setIsSaving", false);
                    })
        })
    },
    fetchOne({ commit }, query) {
        commit("setIsFetchingSingle", true)
        return new Promise((res, rej) => {
        ApiService.post("api/v2/worker-rates/single", query)
                .then(response => {
                    commit("setCustomRate", response.data)
                    res(response);
                })
                .catch(err => {
                    rej(err);
                })
                .finally(() => {
                    commit("setIsFetchingSingle", false)
                })
            })
    },
    fetchRates({ commit }, data) {
        commit("isLoading", data.triggerLoad);
        const query = {
            skill_id: data.query.skillID,
            branch_id: data.query.branchID
        }
        ApiService.post('api/v2/worker-rates', query)
                .then(response => {
                    commit("setRates", response.data.data)
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    commit("isLoading", false);
                })
    },
    fetchBranch({}, branchId) {
        return new Promise((resolve, reject) => {
           ApiService.get("api/v2", "branches/" + branchId)
             .then((response) => {
                 resolve(response.data.data);
             })
             .catch((err) => {
                 reject(err);
             })
        });
    }
}
const mutations = {
    setSkills(state, data) {
        state.skills = data;
    },
    setLevels(state, data) {
        state.levels = data;
    },
    isLoading(state, value) {
        state.isLoading = value;
    },
    setIsSaving(state, value) {
        state.isSaving = value;
    },
    setRates(state, value) { 
        state.rates = value;
    },
    setFreqSkills(state, data) {
        state.skills.unshift({
            name: "Most Booked Skills",
            id: "frequent",
            children: data
        })
    },
    setCustomRate(state, data) {
        state.custom_rates = data;
    },
    setIsFetchingSingle(state, value) {
        state.isFetchingSingle = value;
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
