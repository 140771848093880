import Swal from "sweetalert2";

export const fireToast = (text: string, result: boolean): void => {
  Swal.fire({
    position: "top-end",
    icon: result ? "success" : "error",
    title: text,
    timer: 800,
    toast: true,
    showConfirmButton: false,
    showCancelButton: false
  });
};
