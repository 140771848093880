import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_CookieNotification = _resolveComponent("CookieNotification")!
  const _component_InviteFriendModal = _resolveComponent("InviteFriendModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_view),
    (!_ctx.hasAgreedToCookies)
      ? (_openBlock(), _createBlock(_component_CookieNotification, {
          key: 0,
          class: "stickToBottomOfWindow",
          onAgree: _ctx.agreeToCookies
        }, null, 8, ["onAgree"]))
      : _createCommentVNode("", true),
    (_ctx.$can('can_invite_workers', 'all'))
      ? (_openBlock(), _createBlock(_component_InviteFriendModal, {
          key: _ctx.modalKey,
          onSuccess: _ctx.closeInviteModal
        }, null, 8, ["onSuccess"]))
      : _createCommentVNode("", true)
  ], 64))
}