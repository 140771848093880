import ApiService from "../../core/services/ApiService";

/* eslint-disable */
function initialState() {
    return {
        qrImage: null,
        loading: false,
        code: null,
    };
};

const getters = {
    qrImage: state => state.loading,
    loading: state => state.loading,
    code: state => state.code
}

const actions = {
    fetchQrImage({ commit , state}) {
        commit("setLoading", true);
        ApiService
            .get(
                "/api/v2",
                "otp")
            .then(response => {
                commit("setQrImage", response.data.image);
                commit("setCode", response.data.code);
            })
            .catch(error => {
                commit("setError", error);
            })
            .finally(() => {
                commit("setLoading", false);
            });
    },
    store({ commit, dispatch }, otpCode) {
        commit("setLoading", true);
        return ApiService
            .post(
                "/api/v2/otp", {
                    otp: otpCode
                })
            .catch((err) => {
                reject(err);
            })
            .finally(() => {
                commit("setLoading", false);
            });
    },
    removeTwoFactorAuth({ commit }, password) {
        return new Promise((resolve, reject) => {
           ApiService.post("/api/v2/otp/disable", {password: password})
             .then(() => {
                 resolve();
             })
             .catch((err) => {
                 reject(err);
             })
        });
    }
}
const mutations = {
    setLoading(state, value) {
        state.loading = value;
    },
    resetState(state) {
        state = Object.assign(state, initialState());
    },
    setEror(state, value) {
        state.error = value;
    },
    setQrImage(state, value) {
        state.qrImage = value;
    },
    setCode(state, value) {
        state.code = value;
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
