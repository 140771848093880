/* eslint-disable */
import ApiService from "../../../core/services/ApiService";
import Swal from "sweetalert2";
import router from "@/router";

function initPayment() {
  return {
    company_name: "",
    has_danish_bank: 0,
    has_tax_id: 0,
    iban: "",
    konto: "",
    reg: "",
    social_security: "",
    swift: "",
    tax_card: "",
    tax_id: "",
    user_id: getters.getSingleUser.id,
  }
}

function initialState() {
  return {
    all: [],
    singleUser: {},
    relationships: {
      branches: "name",
      country: "title",
      languages: "name",
      skills: "name",
      company: "name",
      invited_by: "name",
      role: "title",
      favorite_jobs: "name"
    },
    mySkills: {},
    myExperiences: {},
    loading: false,
    referredUsers: [],
    message: "",
    usersList: [],
    search: "",
    parent_skill: "",
    sub_skill: "",
    pagination: {
      current_page: 1,
      last_page: 1
    }
  };
}

const getters = {
  data: state => {
    let rows = state.all;

    if (state.search && state.search.length > 0) {
      rows = rows.filter(
        user => user.name.toLowerCase().indexOf(state.search.toLowerCase()) >= 0
      );
    }

    if (state.query.sort) {
      rows = _.orderBy(rows, state.query.sort, state.query.order);
    }

    return rows.slice(
      state.query.offset,
      state.query.offset + state.query.limit
    );
  },
  total: state => state.all.length,
  loading: state => state.loading,
  relationships: state => state.relationships,
  mySkills: state => state.mySkills,
  myExperiences: state => state.myExperiences,
  all: state => state.all,
  usersList: state => state.usersList,
  getPaginationData: state => state.pagination,
  getSingleUser: state => state.singleUser,
  getSearchString: state => state.search,
  getReferredUsers: state => state.referredUsers,
  getParentSkill: state => state.parent_skill,
  getSubSkill: state => state.sub_skill
};

const actions = {
  fetchData({ commit, state }, params = {}) {
    commit("setLoading", true);
    
    ApiService
      .get(
        "/api/v2",
        'users?page='+
        state.pagination.current_page +
        "&perPage=10" +
        ((state.search == "") ? "" : '&filter[search]=' + state.search)
          + `${state.sub_skill == "" ? '' : "&sub_skill="+state.sub_skill}`
          + `${state.parent_skill == "" ? '' : "&parent_skill="+state.parent_skill}`
      )
      .then(response => {
        commit("setUsersList", response.data.data);
        commit("setPagination", response.data);
      })
      .catch(error => {
        message = error.response.data.message || error.message;
        commit("setError", message);
        console.log(message);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  destroyData({ commit, dispatch}, id) {
    ApiService
        .delete('/api/v2/users/' + id)
        .then(() => {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: `User deleted successfully`,
            showConfirmButton: false,
            timer: 2000
          })
        })
        .catch(error => {
          message = error.response.data.message || error.message
          commit('setError', message)
          console.log(message)
        })
        .finally(() => {
          dispatch("fetchData");
        });
  },

  changeUserStatus({ commit, state, dispatch}, payload) {
    ApiService
        .post(`/api/v2/users/${payload.id}/change-status`,{status: payload.status})
        .then(() => {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: `User ${payload.status == 'active' ? 'activated' : 'deactivated'} successfully`,
            showConfirmButton: false,
            timer: 2000
          })
        })
        .catch(error => {
          message = error.response.data.message || error.message
          commit('setError', message)
          console.log(message)
        })
        .finally(() => {
            dispatch('fetchData');
          }
        )
  },
  checkEmailFree({ commit }, email) {
    return new Promise((resolve, reject) => {
      ApiService.post("api/v2/check-email-free", { email : email })
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        })
    })
  },
  createCOL({ commit }, data) {
    const payload = {
      first_name: data.name,
      role: [data.role],
      phone: data.phone,
      email: data.email,
      company_id: data.company,
      password: data.password != "" ? data.password : null,
      approved: 1
    }
    return new Promise((resolve, reject) => {
      ApiService.post("api/v2/users", payload).then(response => {
        resolve(response.data.data);
      }).catch(error => {
        reject(error);
      })
    })
  },
  getUserById({commit}, value) {
    commit("setLoading", true)
    ApiService.get(
      `/api/v2/users/` + value
    ).then(
      response => {
        commit("setFetchedUserById" , response.data.data);
        commit("setLoading", false)
      }
    ).catch(err => {
      router.push("/");
    })
  },
  update({ dispatch }, value) {
    return new Promise((res, rej) => {
        ApiService.update(
          `/api/v2/users` , value.id , value.payload
        ).then(
          () => {
              dispatch("getUserById", value.id);
              res();
          }).catch(
            error => {
              rej(error);
          })
      })
  },
  updateProfilePicture({ commit }, data) {
    return new Promise((res, rej) => {
      const formData = new FormData();
      formData.append('file', data.file);
      ApiService.post(`api/v2/users/profilePicture/${data.id}`, formData).then(() => {
        res()
      }).catch(err => {
        rej(err);
      });
    });
  },
  fetchReferredUsers({ commit }, data) {
    return new Promise((resolve, reject) => {
      ApiService.get("api/v2", `users/referredBy/${data.id}?page=${data.page}&perPage=5`)
        .then(response => {
          commit("setReferredUsers", response.data.data);
          resolve(response.data.last_page);
        })
        .catch(error => {
          reject(error);
         })
    })
  },

  sendInviteAFriendMail({}, email) {
    return new Promise((resolve, reject) => {
      ApiService.post("api/v2/sendinvitemail", { email: email })
        .then(response => {
          resolve(response.last_page);
        })
        .catch(error => {
          reject(error);
         })
    })
  },

  fetchUnpaidEarnings({}, userId) {
    return new Promise((resolve, reject) => {
      ApiService.get("api/v2", 'statistics/unpaid-earnings' + (userId ? ('?user_id=' + userId) : ''))
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    })
  },

  updatePayment({ dispatch }, value) {
    return new Promise((res, rej) => {
    ApiService.update(
      `/api/v2/payment-information` , value.id , value.payload
    ).then(
      () => {
        dispatch("getUserById", value.id)
        res();
      }).catch(
        error => {
          rej(error.response ? error.response : null);
        })
    })
  },
  setSearchInput({commit} , value){
    commit('setSearch' , value);
  },
  resetPageNumber({commit}){
    commit('resetPageNumber')
  },
  setSubSkill({commit}, payload) {
    commit("mutateSubSkill", payload);
  },
  setParentSkill({commit}, payload) {
    commit("mutateParentSkill", payload);
  },
  setPageNumber({commit} , value){
    commit('setPageNumber', value)
  },
  resetState({ commit }) {
    commit("resetState");
  }
};

const mutations = {
  setAll(state, items) {
    state.all = items;
  },
  mutateParentSkill(state, value) {
    state.parent_skill = value;
  },
  mutateSubSkill(state,value) {
    state.sub_skill = value;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  resetState(state) {
    state = Object.assign(state, initialState());
  },
  setReferredUsers(state, data) {
    state.referredUsers = data;
  },
  setMySkills(state, skills) {
    state.mySkills = skills;
  },
  addSkill(state, skill) {
    state.mySkills.push(skill);
  },
  removeSkill(state, id) {
    state.mySkills = state.mySkills.filter(skill => skill.id !== id);
  },
  setUsersList(state, items) {
    state.usersList = items;
  },
  setSearch(state , value){
    state.search = value
  },
  setPagination(state, value) {
    state.pagination.last_page = value.last_page;
  },
  setPageNumber(state, value){
    state.pagination.current_page = value
  },
  setFetchedUserById(state, value) {
    if(value.payment_settings == null) {
      value.payment_settings = initPayment();
    }
    state.singleUser = value;

  },
  resetPageNumber(state){
    state.pagination.current_page = 1
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
};
