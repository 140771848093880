import ApiService from "../../../core/services/ApiService";

/* eslint-disable */
function initialState() {
    return {
        baseCost: null,
        client_currency: null,
        job_projection: {}
    }
}

const getters = {
    getBaseCosts: state => state.baseCost,
    getJobProjection: state => state.job_projection,
    getClientCurrency: state => state.client_currency
}

const actions = {
    fetchAdditionalCosts({ commit }, jobID) {
        return new Promise((resolve, reject) => {22
            ApiService.get(`api/v2` ,`jobs/additional-costs/${jobID}`)
                .then(response => {
                    resolve(response.data.data);
                })
                .catch(error => {
                    reject(error);
                })
        })
    },
    fetchJobProjection({ commit }, jobID) {
        return new Promise((resolve, reject) => {
            ApiService.get('api/v2', `jobs/${jobID}/projection`)
                .then(response => {
                    commit('setJobProjection', response.data);
                })
                .finally(() => {
                    resolve();
                })
        })

    },
    fetchClientCurrency({ commit }, data) {
        const query = {
            company_id: data.company,
            branch_id: data.branch
        }
        ApiService.post("api/v2/rates/client/get-currency", query).then(response => {
            commit("setClientCurrency", response.data);
        });
    },
    deleteAdditionalCost({ commit }, cost) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`api/v2/jobs/additional-costs/${cost.id}`)
                .then(response => {
                    commit('updateAdditionalCosts', response.data.data);
                    resolve(response.data.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    },
    saveAdditionalCosts({commit}, query) {
      return new Promise((resolve, reject) => {
          ApiService.post(`api/v2/jobs/${query.jobID}/additional-costs/save`, query)
              .then(response => {
                  resolve(response);
              })
              .catch(err => {
                  reject(err);
              })
      })
    },
    resetState({ commit }) {
        commit("resetState");
    }
}
const mutations = {
    resetState(state) {
        state = Object.assign(state, initialState())
    },
    setJobProjection(state, payload) {
        state.job_projection = payload;
    },
    setClientCurrency(state, value) {
        state.client_currency = value;
    },
    updateAdditionalCosts(state, data) {
        state.job_projection.additionalCosts = data;
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
