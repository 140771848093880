import ApiService from "../../../core/services/ApiService";
import Swal from "sweetalert2";

/* eslint-disable */
function initialState() {
  return {
    skillsList: [],
    loading: false,
    error: '',
    pagination: {
      current_page: 1,
      last_page: 1
    }
  }
}

const getters = {
  skillsList: state => state.skillsList,
  getPaginationData: state => state.pagination,
  loading: state => state.loading
}

const actions = {
  fetchData({commit, state}, query) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true);
      let queryString = "";
      if(query) {
        if(query.parentId) {
          queryString = `&filter[parent_id]=${query.parentId}`;
        } else {
          queryString = "&filter[parent_id]=null";
        }
      }
      ApiService.get('api/v2', 'skills?page=' + state.pagination.current_page + '&perPage=10' + queryString)
        .then(response => {
          commit('setSkillsList' , response.data.data)
          commit("setPagination", response.data)
        })
        .catch(error => {
          commit('setError', error)
          reject(error);
        })
        .finally(() => {
          resolve();
          commit('setLoading' , false)
        });
    })

  },
  createNew({commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      ApiService
          .post(
              "/api/v2/skills", payload
          )
          .then(() => {
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: `Skill created successfully`,
              showConfirmButton: false,
              timer: 2000
            })
            resolve();
          })
          .catch(error => {
            commit("setError", error);
            reject();
          });
    })

  },

  update({ commit , dispatch}, payload) {
    return new Promise((resolve, reject) => {
      commit("setLoading", true);
      ApiService
        .update(
          "/api/v2/skills", payload.id, payload.values
        )
        .then(() => {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: `Skill edited successfully`,
            showConfirmButton: false,
            timer: 2000
          });
          resolve();
        })
        .catch(error => {
          commit("setError", error);
          reject();
        })
        .finally(() => {
          commit("setLoading", false)
        });
    });
  },

  destroyData({commit, dispatch}, id) {
    return new Promise((resolve, reject) => {
      ApiService
        .delete(
          "/api/v2/skills/" + id
        )
        .then(() => {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: `Skill deleted successfully`,
            showConfirmButton: false,
            timer: 2000
          })
          resolve();
        })
        .catch(error => {
          commit("setError", error);
          reject();
        });
    })
  },

  saveWeights({ commit, dispatch }, value) {
    return new Promise((resolve, reject) => {
      ApiService
        .post(
            '/api/v2/skills/save-weights' , value
        )
        .then(() => {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: `Weights set successfully`,
            showConfirmButton: false,
            timer: 2000
          })
          resolve();
        })
        .catch(error => {
          commit("setError", error);
          reject();
        })
    })

  },

  resetPageNumber({commit}){
    commit('resetPageNumber')
  },
  setPageNumber({commit} , value){
    commit('setPageNumber', value)
  },
  resetState({ commit }) {
    commit("resetState");
  }
}
const mutations = {
  setSkillsList(state, value) {
    state.skillsList = value
  },
  setError(state, value) {
    state.error = value
  },
  setLoading(state, value) {
    state.loading = value
  },
  setPagination(state, value) {
    state.pagination = value;
  },
  setPageNumber(state, value){
    state.pagination.current_page = value
  },
  resetState(state) {
    state = Object.assign(state, initialState())
  },
  saveWeights({ commit, dispatch }, payload) {
    console.log(payload);
    ApiService
        .post(
            '/api/v2/skills/save-weights' , payload
        )
        .then()
        .catch(error => {
          commit("setError", error);
        })
        .finally(() => {
          dispatch("fetchData");
        });
  }
}

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
}
