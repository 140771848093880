import ApiService from "../../../core/services/ApiService";
import Swal from "sweetalert2";

/* eslint-disable */
function initialState() {
  return {
    branchesList: [],
    loading: false,
    error: '',
    pagination: {
      current_page: 1,
      last_page: 1
    }
  }
}

const getters = {
  branchesList: state => state.branchesList,
  getPaginationData: state => state.pagination,
  loading: state => state.loading
}

const actions = {
  fetchData({commit, state}) {
    commit('setLoading', true);

    ApiService.get('api/v2',
        'branches?page=' + state.pagination.current_page + '&perPage=10')
        .then(
        response => {
          commit('setBranchesList' , response.data.data)
          commit("setPagination", response.data)
        }).catch(error => {
      commit('setError', error)
      console.log(error)
    }).finally(() => {
      commit('setLoading' , false)
    });
  },
  createNew({commit, dispatch }, payload) {
    ApiService
    .post(
        "/api/v2/branches" , payload
      )
      .then(() => {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: `Branch created successfully`,
          showConfirmButton: false,
          timer: 2000
        })
      })
        .catch(error => {
            commit("setError", error);
            if (error.response.data.errors.name) {
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: error.response.data.errors.name,
                showConfirmButton: false,
                timer: 2500
              })
            } else {
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'A network error has occurred',
                showConfirmButton: false,
                timer: 2000
              })
            }
        })
        .finally(() => {
            dispatch("fetchData");
        });
  },
  update({ commit, dispatch }, payload) {
          commit("setLoading", true);
          ApiService
          .update(
              "/api/v2/branches", payload.id, payload.values
            )
            .then(() => {
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: `Branch edited successfully`,
                showConfirmButton: false,
                timer: 2000
              })
            })
              .catch(error => {
                  commit("setError", error);
                  if (error.response.status == 500) {
                    Swal.fire({
                      position: 'top-end',
                      icon: 'error',
                      title: 'Another branch with this name already exists',
                      showConfirmButton: false,
                      timer: 2500
                    })
                  } else {
                    Swal.fire({
                      position: 'top-end',
                      icon: 'error',
                      title: 'A network error has occurred',
                      showConfirmButton: false,
                      timer: 2000
                    })
                  }
              })
              .finally(() => {
                  dispatch("fetchData");
                  commit("setLoading", false)
              });
  },
  destroyData({commit, dispatch}, value) {
    ApiService
    .delete(
        "/api/v2/branches/" + value
      )
        .then(() => {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: `Branch deleted successfully`,
            showConfirmButton: false,
            timer: 2000
          })
        })
        .catch(error => {
            commit("setError", error);
        })
        .finally(() => {
            dispatch("fetchData");
        });
  },
  resetPageNumber({commit}){
    commit('resetPageNumber')
  },
  setPageNumber({commit} , value){
    commit('setPageNumber', value)
  },
  resetState({ commit }) {
    commit("resetState");
  }
}
const mutations = {
  setBranchesList(state, value) {
    state.branchesList = value
  },
  setError(state, value) {
    state.error = value
  },
  setLoading(state, value) {
    state.loading = value
  },
  setPagination(state, value) {
    state.pagination = value;
  },
  setPageNumber(state, value){
    state.pagination.current_page = value
  },
  resetState(state) {
    state = Object.assign(state, initialState())
  },

}

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
}
