import ApiService from "../../../core/services/ApiService";

/* eslint-disable */
function initialState() {
  return {
    chatHistory: [],
    userConversation : [],
    unreadMessages: 0,
    awaitingResponse: []
  }
}

const getters = {
  getChatHistory: state => state.chatHistory,
  getConversation: state => state.userConversation,
  getUnreadMessages: state => state.unreadMessages
}

const actions = {
  fetchChatHistory({commit}) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/v2', 'user/chats')
        .then(response => {
          commit("setChatHistory", response.data.data);
        })
        .catch(err => {
          reject(err);
        })
        .finally(() => {
          resolve();
        })
    })
  },
  fetchUnreadMessages({ commit }) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/v2', 'unread-messages')
        .then(response => {
          commit("setUnreadMessages", response.data.count);
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          resolve();
        })
    })
  },
  addSingleItemToHistory({state}, payload) {
    state.chatHistory.unshift(payload);
  },
  updateLatestChat({commit}, payload) {
    commit("updateLatestChat", payload);
  },
  fetchConversation({commit}, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/v2', `chat/${payload.chat_id}/messages?page=${payload.page}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
        });
    })
  },
  sendMessage({commit}, payload) {
    return new Promise((resolve, reject) => {
      const requestBody = {
        body: payload.messageBody,
      };
      if(null != payload.chatId) {
        requestBody['chat_id'] = payload.chatId;
      }else {
        requestBody['target_user'] = payload.userId;
      }
      ApiService.post('api/v2/chat/send-message', requestBody)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        })
    });
  },
  handleChatMessageRead({dispatch, state}, chatId) {
    const chat = state.chatHistory.find(item => item.id === chatId);
    if(chat) {
      dispatch("fetchUnreadMessages");
      chat.unread_messages = 0;
    }
  },
  fetchUserActiveStatus() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
          resolve("Active");
      }, 150)
    })
  },
  searchUsers({commit}, queryString) {
    return new Promise((resolve, reject) => {
      if("string" !== typeof queryString) {
        reject("Badly formed data");
      }
      ApiService.get('api/v2', `search-users-chat?userName=${queryString}`)
        .then(response => {
          resolve(response.data.data);
        })
        .catch(err => {
          reject(err);
        });
    })
  },
  resetState({ commit }) {
    commit("resetState")
  },
  handleNewMessageFromWebSocket({ commit, state }, eventData) {
    const chat = state.chatHistory.find(item => item.id === eventData.message.chat_id);
    state.unreadMessages++;
    if(chat) {
      chat.unread_messages++;
      chat.last_message = eventData.message.created_at;
    }
  }
}
const mutations = {
  setChatHistory(state, payload) {
    if(null == payload) {
      state.chatHistory = [];
    } else {
      state.chatHistory = payload;
    }
  },
  updateLatestChat(state, payload) {
    if(payload) {
      const chat = state.chatHistory.find(item => item.id === payload.id);
      if(chat) {
        chat.last_message = payload.last_message;
      }
    }
  },
  setUserConversation(state, payload) {
    if(null == payload) {
      state.userConversation = [];
    } else {
      state.userConversation = payload;
    }
  },
  setUnreadMessages(state, value) {
    state.unreadMessages = value;
  },
  resetState(state) {
    Object.assign(state, initialState());
  },
}

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
}

