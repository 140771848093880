import ApiService from "../../../core/services/ApiService";

/* eslint-disable */
function initialState() {
    return {
        data: {},
        all: [],
        loading: false,
        pagination: {
            currentPage: 1,
            lastPage: 1
        },
        userID: ""
    };
};

const getters = {
    loading: state => state.loading,
    getData: state => state.data,
    getAll: state => state.all,
    getPagination: state => state.pagination
}

const actions = {
    fetchData({ commit, state }, id) {
            commit("setLoading", true);
            commit("setViewedUser", id);
            ApiService
            .get(
                "/api/v2", `activities/${id}?perPage=15&page=${state.pagination.currentPage}`)
                .then(response => {
                    commit("setData", response.data.data);
                    commit("setPagination", response.data.meta.last_page)
                })
                .catch(error => {
                    commit("setError", error);
                })
                .finally(() => {
                    commit("setLoading", false);
                });
    },
    fetchAdmin({ commit }, query) {
        return new Promise((resolve, reject) => {
            ApiService.get('api/v2', `activity-log?perPage=20&page=${query.page}${query.filter ? `&filter[log_name]=${query.filter}` : ''}`)
                .then(response => {
                    commit('setAll', response.data);
                })
                .catch(() => {
                    reject()
                })
                .finally(() => {
                    resolve();
                })
        })
    },
    resetState({ commit }) {
        commit("resetState");
    },
    changePage({ commit, dispatch, state }, pageNumber) {
        commit("changePage", pageNumber);
        dispatch("fetchData", state.userID);
    }
}
const mutations = {
    setLoading(state, value) {
        state.loading = value;
    },
    setAll(state, value) {
        state.all = value;
    },
    changePage(state, value) {
        state.pagination.currentPage = value;
    },
    setPagination(state, value) {
        state.pagination.lastPage = value;
    },
    setViewedUser(state, id) {
        state.userID = id;
    },
    resetState(state) {
        state = Object.assign(state, initialState());
    },
    setData(state, value) {
        state.data = value;
    },
    setEror(state, value) {
        state.error = value;
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
