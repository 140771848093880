import ApiService from "../../../core/services/ApiService";
import moment from "moment";
import Swal from "sweetalert2";

/* eslint-disable */
function initialState() {
    return {
        data: {},
        loading: false,
        userID: "",
        activeTab: "",
        branches: "",
        searchString: "",
        parameters: "",
        isAwaitingResponse: {
            isLoading: false,
            indexes: []
        },
        isLoadingEstimates: false,
        pagination: {
          currentPage: 1,
          lastPage: 1
        },
        estimatedEarning: {},
        newJobs: "",
        error: ""
    };
};

const getters = {
    loading: state => state.loading,
    shifts: state => state.data,
    getPagination: state => state.pagination,
    isAwaitingResponse: state => state.isAwaitingResponse,
    newJobs: state => state.newJobs,
    isLoadingEstimates: state => state.isLoadingEstimates,
    estimatedEarning: state => state.estimatedEarning,
}

const actions = {
    fetchShifts({ dispatch, commit , state }, parameters) {
            commit("setEstimatedEarnings", []);
            commit("setParameters", parameters)
            commit("setQueryState", { id: parameters.id , tab : parameters.tab});
            if(parameters.tab == "new") {
              return new Promise((resolve, reject) => {
                const pagination = `page=${state.pagination.currentPage}&perPage=10`;
                ApiService.get("api/v2/my-dashboard" , "new-jobs?" + pagination)
                  .then((response) => {
                    commit("setData", response.data.data);
                    commit("setPagination", response.data.meta.last_page)
                    dispatch("fetchEstimatedEarnings", response.data.data.map(item => item.id));
                    resolve();
                  })
                  .catch((err) => {
                    commit("setError", err.message);
                    reject();
                  });
              });
            }
            commit("setBranches", parameters.branches)
            const pagination = `page=${state.pagination.currentPage}&perPage=10`;
            const assignedTo = `&filter[assigned]=${state.userID}`;
            const searchString = (state.searchString !== "" ? `&filter[search]=${state.searchString}` : "")
            const branches = `&filter[branches]=${state.branches}`
            const activeTab = () => {
                switch(parameters.tab){
                    case 'my':
                        return `${assignedTo}${searchString}&filter[upcoming]=true&filter[applicationStatuses]=Booked&filter[withTrashed]=true&filter[otherJobCurrentStatus]=Draft&filter[sort]=start`;
                        break;
                    case 'past':
                        return `${assignedTo}${searchString}&filter[past]=true&filter[applicationNotStatuses]=Applied,Reserve,Requested,Denied&filter[otherJobCurrentStatus]=Draft&filter[sort]=start`;
                        break;
                    case 'new':
                        return `${searchString}&filter[onlyNew]=${state.userID}&filter[otherJobCurrentStatus]=Draft,Cancelled&filter[otherCurrentStatus]=Cancelled${branches}&filter[upcoming]=true&newJobs=true`;
                        break;
                    case 'pending':
                        return `${searchString}&filter[sort]=start&filter[withTrashed]=true&${assignedTo}&filter[upcoming]=true&filter[applicationStatuses]=Applied,Reserve,Requested,Denied,Request accepted,Request denied&filter[otherJobCurrentStatus]=Draft`;
                        break;
                }
            }
            const queryString = `${pagination}${activeTab()}&include=shift_slots.applications,job.company,job.venue,job.book_from_branches`
            let slug = `shifts?${queryString}`;
            if(parameters.tab === 'past') {
                slug = `my-dashboard/past-shifts?${queryString}`;
            }
            if(parameters.tab === 'my') {
                slug = `my-dashboard/my-shifts?${queryString}`;
            }
            return new Promise((resolve, reject) => {
            ApiService
            .get(
                `/api/v2`, slug)
                .then(response => {
                    dispatch("fetchEstimatedEarnings", response.data.data.map(item => item.id));
                    commit("setData", response.data.data);
                    commit("setPagination", response.data.meta.last_page)
                })
                .catch(error => {
                    commit("setError", error);
                    reject();
                })
                .finally(() => {
                    resolve()
                })})

    },
    setLoading({ commit }, value) {
        commit("setLoading", value);
    },
    fetchForNewJobsCounter({ commit, state }) {
        ApiService
            .get(
                `/api/v2/my-dashboard`, `new-jobs?count=true`)
                .then(response => {
                    commit("setNewJobsCounter", response.data.count)
                })
                .catch(error => {
                    commit("setError", error)
                })
                .finally(() => {
                    //...
                })
    },
    CheckInWorker({ commit, dispatch, state}, shift) {
        commit("setIsAwaitingResponse", { isLoading: true, index: shift.index });
        const payload = {
            application_id: shift.my_shift_slot.applications[0].id,
            assignee_id: shift.my_shift_slot.applications[0].assignee_id,
        }
       ApiService.post(
           `api/v2/my-dashboard/check-in`, payload
       ).then((res) => {
           state.data.find(s => s.id == shift.id).my_shift_slot.applications[0].check_in_at
               = res.data.check_in_at;
       }).catch((error) => {
           commit("setError", error);
       }).finally(() => {
           commit("setIsAwaitingResponse", { isLoading: false, index: shift.index });
        }
       )
    },
    async reportHours({ commit, dispatch, state }, data) {
      return new Promise((resolve, reject) => {
        commit("setIsAwaitingResponse", { isLoading: true, index: data.index });
        const payload = {
            id: data.shiftSlot.id,
            start: data.report.start,
            end: data.report.end,
            shift_slot_id: data.shiftSlot.my_shift_slot.id,
            created_by_id: state.userID,
            i_was_late: data.report.iwasLate ? 1 : 0,
            comment: data.report.comment
        }
        ApiService
        .post(
            "/api/v2/reported-hours", payload
          )
            .then((res) => {
                Swal.fire({
                    toast: true,
                    position: 'top-end',
                    icon: 'success',
                    title: `Hours reported successfully`,
                    showConfirmButton: false,
                    timer: 3000
                })
              resolve(res.data.data);
            })
            .catch(error => {
                reject();
                commit("setError", error);
            })
            .finally(() => {
                commit("setIsAwaitingResponse", { isLoading: false, index: data.index });
            });
      })
    },
    updateReport({ commit, dispatch, state}, data) {
      return new Promise((resolve, reject) => {
        commit("setIsAwaitingResponse", { isLoading: true, index: data.index });
        const payload = {
            created_by_id: state.userID,
            shift_slot_id: data.reportedHours[0].shift_slot_id,
            id: data.reportedHours[0].id,
            start: data.report.start,
            end: data.report.end,
            i_was_late: data.report.iwasLate ? 1 : 0,
            comment: data.report.comment
        }
        ApiService
        .update(
            "/api/v2/reported-hours", data.reportedHours[0].id, payload
          )
            .then((res) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: `Hours updated successfully`,
                    showConfirmButton: false,
                    timer: 800
                });
                resolve(res.data);
            })
            .catch(error => {
                commit("setError", error);
                reject(error);
            })
            .finally(() => {
                commit("setIsAwaitingResponse", { isLoading: true, index: data.index });
            });
      })

    },
    deleteReport({ commit, dispatch, state }, payload) {
      return new Promise((resolve, reject) => {
        commit("setIsAwaitingResponse", { isLoading: true, index: payload.index });
        ApiService
          .delete(
            "/api/v2/reported-hours/" + payload.reportId
          )
          .then(() => {
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: `Report deleted successfully`,
              showConfirmButton: false,
              timer: 800
            })
            const shiftToChange = state.data.find(shift => shift.id == payload.shiftId).my_shift_slot;
            shiftToChange.reported_hours = [];
            shiftToChange.applications[0].status = "Report!";
            resolve();
          })
          .catch(error => {
            reject(error);
            commit("setError", error);
          })
          .finally(() => {
            commit("setIsAwaitingResponse", { isLoading: true, index: payload.index });
          });
      })
    },
    unApplyWorker({ commit, state }, data) {
        commit("setIsAwaitingResponse", { isLoading: true, index: data.index });
        const payload = {
            id: data.id,
            assignee_id: data.assignee_id,
            created_by_id: data.created_by_id,
            status: "Denied",
            shift_slots: [data.pivot.shift_slot_id]
        }
        ApiService
        .update(
           `/api/v2/applications`, data.id , payload
          )
            .then((res) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: `Unapplied for shift`,
                    showConfirmButton: false,
                    timer: 800
                })
                state.data.find(shift => shift.id == data.shiftId).my_shift_slot.applications[0].status.name = "Denied";
            })
            .catch(error => {
                commit("setError", error);
            })
            .finally(() => {
                commit("setIsAwaitingResponse", { isLoading: false, index: data.index });
            });
    },
    applyForShift({ state, commit, dispatch}, payload) {
        return new Promise((resole, reject) => {
            ApiService.post(
                `/api/v2/applications`, payload
               )
                 .then((res) => {
                     Swal.fire({
                         position: 'top-end',
                         icon: 'success',
                         title: `Succesfully applied`,
                         showConfirmButton: false,
                         timer: 800
                     });
                 })
                 .catch(error => {
                     commit("setError", error);
                     reject();
                 })
                 .finally(() => {
                     dispatch('fetchForNewJobsCounter')
                     dispatch('fetchShifts', state.parameters).then(() => {
                         resole()
                     }); 
                 });
        })
    },
    fetchEstimatedEarnings({ commit }, data) {
      return new Promise((resolve, reject) => {
          commit("setIsLoadingEstimates", true);
          ApiService.get("api/v2", "shifts/estimated-earnings?shifts=" + data)
            .then((response) => {
              commit("setEstimatedEarnings", response.data.data);
              commit("setIsLoadingEstimates", false);
              resolve();
            })
            .catch((err) => {
              commit("setEstimatedEarnings", {});
              commit("setIsLoadingEstimates", false);
              reject(err);
            });
      });
    },
    setCurrentPage({commit}, pageNumber) {
        commit("setCurrentPage", pageNumber);
    },
    resetPagination({ commit }) {
        commit("resetPagination")
    },
    setSearchString({ commit, dispatch, state }, searchString ) {
        commit("searchStringMutation", searchString),
        dispatch("fetchShifts", {id: state.userID, tab: state.activeTab});
    },
    resetState({ commit }) {
        commit("resetState");
    }
}
const mutations = {
    setLoading(state, value) {
        state.loading = value;
    },
    setEstimatedEarnings(state, payload) {
      state.estimatedEarning = payload;
    },
    resetState(state) {
        state = Object.assign(state, initialState());
    },
    setData(state, value) {
        state.data = value;
    },
    setIsLoadingEstimates(state, value) {
      state.isLoadingEstimates = value;
    },
    setParameters(state, value) {
        state.parameters = value;
    },
    setEror(state, value) {
        state.error = value;
    },
    setQueryState(state, value) {
        state.userID = value.id;
        state.activeTab = value.tab;
    },
    setPagination(state, value) {
        state.pagination.lastPage = value;
    },
    setCurrentPage(state, value) {
        state.pagination.currentPage = value;
    },
    resetPagination(state) {
        state.pagination.currentPage = 1;
        state.pagination.lastPage = 1;
    },
    searchStringMutation(state, value) {
        console.log(value)
        state.searchString = value;
    },
    setIsAwaitingResponse(state, values) {
        state.isAwaitingResponse.isLoading = values.isLoading;
        const index = state.isAwaitingResponse.indexes.indexOf(values.index);
        if (index > -1){
            state.isAwaitingResponse.indexes.splice(index, 1);
        } else {
            state.isAwaitingResponse.indexes.push(values.index);
        }
    },
    setNewJobsCounter(state, value) {
        if(value < 20) state.newJobs = value
        else state.newJobs = "20+"
    },
    setError(state, value) {
        state.error = value;
    },
    setBranches(state, value) {
        state.branches = value;
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
