import Echo from "laravel-echo";
import JwtService from "@/core/services/JwtService";
import store from "@/store";
import mitt from "mitt";
import { Actions } from "@/store/enums/StoreEnums";

export const eventBus = new mitt();

window.Pusher = require("pusher-js");

function createEchoInstance() {
  window.Echo = new Echo({
    authEndpoint: process.env.VUE_APP_PUSHER_AUTH_ENDPOINT,
    broadcaster: "pusher",
    key: process.env.VUE_APP_PUSHER_KEY,
    cluster: process.env.VUE_APP_PUSHER_CLUSTER,
    forceTLS: true,
    auth: {
      headers: {
        Authorization: "Bearer " + JwtService.getToken()
      }
    }
  });
}

createEchoInstance();

function handleNewChatMessage(event) {
  eventBus.emit("NewChatMessage", event);
  store.dispatch("LiveChatModule/handleNewMessageFromWebSocket", event);
}

function handleMessageReadByUser(event) {
  eventBus.emit("MessageReadByUser", event.chat_id);
  store.dispatch("LiveChatModule/handleChatMessageRead", event.chat_id);
}

export function connectToLiveChat(userId) {
  try {
    if (!window.Echo) {
      createEchoInstance();
    }
    window.Echo.private("live-chat." + userId)
      .listen(".NewChatMessage", ev => handleNewChatMessage(ev))
      .listen(".MessageReadByUser", ev => handleMessageReadByUser(ev))
      .subscribed(() => {
        store.dispatch(Actions.SET_WEBSOCKET_CONNECTED, true);
      });
  } catch (e) {
    console.log(e);
  }
}

export function leaveLiveChat() {
  if (window.Echo) {
    window.Echo.disconnect();
    delete window.Echo;
  }
}

export function setReadStatus(userId, chatId) {
  window.Echo.private("live-chat." + userId).whisper("message-read-by-user", {
    userId: userId,
    chatId: chatId
  });
}
