import {destroyToken, getToken, saveToken} from "@/core/services/JwtService";

const ID_RULES_KEY = "rules" as string;

/**
 * @description rules token form localStorage
 */
export const getRules = (): string[] | null => {
    const rolesJson = window.localStorage.getItem(ID_RULES_KEY);


    if(rolesJson) {
        return JSON.parse(rolesJson);
    }

    return []
};

/**
 * @description save rules into localStorage
 * @param rules: any
 */
export const saveRules = (rules: any): void => {
    window.localStorage.setItem(ID_RULES_KEY, JSON.stringify(rules));
};

/**
 * @description remove rules form localStorage
 */
export const destroyRules = (): void => {
    window.localStorage.removeItem(ID_RULES_KEY);
};

export default { getRules, saveRules, destroyRules };