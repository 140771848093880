
import { computed, defineComponent, onMounted, ref, nextTick } from "vue";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { useAbility } from "@casl/vue";
import { Ability, AbilityBuilder } from "@casl/ability";
import AccessService from "@/core/services/AccessService";
import CookieNotification from "@/components/Cookies/CookieNotification.vue";
import InviteFriendModal from "@/components/users/userProfile/fields/referred-users/misc/invite-a-friend-modal.vue";
import { Modal } from "bootstrap";

export default defineComponent({
  name: "app",
  metaInfo: {
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" }
    ]
  },
  components: {
    CookieNotification,
    InviteFriendModal
  },
  setup() {
    const app = document.getElementById("app");
    let modal = null as Modal;
    const modalKey = ref(0);
    const store = useStore();
    const ability = useAbility();
    const { can, rules } = new AbilityBuilder(Ability);
    const isCookieDialogOpen = ref<boolean>(false);
    const cookiesStatus = ref<string | null>(
      localStorage.getItem("areCookiesAccepted")
    );
    function agreeToCookies() {
      localStorage.setItem("areCookiesAccepted", "true");
      cookiesStatus.value = localStorage.getItem("areCookiesAccepted");
    }
    app?.addEventListener("open_invite_friend_modal", async () => {
      modalKey.value++;
      await nextTick();
      modal = new Modal(document.getElementById("invite_a_friend_modal"));
      modal?.show();
    });
    const closeInviteModal = () => {
      modal.hide();
      modal = null;
    }
    const hasAgreedToCookies = computed((): boolean => {
      if (cookiesStatus.value !== null) return true;
      else return false;
    });
    onMounted(() => {
      const rulesStored = AccessService.getRules();

      if (rulesStored) {
        rulesStored.forEach(rule => {
          can(rule, "all");
        });

        ability.update(rules);
      }

      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
    });

    return {
      agreeToCookies,
      closeInviteModal,
      isCookieDialogOpen,
      hasAgreedToCookies,
      modalKey
    };
  }
});
