import { useAbility } from "@casl/vue";
import moment from "moment";
import { useStore } from "vuex";
export function disabledDates(time: Date, minimumDate?: string|undefined): boolean {
  if (useAbility().can("can_create_past_jobs", "all")) {
    if (undefined !== minimumDate && time) {
      const momentObject = moment(minimumDate, "DD/MM/YYYY");
      if (momentObject.isValid()) {
        return time < momentObject.toDate();
      }
    }
    return false;
  }
  if (time) {
    time.setHours(0, 0, 0, 0);
    if (undefined !== minimumDate) {
      const momentObject = moment(minimumDate, "DD/MM/YYYY");
      if (momentObject.isValid()) {
        return time < momentObject.toDate();
      }
    }
    const now: Date = new Date();
    now.setHours(0, 0, 0, 0);
    return time < now;
  }
  return false;
}

export function isCrewBoss(job) {
  const store = useStore();
  const currentUser = store.getters.currentUser;
  if (currentUser && job) {
    const shifts = job.shifts ? job.shifts : [];
    const crewBosses = shifts.map(i => i.crew_bosses).flat();
    return crewBosses.some(i => i.id == currentUser.id);
  }
  return false;
}
