import ApiService from "../../../core/services/ApiService";
import Swal from "sweetalert2";

/* eslint-disable */
function initialState() {
    return {
        data: [],
        loading: false,
        error: '',
        pagination: {
            currentPage : 1,
            lastPage: 1
        },
        myVenues: [],
        searchParams: {
            countryID: "",
            searchText:"" 
        },
        isBeingAddedToMyVenues: [],
        activeTab: "active"
    }
}

const getters = {
    getData: state => state.data,
    loading: state => state.loading,
    getPaginationData: state => state.pagination,
    myVenues: state => state.myVenues,
    isBeingAddedToMyVenues: state => state.isBeingAddedToMyVenues
}

const actions = {
    fetchData({commit, state}) {
        commit('setLoading', true);

        ApiService.get('api/v2','venues?' +
        'page=' + state.pagination.currentPage +
        '&perPage=10' +
        '&filter[country_id]=' + state.searchParams.countryID +
        ((state.searchParams.searchText == "") ? "" : '&name=' + state.searchParams.searchText) +
        ((state.activeTab == 'active') ? "&filter[accepted]=1" : "") +
        ((state.activeTab == 'new') ? "&filter[accepted]=0" : "") +
        ((state.activeTab == "myVenues") ? "&filter[myVenues]=true" : "")
        )
        .then(
            response => {
                commit('setData' , response.data.data)
                commit('setPaginationData', response.data.last_page)
            }).catch(error => {
                commit('setError', error)
            }).finally(() => {
                commit('setLoading' , false)
            });   
    },
    destroyData({commit, dispatch}, value) {
        ApiService
         .delete(
             "/api/v2/venues/" + value
           )
            .then(() => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: `Venue deleted successfully`,
                    showConfirmButton: false,
                    timer: 2000
                });
            })
             .catch(error => {
                 commit("setError", error);
            })
            .finally(() => {
                dispatch("fetchData");
             });
    },
    approveVenue({dispatch}, value) {
        ApiService
        .post(
            "/api/v2/venues/" + value + "/make-public"
          )
            .then(() => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: `Venue approved successfully`,
                    showConfirmButton: false,
                    timer: 2000
                })
            })
            .catch(error => {
                console.log(error);
           })
           .finally(() => {
               dispatch("fetchData");
            });
    },
    declineVenue({dispatch}, value) {
        ApiService
        .post(
            "/api/v2/venues/" + value + "/decline"
          )
            .then(() => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: `Venue declined successfully`,
                    showConfirmButton: false,
                    timer: 2000
                })
            })
            .catch(error => {
                console.log(error);
           })
           .finally(() => {
               dispatch("fetchData");
            });
    },
    addToMyVenues({ commit }, venue) {
        commit("isBeingAddedToMyVenues", venue.id);
        ApiService
        .post(
            "/api/v2/venues/" + venue.id + "/clone"
          )
            .then(() => {
                commit("wasAddedToMyVenues", { id: venue.id , result: true });
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: `Venue added successfully`,
                    showConfirmButton: false,
                    timer: 800
                });
            })
            .catch(error => {
                commit("wasAddedToMyVenues", { id: venue.id , result: false });
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: error,
                    showConfirmButton: false,
                    timer: 800
                });
           });
    },
    fetchMyVenues({ commit }) {
        commit('setLoading' , true)
        ApiService.get('api/v2','venues?' + "&filter[myVenues]=true&isForMyVenues=true"
        )
        .then(
            response => {
                commit('setMyVenues' , response.data.data);
            }).catch(error => {
                commit('setError', error);
            }).finally(() => {
                commit('setLoading' , false);
            });   
    },
    changePageNumber({commit}, value) {
        commit("setPageNumber", value)
    },
    setSearchParameters({commit}, value) {
        commit("setSearchParams", value);
    },
    resetPageNumber({commit}) {
        commit("setPageNumber", 1)
    },
    setActiveTab({commit}, value) {
        commit("setCurrentTab", value)
    },
    resetState({commit}) {
        commit("resetState")
    },
    createNew({dispatch}, value) {
        return new Promise((resolve, reject) => {
            const payload = {
                name : value.name,
                entrance: value.entrance,
                country_id: value.country,
                city: value.city,
                address: value.address,
                zip: value.zip,
                province: value.province,
                notes: value.notes,
                company_id: value.company_id,
                public: 0
            };
            ApiService
            .post(
                "/api/v2/venues/", payload
              )
                .then(() => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: `Venue created successfully`,
                        showConfirmButton: false,
                        timer: 2000
                    })
                })
                .catch(error => {
                   reject(error)
               })
               .finally(() => {
                   resolve();
                   dispatch("resetPageNumber");
                   dispatch("fetchData");
                });
        });
    },
    update({dispatch}, value) { 
        const payload = {
            name : value.name,
            entrance: value.entrance,
            country_id: value.country,
            city: value.city,
            address: value.address,
            zip: value.zip,
            province: value.province,
            notes: value.notes,
            company_id: value.company_id
        };
        ApiService
        .update(
            "/api/v2/venues" , value.id , payload
          )
            .then(() => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: `Venue edited successfully`,
                    showConfirmButton: false,
                    timer: 2000
                })
            })
            .catch(error => {
                console.log(error);
           })
           .finally(() => {
               dispatch("resetPageNumber");
               dispatch("fetchData");
            });
    }
}
const mutations = {
    setError(state, value) {
        state.error = value
    },
    setData(state, value) {
        state.data = value
    },
    setLoading(state, value) {
        state.loading = value
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    },
    setPaginationData(state, value) {
        state.pagination.lastPage = value;
    },
    isBeingAddedToMyVenues(state, value) {
        state.isBeingAddedToMyVenues.push(value);
    },
    setMyVenues(state, data) {
        const ids = data.map(a => a.id);
        for(let i = 0; i < data.length; i++) {
            if(data[i].original_id !== null) ids.push(data[i].original_id);
        }
        state.myVenues = ids;
    },
    wasAddedToMyVenues(state, payload) {
        state.isBeingAddedToMyVenues = state.isBeingAddedToMyVenues.filter(v => v !== payload.id);
        if(payload.result) state.myVenues.push(payload.id);
    },
    setPageNumber(state, value) {
        state.pagination.currentPage = value;
    },
    setSearchParams(state, value) {
        state.searchParams = value;
    },
    setCurrentTab(state, value) {
        state.activeTab = value;
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
