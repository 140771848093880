import ApiService from "../../../core/services/ApiService";

/* eslint-disable */
function initialState() {
  return {
    userDocuments : [],
    loading: false
  }
}

const getters = {
  getDocuments: state => state.userDocuments,
  loading: state => state.loading
}

const actions = {
    fetchData({
        commit,
    }, userId) {
        commit('setLoading', true)

        ApiService.get(`/api/v2` , `documents?user_id=${userId}`)
            .then(response => {
                commit('setData', response.data.data)
            })
            .catch(error => {
                message = error.response.data.message || error.message
                commit('setError', message)
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    createNew({ commit , dispatch}, payload) {
      ApiService.post('/api/v2/documents/' , payload)
            .then(response => {
                //
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                dispatch("fetchData")
            })
    },
    update({ commit , dispatch}, payload) {
      ApiService.update('/api/v2/documents' , payload.id , payload.data)
            .then(response => {
                //
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                dispatch("fetchData")
            })
    },
    destroyData({commit}, documentId) {
      return new Promise((resolve, reject) => {
        ApiService.delete("/api/v2/documents/" + documentId)
          .then(response => {
            resolve(response)
          })
          .catch((error) => {
            reject(error);
          })
      })
    },
}
const mutations = {
  resetState(state) {
    state = initialState();
  },
  setData(state, value ) {
    state.userDocuments = value;
  },
  setLoading(state, value) { 
    state.loading = value;
  }
}

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
}