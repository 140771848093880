import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  useRoute
} from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { computed } from "vue";
import { useStore } from "vuex";
import { useAbility } from "@casl/vue";
import { isCrewBoss } from "@/components/jobs/helpers";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    meta: { title: "Dashboard" },
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/my-dashboard",
        name: "my-dashboard",
        meta: { title: "My dashboard", permission: "my_dashboard_access" },
        component: () => import("@/views/MyDashboard.vue")
      },
      {
        path: "/insights",
        name: "insights-index",
        meta: { title: "Insights", permission: "can_view_statistics" },
        component: () => import("@/views/statistics/index.vue")
      },
      {
        path: "/insights/workers",
        name: "insights-workers",
        meta: { title: "Worker statistics", permission: "can_view_statistics" },
        component: () => import("@/components/statistics/index-workers.vue")
      },
      {
        path: "/insights/earnings",
        name: "insights-earnings",
        meta: {
          title: "Earnings statistics",
          permission: "can_view_earning_statistics"
        },
        component: () => import("@/components/statistics/index-earnings.vue")
      },
      {
        path: "/activity-log",
        name: "activity-log",
        meta: { title: "Activity log", permission: "can_view_all_activities" },
        component: () => import("@/components/system/ActivityLog.vue")
      },
      {
        path: "/calendar",
        name: "calendar",
        meta: { title: "Calendar", permission: "calendar_access" },
        component: () => import("@/views/Calendar.vue")
      },
      {
        path: "/jobs/:id/edit",
        name: "job-edit",
        meta: { title: "Edit job", permission: "job_edit" },
        component: () => import("@/views/jobs/JobEdit.vue")
      },
      {
        path: "/create-quote",
        name: "create-quote",
        meta: { title: "Create job quote", permission: "job_edit" },
        component: () => import("@/views/job_quotes/CreateQuote.vue")
      },
      {
        path: "/edit-quote/:id",
        name: "edit-quote",
        meta: { title: "Edit job quote", permission: "job_edit" },
        component: () => import("@/views/job_quotes/CreateQuote.vue")
      },
      {
        path: "/job-quotes",
        name: "job-quote",
        meta: { title: "View job quotes", permission: "job_edit" },
        component: () => import("@/views/job_quotes/Index.vue")
      },
      {
        path: "/jobs/templates",
        name: "job-templates",
        meta: { title: "Job templates", permission: "job_template_access" },
        component: () => import("@/views/jobs/Templates.vue")
      },
      {
        path: "/jobs/templates/:id",
        name: "job-templates-edit",
        meta: { title: "Edit job template", permission: "job_template_access" },
        component: () => import("@/components/job-templates/Edit.vue")
      },
      {
        path: "/jobs/:id",
        name: "job-view",
        meta: { title: "View job", permission: "job_view" },
        component: () => import("@/views/jobs/JobView.vue"),
        children: [
          {
            path: "Shifts",
            name: "job-shifts",
            meta: { title: "Job - shifts" },
            component: () => import("@/views/jobs/tabs/JobShifts.vue")
          },
          {
            path: "book",
            name: "job-book",
            meta: {
              title: "Book users",
              permission: ["jobs_book_workers", "can_request_worker"]
            },
            component: () =>
              import("@/components/jobs/Booking/BookWorkersIndex.vue")
          },
          {
            path: "additional-costs",
            name: "additional-costs",
            meta: {
              title: "Job additional costs",
              permission: "can_view_additional_costs"
            },
            component: () =>
              import("@/components/jobs/tabs/AdditionalCosts.vue")
          },
          {
            path: "job-projection",
            name: "job-projection",
            meta: {
              title: "Job projection",
              permission: "can_view_job_projection"
            },
            component: () => import("@/components/jobs/tabs/JobProjection.vue")
          },
          {
            path: "client-rates",
            name: "client-rates",
            meta: { title: "Client rates", permission: "rate_access" },
            component: () => import("@/components/jobs/tabs/ClientRatesJob.vue")
          },
          {
            path: "worker-rates",
            name: "worker-rates-job",
            meta: { title: "Worker rates", permission: "rate_access" },
            component: () => import("@/components/jobs/tabs/WorkerRatesTab.vue")
          },
          {
            path: "job-notes",
            name: "job-notes",
            meta: { title: "Job - notes", permission: [
                "job_notes_access",
                () => {
                  const store = useStore();
                  const job = store.getters["JobModule/item"];
                  if (job) {
                    return isCrewBoss(job);
                  }
                  return false;
                }
              ] },
            component: () => import("@/views/jobs/tabs/JobNotes.vue")
          }
        ]
      },
      {
        path: "/jobs/create",
        name: "job-create",
        meta: { title: "Create job", permission: "job_create" },
        component: () => import("@/views/jobs/JobCreate.vue")
      },
      {
        path: "/dashboard",
        name: "dashboard",
        meta: { title: "Dashboard", permission: "dashboard_access" },
        component: () => import("@/views/Dashboard.vue")
      },
      {
        path: "/approvals",
        name: "approvals",
        meta: { title: "Approvals", permission: "approval_access" },
        component: () => import("@/views/approvals/ApprovalsView.vue")
      },
      {
        path: "/users",
        name: "users",
        meta: { title: "Users" },
        component: () => import("@/views/users/IndexView.vue")
      },
      {
        path: "/live-chat",
        name: "live-chat",
        meta: { title: "Chat", permission: "has_live_chat" },
        component: () => import("@/components/live-chat/LiveChatIndex.vue")
      },
      {
        path: "/users/create",
        name: "users-create",
        meta: { title: "Create user", permission: "user_create" },
        component: () => import("@/views/users/Create.vue")
      },
      {
        path: "applicants",
        name: "applicants",
        meta: { title: "Applicants", permission: "can_view_applicants_list" },
        component: () => import("@/views/users/Applicants.vue")
      },
      {
        path: "/users/:id",
        name: "ViewUser",
        meta: { title: "View user" },
        component: () => import("@/views/users/UserProfileView.vue"),
        children: [
          {
            path: "personal",
            name: "personal",
            meta: { title: "User - personal" },
            component: () =>
              import("@/components/users/userProfile/fields/Personal.vue")
          },
          {
            path: "settings",
            name: "settings",
            meta: { title: "User - professional" },
            component: () =>
              import("@/components/users/userProfile/fields/WorkRelated.vue")
          },
          {
            path: "payment",
            name: "payment",
            meta: { title: "Payment information" },
            component: () =>
              import("@/components/users/userProfile/fields/PaymentInfo.vue")
          },
          {
            path: "notes",
            name: "notes",
            meta: { title: "User - notes" },
            component: () =>
              import("@/components/users/userProfile/fields/Notes.vue")
          },
          {
            path: "my-documents",
            name: "my-documents",
            meta: { title: "User - documents" },
            component: () =>
              import("@/components/my-documents/MyDocumentsIndex.vue")
          },
          {
            path: "my-skills",
            name: "my-skills",
            meta: { title: "User - skills" },
            component: () => import("@/components/my-skills/MySkillsIndex.vue")
          },
          {
            path: "user-activities",
            name: "user-activities",
            meta: {
              title: "User - activities",
              permission: "view_user_activity"
            },
            component: () =>
              import("@/components/users/userProfile/fields/UserActivity.vue")
          },
          {
            path: "about-company",
            meta: { title: "About company" },
            name: "about-company",
            component: () =>
              import(
                "@/components/users/userProfile/bookerTabs/AboutCompanyTab.vue"
              )
          },
          {
            path: "invited-users",
            meta: {
              title: "Invited users",
              permission: [
                "can_view_all_referred_users_lists",
                to => {
                  return to.params.id == store.getters.currentUser.id;
                }
              ]
            },
            name: "invited-users",
            component: () =>
              import(
                "@/components/users/userProfile/fields/referred-users/referred-users-tab.vue"
              )
          },
          {
            path: "user-calendar",
            meta: { title: "Calendar" },
            name: "user-calendar",
            component: () =>
              import(
                "@/components/users/userProfile/fields/userCalendar/user-calendar.vue"
              )
          },
          {
            path: "user-security",
            meta: { title: "Security" },
            name: "user-security",
            component: () =>
              import(
                "@/components/users/userProfile/fields/userSecurity/user-security.vue"
              )
          }
        ]
      },
      {
        path: "/users/me/:tab",
        name: "ViewUserMe",
        redirect: to => {
          return `/users/${store.getters.currentUser.id}/${to.params.tab}`;
        }
      },
      {
        path: "/clients",
        name: "clients",
        meta: { title: "Clients", permission: "company_access" },
        component: () => import("@/views/clients/IndexView.vue")
      },
      {
        path: "/client/:id",
        name: "viewSingleClient",
        meta: {
          title: "View client",
          permission: [
            "company_access",
            to => {
              return to.params.id == store.getters.currentUser.company_id;
            }
          ]
        },
        component: () => import("@/views/clients/SingleClientView.vue")
      },
      {
        path: "/clients/new",
        name: "clientsNew",
        meta: { title: "Create company" },
        component: () => import("@/views/clients/EditOrCreateClientView.vue")
      },
      {
        path: "/clients/edit/:id",
        name: "clientsEdit",
        meta: {
          title: "Edit company",
          permission: ["company_create", "company_edit"]
        },
        component: () => import("@/views/clients/EditOrCreateClientView.vue")
      },
      {
        path: "/levels",
        name: "levels",
        meta: { title: "Levels", permission: "level_access" },
        component: () => import("@/views/settings/LevelsView.vue")
      },
      {
        path: "/branches",
        name: "branches",
        meta: { title: "Branches", permission: "branch_access" },
        component: () => import("@/views/branches/BranchesView.vue")
      },
      {
        path: "/document-types",
        name: "document-types",
        meta: { title: "Document types", permission: "document_type_access" },
        component: () => import("@/views/document_types/DocumentTypesView.vue")
      },
      {
        path: "/skills",
        name: "skills",
        meta: { title: "Skills", permission: "settings_skill_access" },
        component: () => import("@/views/skills/Index.vue")
      },
      {
        path: "/equipment-categories",
        name: "equipment-categories",
        meta: {
          title: "Equipment categories",
          permission: "equipment_category_access"
        },
        component: () => import("@/views/settings/EquipmentCategoriesView.vue")
      },
      {
        path: "/dresscodes",
        name: "dresscodes",
        meta: { title: "Dresscodes", permission: "dresscode_access" },
        component: () => import("@/views/dresscodes/DresscodesView.vue")
      },
      {
        path: "/freelancers",
        name: "freelancers",
        meta: { title: "Freelancers", permission: "freelancer_access" },
        component: () => import("@/views/freelancers/FreelancersView.vue")
      },
      {
        path: "/countries",
        name: "countries",
        meta: { title: "Countries", permission: "country_access" },
        component: () => import("@/views/system/CountriesView.vue")
      },
      {
        path: "/languages",
        name: "languages",
        meta: { title: "Languages", permission: "language_access" },
        component: () => import("@/views/system/LanguagesView.vue")
      },
      {
        path: "/permissions",
        name: "permissions",
        meta: { title: "Permissions", permission: "permission_access" },
        component: () => import("@/views/settings/PermissionsView.vue")
      },
      {
        path: "/roles",
        name: "roles",
        meta: { title: "Roles", permission: "role_access" },
        component: () => import("@/views/settings/RolesView.vue")
      },
      {
        path: "/worker-rates",
        name: "worker-rates",
        meta: { title: "Worker rates", permission: "can_manage_worker_rates" },
        component: () => import("@/views/worker-rates/WorkerRatesView.vue")
      },
      {
        path: "/help/edit/:id",
        name: "EditHelpArticle",
        meta: { title: "Edit article", permission: "article_edit" },
        component: () => import("@/components/help/EditHelpArticle.vue")
      },
      {
        path: "/help/create/:id",
        name: "CreateArticle",
        meta: { title: "Create article", permission: "article_create" },
        component: () => import("@/components/help/CreateArticle.vue")
      },
      {
        path: "/venues",
        name: "venues",
        meta: { title: "Venues", permission: "venue_access" },
        component: () => import("@/views/venues/VenuesView.vue"),
        children: [
          {
            path: "edit/:id",
            name: "EditVenue",
            meta: { title: "Venue - edit" },
            component: () => import("@/views/system/CountriesView.vue")
          }
        ]
      },
      {
        path: "/salaryreports",
        name: "salaryReport",
        meta: { title: "Salary repors", permission: "salary_access" },
        component: () => import("@/views/payments/SalaryReportsView.vue")
      },
      {
        path: "/help",
        name: "helpSection",
        meta: { title: "Help", permission: "help_access" },
        component: () => import("@/views/help/HelpView.vue")
      },
      {
        path: "/help-categories",
        name: "HelpCategories",
        meta: {
          title: "Help - categories",
          permission: "help_category_access"
        },
        component: () => import("@/views/help/HelpcategoriesView.vue")
      }
    ]
  },
  {
    path: "/sign-in",
    name: "sign-in",
    meta: { title: "Wanted crew" },
    component: () => import("@/views/auth/SignIn.vue")
  },
  {
    path: "/otp/enable",
    name: "otp-enable",
    meta: { title: "Enable 2FA" },
    component: () => import("@/views/otp/EnableOtp.vue")
  },
  {
    path: "/otp/disable",
    name: "otp-disable",
    meta: { title: "Disable 2FA" },
    component: () => import("@/views/otp/DisableOtp.vue")
  },
  {
    path: "/two-factor",
    name: "two-factor",
    meta: { title: "Wanted crew" },
    component: () => import("@/views/auth/TwoFactor.vue")
  },
  {
    path: "/email/verify/success",
    name: "succesfull-verify",
    redirect: "/sign-in?wasVerified=true"
  },
  {
    path: "/sign-up",
    name: "sign-up",
    meta: { title: "Sign up" },
    component: () => import("@/views/auth/SignUp.vue")
  },
  {
    path: "/password-reset",
    name: "password-reset",
    meta: { title: "Reset password" },
    component: () => import("@/views/auth/PasswordReset.vue")
  },
  {
    path: "/password/reset/:token",
    name: "password-change",
    component: () => import("@/views/auth/PasswordChange.vue")
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    meta: { title: "Privacy policy" },
    component: () => import("@/views/legal/PrivacyPolicy.vue")
  },
  {
    path: "/privacy-notice",
    name: "privacy-notice",
    meta: { title: "Privacy notice" },
    component: () => import("@/views/legal/PrivacyNotice.vue")
  },
  {
    path: "/cookie-policy",
    name: "cookie-policy",
    meta: { title: "Cookie policy" },
    component: () => import("@/views/legal/CookiePolicy.vue")
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    meta: { title: "Page not found" },
    component: () => import("@/views/error/Error404.vue")
  },
  {
    path: "/maintenance",
    name: "maintenance",
    meta: { title: "Maintenance" },
    component: () => import("@/views/error/Maintenance.vue")
  },
  {
    path: "/500",
    name: "500",
    meta: { title: "Error" },
    component: () => import("@/views/error/Error500.vue")
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404"
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

function isAllowed(permission, rules, to) {
  if (!rules || !permission) return false;

  if (!Array.isArray(permission)) {
    permission = [permission];
  }

  for (let i = 0; i < permission.length; i++) {
    const el = permission[i];

    if (typeof el === "function") {
      return el(to);
    } else if (rules.includes(el)) {
      return true;
    }
  }

  return false;
}

router.beforeEach((to, from, next) => {
  if (
    process.env.VUE_APP_MAINTENANCE_MODE === "true" &&
    to.name !== "maintenance"
  ) {
    next("/maintenance");
  }

  // reset config to initial state
  const modal = document.querySelector(".modal");
  if (modal) {
    document.body.style.overflow = "inherit";
  }
  const currentUser = computed(() => store.getters.currentUser);

  store.commit(Mutations.RESET_LAYOUT_CONFIG);
  store
    .dispatch(Actions.VERIFY_AUTH)
    .then(payload => {
      if (
        "otp-enable" !== to.name &&
        payload &&
        payload.should_enable_2fa &&
        0 == currentUser.value.google2fa_enabled &&
        store.getters.isAdmin
      ) {
        next("/otp/enable");
      } else {
        const rules = computed<null | string[]>(
          (): null | string[] => store.getters["RulesModule/rules"]
        );
        const routePermission = to.meta.permission as string | string[];
        store
          .dispatch("RulesModule/fetchData")
          .then(() => {
            if (
              rules.value &&
              routePermission &&
              !isAllowed(routePermission, rules.value, to)
            ) {
              if (rules.value?.find(item => item == "dashboard_access")) {
                next("/");
              } else {
                next("/my-dashboard");
              }
            } else {
              next();
            }
          })
          .catch(() => {
            next();
          });
      }
    })
    .catch(() => {
      next();
    });

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});
router.afterEach(to => {
  if (to.meta.title) {
    document.title = `${to.meta.title}`;
  }
});

export default router;
