/* eslint-disable */
import ApiService from "../../../core/services/ApiService";
import Swal from "sweetalert2";

function initialState() {
    return {
        jobs: [],
        pagination: {},
        loading: false,
        error: '',
        jobStatusLoading: {
            job_id: null,
            loading: false,
        },
        query : {
            companyID : '',
            countryID: '',
            userSearchInput: '',
            branches: [],
            own: false
        },
        jobType: 'upcoming',
        relationships: {
            company: "name",
            country: "title",
            venue: "name",
            book_from_branches: "name",
            project_manager: "name",
            contact_on_location: "name",
            crew_boss: "name"
        },
        shiftsAll: [],
        relatedClients: "",
        relatedCountries: "",
        isAwaitingCancel: [],
        isPast: false,
        isOwn: false,
        loadedNew: false,
        loadedOwn: false,
        page: 1,
        total: 0,
    };
}

const getters = {
    jobs: state => state.jobs,
    jobType: state => state.jobType,
    loading: state => state.loading,
    jobStatusLoading: state => state.jobStatusLoading,
    relationships: state => state.relationships,
    query: state => state.query,
    statusAll: state => state.statusAll,
    shiftsAll: state => state.shiftsAll,
    isPast: state => state.isPast,
    isOwn: state => state.isOwn,
    loadedNew: state => state.loadedNew,
    loadedOwn: state => state.loadedOwn,
    page: state => state.page,
    total: state => state.total,
    getPaginationData: state => state.pagination,
    relatedClients: state => state.relatedClients,
    relatedCountries: state => state.relatedCountries,
    isAwaitingCancel: state => state.isAwaitingCancel
};

const actions = {
    resetPageNumber({commit}){
        commit('resetPageNumber');
    },
    setIsChangingStatus({commit}, payload) {
        commit("setJobStatusLoading", { job_id: payload.id, loading: payload.state });
    },
    async saveNewJobStatus({ state, commit, dispatch }, payload) {
        commit("setJobStatusLoading", { job_id: payload.id, loading: true });
        await ApiService
            .post(`/api/v2/jobs/` + payload.id + `/status/` + payload.newStatus)
            .then(() =>{
                if (!payload.isSingleJobView) {
                    const jobToChange = state.jobs.find(job => job.id == payload.id);
                    jobToChange.status = payload.newStatus;
                }
            })
            .finally(() => {
              if (!payload.isSingleJobView) {
                  commit("setJobStatusLoading", { job_id: payload.id, loading: false });
              }
            });
    },
    fetchRelatedClients({ state, commit }, query) {
        ApiService.get("/api/v2",`clientswithjobs?${query}${state.query.countryID ? '&filter[country_id]=' + state.query.countryID : ''}`)
                  .then((response) => {
                    commit("setRelatedClients", response.data.data);
                  })
    },
    fetchRelatedCountries({ state, commit }, query) {
        ApiService.get("/api/v2",`countrieswithjobs?${query}${state.query.companyID ? '&filter[company_id]=' + state.query.companyID : ''}`)
                  .then((response) => {
                     commit('setRelatedCountries', response.data.data);
                  })
    },
    fetchData({commit, state, dispatch}, filters) {
        return new Promise((resolve, reject) => {
            commit("setLoading", true);
            const relatedFilters = ((state.query.companyID !== '') ? '&filter[company_id]=' +  state.query.companyID : '') +
                            ((state.query.countryID !== '') ? '&filter[country_id]=' +  state.query.countryID : '');
            const query = " past=" +
                            +state.isPast +
                            "&own=" +
                            +state.isOwn +
                            "&page=" +
                            +state.page +
                            ((state.query.own) ? '&filter[own]=' +  state.query.own : '') +
                            ((state.query.userSearchInput !== '') ? '&filter[search]=' +  state.query.userSearchInput : '') +
                            ((state.jobType == 'upcoming') ? '&filter[upcoming]=1' : '&filter[past]=1') +
                            ((filters.status == "") ? "" : `&filter[status]=${filters.status}`) +
                            ((state.query.branches.length > 0) ? '&filter[branches]=' +  state.query.branches : '');
            dispatch("fetchRelatedClients", query);
            dispatch("fetchRelatedCountries", query);
            dispatch("ListsModule/fetchBranches", { filters: {
                upcoming: state.jobType == "upcoming",
                past: state.jobType == "past",
                country_id: state.query.countryID,
                company: state.query.companyID
            }},
            { root: true });
            ApiService
            .get(
                "/api/v2",
                `jobs?${query}${relatedFilters}`
              )
                .then(response => {
                    const newAll = response.data.data;
                    const paginationData = response.data.meta;
                    if (!state.isPast) {
                        commit("setLoadedNew", true);
                    }
                    if (state.isOwn) {
                        commit("setLoadedOwn", true);
                    }
                    commit("setJobs", newAll);
                    commit("setPaginationData" , paginationData)
                    commit("setTotal", response.data.total);
                    resolve();
                })
                .catch(error => {
                    message = error.response.data.message || error.message;
                    commit("setError", message);
                    console.log(message);
                    reject(error);
                })
                .finally(() => {
                    commit("setLoading", false);
                });
        });

    },
    destroyData({ commit, state }, id) {
        ApiService
            .delete("/api/v2/jobs/" + id)
            .then(response => {
                commit(
                    "setJobs",
                    state.jobs.filter(item => {
                        return item.id != id;
                    })
                );
                Swal.fire({
                    toast: true,
                    position: 'top-end',
                    icon: 'success',
                    title: `Job deleted successfully`,
                    showConfirmButton: false,
                    timer: 3000
                })
            })
            .catch(error => {
                console.log(error);
                message = error.response.data.message || error.message;
                commit("setError", message);
                console.log(message);
            });
    },
    changeJobReason({ state, commit, dispatch }, payload) {
        ApiService
            .post(
                `/api/v2/jobs/${payload.id}/change-reason`, payload
            )
            .then(() => {
                const jobIndex = state.jobs.findIndex(job => job.id == payload.id);

                if (!state.jobs[jobIndex].reason || !payload.reason) {
                    state.jobs[jobIndex].reason = payload.reason;
                }
            })
            .catch(error => {
                commit("setError", error);
            });
    },
    cancelJob({ commit }, data) {
            commit('isAwaitingCancel', data.id);
            ApiService.post(`/api/v2/jobs/${data.id}/status/Cancelled`)
                  .finally(() => {
                    commit("changeLocalJobStatus", data.id);
                    commit('clearIsAwaitingCancel', data.id);
                  })
    },
    setQuery({commit}, value) {
        commit("setQuery", value);
    },  
    setLoading({ commit }, value) {
        commit("setLoading", purify(value));
    },
    setJobStatusLoading({ commit }, value) {
        commit("setJobStatusLoading", purify(value));
    },
    setIsPast({ commit, dispatch }, value) {
        commit("setIsPast", value);
    },
    setLoadedNew({ commit, dispatch }, value) {
        commit("setLoadedNew", purify(value));
    },
    setJobsType({commit}, value){
        commit('setJobsType', value)
    },
    setIsOwn({ commit, dispatch }, value) {
        commit("setIsOwn", purify(value));
    },
    setPage({ commit }, value) {
        commit("setPage", value)
    },
    setTotal({ commit, dispatch }, value) {
        commit("setTotal", purify(value));
    },
    resetState({ commit }) {
        commit("resetState");
    }
};

const mutations = {
    setJobs(state, items) {
        state.jobs = items;
    },
    setRelatedClients(state, payload) {
        state.relatedClients = payload;
    },
    setLoading(state, loading) {
        state.loading = loading;
    },
    setJobStatusLoading(state, payload) {
        state.jobStatusLoading.job_id = payload.job_id;
        state.jobStatusLoading.loading = payload.loading;
    },
    setQuery(state, query) {
        state.query = query;
    },
    setError(state, value) {
        state.error = value
    },
    resetState(state) {
        state = Object.assign(state, initialState());
    },
    setShiftsAll(state, value) {
        state.shiftsAll = value;
    },
    setPaginationData(state , value){
        state.pagination = value;
    },
    setIsPast(state, value) {
        state.isPast = value;
    },
    setIsOwn(state, value) {
        state.isOwn = value;
    },
    setPage(state, value) {
        state.page = value;
    },
    setTotal(state, value) {
        state.total = value;
    },
    isAwaitingCancel(state, value) {
        state.isAwaitingCancel.push(value);
    },
    clearIsAwaitingCancel(state, value) {
        state.isAwaitingCancel.filter(item => item == value);
    },
    changeLocalJobStatus(state, value) {
        state.jobs.find(job => job.id == value).status = "Cancelled";
    },
    setLoadedNew(state, value) {
        state.loadedNew = value;
    },
    setRelatedCountries(state, value) {
        if(value) {
            state.relatedCountries = value.filter(n => n)
        }
    },
    setLoadedOwn(state, value) {
        state.loadedOwn = value;
    },
    setJobsType(state, value){
        state.jobType = value
    },
    resetPageNumber(state){
        state.page = 1;
    }
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
};
