import ApiService from "../../../core/services/ApiService";
import moment from "moment";

/* eslint-disable */
function initialState() {
    return {
        jobsStatistic: {},
        reportedHoursStatistic: {},
        workerEarnings: {},
        jobsPerClient: {},
        usersStatistic: {},
        earningsStatistic: {},
        clientRevenue: {},
        marginByClient: {},
        jobsByWorker: {
            labels: [],
            values: []
        },
        hoursByWorker: {
            labels: [],
            values: []
        },
        globalFilters: {
            company_id: null,
            branches: []
        },
        consolidatedExpenses: {
            additional_expenses: 0,
            salaries: 0,
        }
    };
};

const getters = {
    jobsStatistic: (state) => state.jobsStatistic,
    workerEarnings: (state) => state.workerEarnings,
    reportedHoursStatistic: (state) => state.reportedHoursStatistic,
    jobsPerClient: (state) => state.jobsPerClient,
    jobsByWorker: (state) => state.jobsByWorker,
    hoursByWorker: (state) => state.hoursByWorker,
    users: (state) => state.usersStatistic,
    earnings: (state) => state.earningsStatistic,
    consolidatedExpenses: (state) => state.consolidatedExpenses,
    clientRevenue: (state) => state.clientRevenue,
    marginByClient: (state) => state.marginByClient
}

const actions = {
    clearMarginStats({ commit }) {
        commit("setMarginByClient", {});
    },
    fetchJobsStatistic({ state, commit }) {
        let queryString = "";
        if(state.globalFilters.company_id) {
            queryString += `filter[company_id]=${state.globalFilters.company_id}&`;
        }
        if(state.globalFilters.branches.length > 0) {
            queryString += `filter[branches]=${state.globalFilters.branches}&`;
        }
        return new Promise((resolve, reject) => {
            ApiService.get("api/v2", `statistics/jobs${queryString != "" ? `?${queryString}` : ''}`).then(response => {
                commit("setJobsStatistic", response.data)
                resolve(response);
            }).catch(error => {
                reject(error);
            })
        });
    },
    fetchConsolidatedExpenses({ state, commit }, query) {
        return new Promise((resolve, reject) => {
            let queryString = "";
            if(query.start && query.end) {
                queryString += `start=${query.start}&end=${query.end}`;
            }
            ApiService.get("api/v2", 'statistics/consolidated-expenses?' + queryString).then(response => {
                commit("setConsolidatedExpenses", response.data.data);
                resolve(response);
            }).catch(error => {
                reject(error);
            })
        });
    },
    clearConsolidatedExpenses({commit}) {
        commit("setConsolidatedExpenses", {additional_expenses: 0, salaries: 0});
    },
    fetchEarningStatistics({ commit }, query) {
        return new Promise((resolve, reject) => {
            let queryString = "";
            if(query.startDate && query.endDate) {
                queryString += `start=${query.startDate}&end=${query.endDate}`;
            }
            if(query.branchId) {
                queryString += `${queryString === '' ? '' : '&'}branch=${query.branchId}`;
            }
            ApiService.get("api/v2", 'statistics/revenue?' + queryString).then(response => {
                commit("setEarningStatistics", response.data.data);
                resolve(response);
            }).catch(error => {
                reject(error);
            })
        });
    },
    fetchClientRevenueStatistic({ commit }, query) {
        return new Promise((resolve, reject) => {
            let queryString = "";
            if(query.startDate && query.endDate) {
                queryString += `start=${query.startDate}&end=${query.endDate}`;
            }
            ApiService.get("api/v2", 'statistics/client-revenue?' + queryString).then(response => {
                commit("setClientRevenue", response.data);
                resolve(response);
            }).catch(error => {
                reject(error);
            })
        });
    },
    fetchJobsPerClient({ commit }) {
        return new Promise((resolve, reject) => {
            ApiService.get("api/v2", 'statistics/jobs-per-client').then(response => {
                commit("setJobsPerClient", response.data);
                resolve(response);
            }).catch(error => {
                reject(error);
            })
        });
    },
    reportedHours({ state, commit }) {
        let queryString = "";
        if(state.globalFilters.company_id) {
            queryString += `filter[company_id]=${state.globalFilters.company_id}&`;
        }
        if(state.globalFilters.branches.length > 0) {
            queryString += `filter[branches]=${state.globalFilters.branches}&`;
        }
        return new Promise((resolve, reject) => {
            ApiService.get("api/v2", `statistics/reportedHours${queryString != "" ? `?${queryString}` : ''}`).then(response => {
                commit("setReportedHoursStatistic", response.data)
                resolve(response);
            }).catch(error => {
                reject(error);
            })
        })
    },
    users({ commit }) {
        return new Promise((resolve, reject) => {
            ApiService.get("api/v2", 'statistics/users').then(response => {
                commit("setUsersStatistic", response.data);
                resolve(response);
            }).catch(error => {
                reject(error);
            })
        })
    },
    fetchJobsByWorker({commit}, query) {
        return new Promise((resolve, reject) => {
            ApiService.get("api/v2", 'statistics/jobs-by-worker?' + query).then(response => {
                commit("setJobsByWorkerStatistic", response.data);
                resolve(response);
            }).catch(error => {
                reject(error);
            })
        })
    },
    fetchHoursByWorker({commit}, query) {
        return new Promise((resolve, reject) => {
            ApiService.get("api/v2", 'statistics/hours-by-worker?' + query).then(response => {
                commit("setHoursByWorker", response.data);
                resolve(response);
            }).catch(error => {
                reject(error);
            })
        })
    },
    downloadWorkerStatisticsReport({commit}, query) {
        return new Promise((res, rej) => {
            ApiService.download(`api/v2`, `statistics/workers?`+ query)
                .then(response => {
                    const fileName = `Worker-statistics-${moment(Date.now()).format('DD/MM/YYYY')}.xls`;
                    const url = URL.createObjectURL(new Blob([response.data], {
                        type: 'application/vnd.ms-excel'
                    }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    res();
                })
                .catch(err => {
                    rej(err);
                })
        })
    },
    fetchWorkerEarnings({ commit }) {
        return new Promise((resolve, reject) => {
            ApiService.get("api/v2", 'statistics/worker-earnings').then(response => {
                commit("setWorkerEarnings", response.data);
                resolve(response);
            }).catch(error => {
                reject(error);
            })
        });
    },
    fetchMarginByClient({commit}, query) {
        return new Promise((resolve, reject) => {
            let queryString = "";
            if(query.startDate && query.endDate) {
                queryString += `start=${query.startDate}&end=${query.endDate}`;
            }
            ApiService.get("api/v2", 'statistics/client-margins?' + queryString).then(response => {
                commit("setMarginByClient", response.data);
                resolve(response);
            }).catch(error => {
                reject(error);
            })
        });
    },
    setCompanyFilter({ commit }, company_id) {
        commit("setCompanyFilter", company_id);
    },
    setBranchesFilter({commit}, branches) {
        commit("setBranchesFilter", branches);
    },
    resetState({ commit }) {
        commit("resetState");
    }
}
const mutations = {
    setJobsStatistic(state, data) {
        state.jobsStatistic = data;
    },
    setMarginByClient(state, payload) {
        state.marginByClient = payload;
    },
    setClientRevenue(state, data) {
        state.clientRevenue = data;
    },
    setEarningStatistics(state, data) {
        state.earningsStatistic = data;
    },
    setConsolidatedExpenses(state, data) {
        state.consolidatedExpenses = data;
    },
    setWorkerEarnings(state, data) {
      state.workerEarnings = data;
    },
    setJobsByWorkerStatistic(state, data) {
        state.jobsByWorker = data;
    },
    setHoursByWorker(state, data) {
      state.hoursByWorker = data;
    },
    setJobsPerClient(state, data) {
        state.jobsPerClient = data;
    },
    setCompanyFilter(state, company_id) {
        state.globalFilters.company_id = company_id
    },
    setBranchesFilter(state, branches) {
        state.globalFilters.branches = branches;
    },
    setReportedHoursStatistic(state, data) {
        state.reportedHoursStatistic = data;
    },
    setUsersStatistic(state, data) {
        state.usersStatistic = data;
    },
    resetState(state) {
        state = Object.assign(state, initialState());
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
