import ApiService from "../../../core/services/ApiService";

/* eslint-disable */
function initialState() {
    return {
        data: {},
        loading: false
    };
};

const getters = {
    loading: state => state.loading,
    getData: state => state.data
}

const actions = {
    fetchData({ commit , state}) {
            commit("setLoading", true);
            ApiService
            .get(
                "/api/v2",
                "roles?")
                .then(response => {
                    commit("setData", response.data.data);
                })
                .catch(error => {
                    commit("setError", error);
                })
                .finally(() => {
                    commit("setLoading", false);
                });
    },

    update({ commit , dispatch}, value) {
        commit("setLoading", true);
        const payLoad = {
            title: value.title,
            description: value.description,
            permission: value.permissionIds
        }
        ApiService
        .update(
            "/api/v2/roles" ,value.id, payLoad
          )
          .then()
            .catch(error => {
                commit("setError", error);
            })
            .finally(() => {
                dispatch("fetchData");
                commit("setLoading", false)
            });
    },
    createNew({commit, dispatch }, value) {
        ApiService
        .post(
            "/api/v2/roles" , value
          )
          .then()
            .catch(error => {
                commit("setError", error);
            })
            .finally(() => {
                dispatch("fetchData");
            });
    },
    destroyData({commit, dispatch}, value) {
        ApiService
         .delete(
             "/api/v2/roles/" + value.id
           )
           .then()
             .catch(error => {
                 commit("setError", error);
            })
            .finally(() => {
                dispatch("fetchData");
             });
    }
}
const mutations = {
    setLoading(state, value) {
        state.loading = value;
    },
    resetState(state) {
        state = Object.assign(state, initialState());
    },
    setData(state, value) {
        state.data = value;
    },
    setEror(state, value) {
        state.error = value;
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
