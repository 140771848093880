import ApiService from "../../core/services/ApiService";

function initialState() {
    return {
        rules: null,
        isFetched: false,
    }
}

const getters = {
    rules: state => state.rules,
    isFetched: state => state.isFetched,
}

const actions = {
    purgeRules({ commit }) {
        commit('setRules', null);
        commit('setIsFetched', false);
    },
    fetchData({ commit, state, dispatch }, rules) {
        if (!state.isFetched) {
            return new Promise((resolve, reject) => {
                ApiService.get('/api/v2/rules')
                    .then(response => {
                        commit('setRules', response.data.data);
                        commit('setIsFetched', true);
                        resolve()
                    })
                    .catch(error => {
                        const message = error.response.data.message || error.message
                        console.log(message)
                        reject()
                    })
            })
        }
    },
    resetState({commit}) {
        commit('resetState')
    }
}

const mutations = {
    setRules(state, rules) {
        state.rules = rules
    },
    setIsFetched(state, value) {
        state.isFetched = value
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
