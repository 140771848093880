import ApiService from "../../../core/services/ApiService";

/* eslint-disable */
function initialState() {
    return {
        company_rate: [],
        loading: false,
        storeInProgress: false,
        skills: []
    }
}

const getters = {
    company_rate: state => state.company_rate,
    loading: state => state.loading,
    storeInProgress: state => state.storeInProgress,
    skills: state => state.skills
}

const actions = {
    fetch({ commit }, query) {
        commit("setLoading", true);
        const skill = query.skill_id != "" ? `skill=${query.skill_id}` : "";
        const branch = query.branch_id != "" ? `&branch=${query.branch_id}` : "";
        const queryString = skill + branch;

        return new Promise((res, rej) => {
            ApiService.get("api/v2", `rates/client/${query.company_id}?${queryString}`)
                    .then(response => {
                        res();
                        commit("setCompanyRate", response.data)
                        commit("setLoading", false);
                    }).catch(error => {
                        rej(error);
                        commit("setLoading", false);
                    })
        })
    },
    store({ commit }, payload) {
        commit('setStoreInProgress', true);
        return new Promise((res, rej) => {
            ApiService.post("api/v2/rates/client/", payload).then(response => {
                commit('setCompanyRate', response.data);
                commit('setStoreInProgress', false);
                res();
            }).catch(error => {
                rej(error);
            })
        })
    },
    update({ commit, state }, payload) {
        commit('setStoreInProgress', true);
        return new Promise((res, rej) => {
            ApiService.update('api/v2', `rates/update/${state.company_rate.id}`, payload).then(response => {
                commit('setCompanyRate', response.data);
                commit('setStoreInProgress', false);
                res();
            }).catch(error => {
                rej(error);
            })
        })
    },
    saveJobRates({ commit }, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`api/v2/jobs/${payload.jobID}/save-rates`, payload)
                .catch(() => {
                    reject();
                })
                .finally(() => {
                    resolve();
                })
        });
    },
    fetchSkills({ commit }, id) {
        commit('setSkills', []);
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/v2`, `skills`)
                    .then(response => {
                        commit('setSkills', response.data.data);
                        ApiService.get(`/api/v2`, `frequentSkills${id ? '?company_id=' + id : ''}`).then(response => {
                            if(response.data.preferredSkills) {
                                commit('addFrequentlyUsedSkills', { data: response.data.preferredSkills , type: "Client preferred skills"});
                            } else {
                                commit('addFrequentlyUsedSkills', { data: response.data.frequentSkills, type: "Frequently used skills"});
                            }
                            resolve();
                        })
                        .catch(() => {
                            resolve();
                        })
            })
              .catch(()=> {
                  reject();
              });
        })    
    },
}
const mutations = {
    setCompanyRate(state, data) {
        state.company_rate = data;
    },
    setLoading(state, loading) {
        state.loading = loading;
    },
    setStoreInProgress(state, loading) {
        state.storeInProgress = loading;
    },
    setSkills(state, data) {
        state.skills = data;
    },
    addFrequentlyUsedSkills(state, data) {
        if(data.data.length > 0 && (state.skills[0] && state.skills[0].id != "freq_1")){
            state.skills.unshift({
                name: data.type,
                id: "freq_1",
                children: data.data
            })
        }
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
