import ApiService from "../../../core/services/ApiService";

function initialState() {
  return {
    quotes: []
  };
}

const getters = {
  quotes: state => state.quotes
};

const actions = {
  fetchQuotes({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("api/v2/job-quotes", "fetch?page=" + payload.current)
        .then(response => {
          commit("setQuotes", response.data.data);
          resolve(response.data.meta);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  saveQuote({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`api/v2/job-quotes/${payload.editing ? 'edit' : 'save'}`, payload)
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getClientData({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`api/v2/job-quotes/get-client-data`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteJobQuote({ commit }, id) {
    return new Promise((resolve, reject) => {
      ApiService.post("api/v2/job-quotes/delete", { quoteId: id })
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getQuoteById({ commit }, id) {
    return new Promise((resolve, reject) => {
      ApiService.get("api/v2/job-quotes", id)
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  downloadJobQuote({ state, commit }, id) {
    return new Promise((resolve, reject) => {
      ApiService.download("api/v2", `job-quotes/generate/${id}`)
        .then(response => {
          const fileName = `job-quote.pdf`;
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel"
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  resetState({ commit }) {
    commit("resetState");
  }
};
const mutations = {
  setQuotes(state, payload) {
    state.quotes = payload;
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
};
