import ApiService from "../../../core/services/ApiService";
import Swal from "sweetalert2";

/* eslint-disable */
function initialState() {
  return {
    approvalsList: [],
    approvalsCounter: 0,
    loading: false,
    error: '',
    pagination: {
      current_page: 1,
      last_page: 1
    }
  }
}

const getters = {
  approvalsList: state => state.approvalsList,
  approvalsCounter: state => state.approvalsCounter,
  getPaginationData: state => state.pagination,
  loading: state => state.loading
}

const actions = {
  fetchApprovals({commit, state}, getJustApprovalsCount = null) {
    commit('setLoading', true);

    ApiService.get('api/v2',
        'approvals?page=' + state.pagination.current_page + '&perPage=10')
        .then(
        response => {
          if (getJustApprovalsCount) {
            commit('setApprovalsCounter', response.data.data.length);
          } else {
            commit('setApprovalsList' , response.data.data)
            commit('setApprovalsCounter', response.data.data.length);
            commit("setPagination", response.data)
          }
        }).catch(error => {
      commit('setError', error)
      console.log(error)
    }).finally(() => {
      commit('setLoading' , false)
    });
  },

  changeDocumentStatus({ state, commit, dispatch}, payload) {
    ApiService
        .update('/api/v2/documents', payload.id, payload)
        .then(() => {
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'success',
            title: `Document ${payload.status == 'Approved' ? 'approved' : 'declined'} successfully`,
            showConfirmButton: false,
            timer: 3000
          })
        })
        .catch(error => {
          commit("setError", error);
        })
        .finally(() => {
          dispatch("fetchApprovals");
          commit("setLoading", false)
        });
  },
  resetPageNumber({commit}){
    commit('resetPageNumber')
  },
  setPageNumber({commit} , value){
    commit('setPageNumber', value)
  },
  resetState({ commit }) {
    commit("resetState");
  }
}
const mutations = {
  setApprovalsList(state, value) {
    state.approvalsList = value
  },
  setApprovalsCounter(state, value) {
    state.approvalsCounter = value
  },
  setError(state, value) {
    state.error = value
  },
  setLoading(state, value) {
    state.loading = value
  },
  setPagination(state, value) {
    state.pagination = value;
  },
  setPageNumber(state, value){
    state.pagination.current_page = value
  },
  resetState(state) {
    state.approvalsList = [];
    state.error = '';
    state.pagination = {
      current_page: 1,
      last_page: 1
    }
  }
}

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
}
