import ApiService from "../../../core/services/ApiService";
import Swal from "sweetalert2";

/* eslint-disable */
function initialState() {
    return {
        data: [],
        loading: false,
        tableRows: [],
        presets: [],
        clientRates: [],
        isSavingRates: false
    }
}

const getters = {
    getData: state => state.data,
    presets: state => state.presets,
    tableRows: state => state.tableRows,
    loading: state => state.loading,
    isSavingRates: state => state.isSavingRates
}

const actions = {
    fetchData({ dispatch ,commit}, query) {
        commit('setLoading', true);
        commit("clearRatesArray");
        const clientAndBranch = `${query.company !== "" ? `&filter[company_id]=${query.company}` : ''}${query.branch !== "" ? `&filter[branch_id]=${query.branch}` : ''}`;
        const skillAndLevel = `${query.skill !== "" ? `&filter[parent_skill_id]=${query.skill}` : ''}${query.level !== "" ? `&filter[level_id]=${query.level}` : ''}`
        const queryString = `${clientAndBranch}${skillAndLevel}${query.invoice ? `&filter[invoice]=true` : ''}`
        return new Promise((res, rej) => {
            ApiService.get('api/v2' , `rates?${queryString}`
                ).then(
                response => {
                    commit('setData' , response.data.data);
                    res()
                }).catch(error => {
                    commit('setError', error);
                    rej(error);
                }).finally(() => {
                    commit('setLoading' , false);
                    dispatch("fetchPresets", queryString);
                });
        });       
    },
    fetchTableRows({ commit }, id) {
        ApiService.get('api/v2', `skillsForRates/${id}`)
                  .then(response => {
                    commit('setTableRows' , response.data.data)
                  })
    },
    fetchPresets({ commit }, query) {
        ApiService.get('api/v2', `rates/presets?${query}`)
            .then(response => {
                commit('setPresets' , response.data.data)
            })
    },
    clearTableRows({ commit }) {
        commit("clearTableRows");
    },
    buildRatesArray({ commit }, data) {
        commit("buildRatesArray", data);
    },
    ChangeRate({ commit }, data) {
        commit("mutateRate", data);
    },
    save({ commit, state }, data) {
        commit("isSavingRates", true);
        const rates = [];
        state.clientRates.forEach(rate => {
            const temp = {
                ...rate,
                company_id: data.data.company,
                branch_id: data.data.branch,
                night_start: (data.nightHours.start == "" ? null : data.nightHours.start),
                night_end: (data.nightHours.end == "" ? null : data.nightHours.end)
            }
            rates.push(temp);
        });
        const payload = {
            jsonData: JSON.stringify({
                company_id: data.data.company,
                branch_id: data.data.branch,
                rate_clients: rates
            })
        }
        return new Promise((res, rej) => {
            if(!data.isSavingPreset){
                ApiService.post('api/v2/rates', payload)
                        .then(response => {
                            Swal.fire({
                                position: 'top-end',
                                icon: 'success',
                                title: "Record updated successfully",
                                showConfirmButton: false,
                                timer: 1000
                            }).then(() => {
                                commit("isSavingRates", false);
                                res();
                                commit("clearRatesArray")
                            }).catch(error => {
                                rej(error);
                            })
                        })
            } else {
                const preset = {
                    jsonData: payload.jsonData,
                    presetName: data.presetName
                }
                ApiService.post('api/v2/rates/presets', preset)
                .then(() => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: "Preset saved successfully",
                        showConfirmButton: false,
                        timer: 1000
                    }).then(() => {
                        commit("isSavingRates", false);
                        res();
                    }).catch(error => {
                        rej(error);
                    })
                })
            }            
        })          
    },
    update({ commit, state }, data) {
        commit("isSavingRates", true);
        const payload = state.data;
        payload["currency"] = data.currency;
        state.clientRates.forEach(rate => {
            const shallow = payload.rate_clients.find(element => element.id == rate.id);
            shallow.weekend_price = rate.weekend_price;
            shallow.standard_price = rate.standard_price;
            shallow.night_price = rate.night_price;
            shallow.night_start = (data.nightHours.start !== "" ? data.nightHours.start : null)
            shallow.night_end = (data.nightHours.end !== "" ? data.nightHours.end : null);
            shallow.rate_worker = rate.rate_worker;
        })
        const jsondata = {
            jsonData: JSON.stringify(payload)
        }
        return new Promise((res, rej) => {
            ApiService.put('api/v2/rates', jsondata)
                    .then(() => {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: "Record updated successfully",
                            showConfirmButton: false,
                            timer: 1000
                        }).then(() => {
                            commit("isSavingRates", false);
                            res();
                            commit("clearRatesArray")
                        }).catch(error => {
                            rej(error);
                        })
                    })
        })
    },
    setDataFromPreset({ commit }, data) {
        commit("setDataFromPreset", data);
    },
    clearRatesArray({ commit }) {
        commit("clearRatesArray");
    },
    handleWorkerRate({ commit }, data) {
        commit("addOrChangeWorkerRate", data)
    }
}
const mutations = {
    setError(state, value) {
        state.error = value
    },
    setPresets(state, data) {
        state.presets = data;
    },
    clearRatesArray(state) {
        state.clientRates = [];
    },
    setDataFromPreset(state, payload) {
        payload.id = state.data.id;
        state.data = payload;
    },
    buildRatesArray(state, data) {
        state.clientRates.push(data);
    },
    addOrChangeWorkerRate(state, data) {
        state.clientRates.find(rate => rate.skill_id == data.skillID).rate_worker = data.rate;
    },
    mutateRate(state, data) {
        const rate = state.clientRates.find(rate => rate.skill_id == data.id);
        rate.weekend_price = data.rates.weekend;
        rate.standard_price = data.rates.standart;
        rate.night_price = data.rates.nightBonus;
    },
    setData(state, value) {
        state.data = value[0];
    },
    clearTableRows(state) {
        state.tableRows = [];
    },
    setTableRows(state, value) {
        state.tableRows = value;
    },
    setLoading(state, value) {
        state.loading = value
    },
    isSavingRates(state, status) {
        state.isSavingRates = status;
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
