/* eslint-disable */
import ApiService from "../../../core/services/ApiService";
import Swal from "sweetalert2";
function initialState() {
  return {
    all: [],
    relationships: {
      default_branch: "name",
      country: "title",
      invited_by: "name",
      key_account_managers: 'name'
    },
    relatedUsers: "",
    loading: false,
    fetchingUsers: false,
    message: "",
    clientsList: [],
    query: {
      search: "",
      taxId: "",
      countryId: ""
    },
    pagination: {
      current_page: 1,
      last_page: 1
    },
    usersTabPagination: {
      current_page: 1,
      last_page: 1
    },
    updatingCompanyData: false,
    singleClient: {},
    preferredSkills: [],
  };
}

const getters = {
  data: state => {
    let rows = state.all;

    if (state.query.search && state.query.search.length > 0) {
      rows = rows.filter(
        client => client.name.toLowerCase().indexOf(state.query.search.toLowerCase()) >= 0
      );
    }

    if (state.query.sort) {
      rows = _.orderBy(rows, state.query.sort, state.query.order);
    }

    return rows.slice(
      state.query.offset,
      state.query.offset + state.query.limit
    );
  },
  isUpdatingCompany: state => state.updatingCompanyData,
  total: state => state.all.length,
  loading: state => state.loading,
  relationships: state => state.relationships,
  all: state => state.all,
  clientsList: state => state.clientsList,
  getPaginationData: state => state.pagination,
  isFetchingUsers: state => state.fetchingUsers,
  getFetchedSingle: state => state.singleClient,
  getRelatedUsers: state => state.relatedUsers,
  getUsersTabPagination: state => state.usersTabPagination,
  preferredSkills: state => state.preferredSkills
};

const actions = {
  fetchData({ commit, state }, params = {}) {
    commit("setLoading", true);

    ApiService
      .get(
        "/api/v2",
        'companies?page='+
          state.pagination.current_page +
          "&perPage=10" +
        (state.query.search ? '&filter[name]=' + state.query.search : "") +
        (state.query.taxId ? '&filter[taxid]=' + state.query.taxId : "") +
        (state.query.countryId ? '&filter[countryid]=' +  state.query.countryId : '')
      )
      .then(response => {
        commit("setClientsList", response.data.data);
        commit("setPagination", response.data.meta);
      })
      .catch(error => {
        message = error.response.data.message || error.message;
        commit("setError", message);
        console.log(message);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  destroyData({ commit, state}, id) {
    ApiService
        .delete('/api/v2/companies/' + id)
        .then(response => {
          commit('setClientsList', state.clientsList.filter((item) => {
            return item.id != id
          }))
        })
        .catch(error => {
          message = error.response.data.message || error.message
          commit('setError', message)
          console.log(message)
        })
  },
  fetchSingle({ commit }, id) {
    commit("setLoading", true)
    return new Promise((res, rej) => {
      ApiService.get(
        'api/v2/companies/' + id
      ).then(
        response => {
          commit("setLoading", false)
          res(response.data.data);
          commit("setFetchedSingle", response.data.data);
        }
      ).catch(error => {
        commit("setError", error)
        rej(error);
      });
  })
  },
  fetchClientRelatedUsers({ commit, state }, value) {
    commit("setFetchingUsers", true)
    commit("setUsersTabPage", value.currentPage)
    const pagination = `page=${state.usersTabPagination.current_page}&perPage=5`
    ApiService.get(
      `api/v2`, `users?${pagination}&filter[company_id]=${value.id}&`
    ).then(
      response => {
        commit("setRelatedUsers", response.data.data);
        commit("setUsersTabPagination", { current_page: response.data.current_page, last_page: response.data.last_page});
      }
    ).catch(error => {
      commit("setError", error)
    }).finally(() => {
      commit("setFetchingUsers", false)
    })
  },
  update({ commit }, payload) {
    const kam = { key_account_managers: payload.data.key_account_managers.map(kam => kam.id)};
    const uploadedFiles = { uploaded_files : (payload.data.uploaded_files.length == 0 ) ? "" : payload.data.uploaded_files.map(file => file.id).join(",") }
    const keyAccManagerID = Object.assign(payload.data , kam);
    const data = Object.assign(keyAccManagerID  ,uploadedFiles)

    ApiService.update(
     `api/v2/companies` , payload.id , data
    ).then().catch(error => {
      console.log(error)
      commit("setError", error)
    })
  },
  createNew({ commit }, payload) {
    ApiService.post(`api/v2/companies`, payload)
              .then()
              .catch(error => {
                commit("setError", error)
              })
  },
  saveAboutCompanyData({ state, commit }, payload) {
    const data = state.singleClient;
    data.uploaded_files = (data.uploaded_files.length == 0 ) ? "" : data.uploaded_files.map(file => file.id).join(",")
    data.key_account_managers = data.key_account_managers.map(kam => kam.id);
    data.type_of_work = payload.typeOfWork;
    data.safety_requirements = payload.safetyReqs;
    data.water_policy = payload.waterPolicy;
    data.dresscode = payload.prefDresscode;
    data.food_policy = payload.foodPolicy;
    data.value_workers = payload.workerType;
    commit("setUpdatingCompanyData", true);
    ApiService.update(
      `api/v2/companies` , state.singleClient.id , data
     ).then(() => {
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: `Data updated successfully!`,
        showConfirmButton: false,
        timer: 1000
       })
     }).catch(error => {
      commit("setError", error)
     }).finally(() => {
      commit("setUpdatingCompanyData", false);
     })
  },
  setQuery({commit}, value) {
    commit("setQuery", value);
  },
  resetPageNumber({commit}){
    commit('resetPageNumber')
  },
  setPageNumber({commit} , value){
    commit('setPageNumber', value)
  },
  resetState({ commit }) {
    commit("resetState");
  },
  fetchPreferredSkills({ state, commit }) {
    return new Promise((resolve, reject) => {
      if(!state.singleClient) {
        reject();
      }
      ApiService.get(`api/v2`, `companies/preferred-skills/${state.singleClient.id}`)
        .then((response) => {
          commit("setPreferredSkills", response.data.data);
          resolve()
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  savePreferredSkills({}, query) {
    return new Promise((resolve, reject) => {
      if(!query) {
        reject("Query is a required parameter");
      }
      ApiService.post('api/v2/companies/preferred-skills', query)
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err)
        })

    });
  },
  clearPreferredSkills({ commit }) {
    commit("setPreferredSkills", []);
  }
};

const mutations = {
  setAll(state, items) {
    state.all = items;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setUpdatingCompanyData(state, value) {
    state.updatingCompanyData = value;
  },
  setPreferredSkills(state, payload) {
    state.preferredSkills = payload;
  },
  resetState(state) {
    state = Object.assign(state, initialState());
  },
  setClientsList(state, items) {
    state.clientsList = items;
  },
  setQuery(state, query) {
    state.query = query;
  },
  setPagination(state, value) {
    state.pagination = value;
  },
  setUsersTabPagination(state, value) {
    state.usersTabPagination = value;
  },
  setPageNumber(state, value){
    state.pagination.current_page = value;
  },
  setFetchedSingle(state , value) {
    state.singleClient = value;
  },
  setFetchingUsers(state, value) {
    state.fetchingUsers = value;
  },
  setRelatedUsers(state, value) {
    state.relatedUsers = value;
  },
  resetPageNumber(state){
    state.pagination.current_page = 1;
  },
  setUsersTabPage(state, value) {
    state.usersTabPagination.current_page = value;
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
};
