import ApiService from "../../../core/services/ApiService";

/* eslint-disable */
function initialState() {
    return {
        data: {},
        loading: false
    };
};

const getters = {
    loadingShifts: state => state.loading,
    getShifts: state => state.data
}

const actions = {
    async fetchShiftsByJobID({ commit , state}, jobID) {
            // This check is designated to avoid making unneeded API calls in the event that a user hit "View Shifts" button
            // on a job that already has fetched shifts for it.
            const jobShiftsAreAlreadyFetched = state.data.hasOwnProperty(jobID);
            if (jobShiftsAreAlreadyFetched) {
                return
            } else {
                commit("setLoading", true);
                await ApiService
                    .get(
                        `/api/v2`, `jobs/${jobID}/shifts`)
                    .then(response => {
                        commit("setData", {jobID: jobID, shifts: response.data.data});
                        return response.data.data;
                    })
                    .catch(error => {
                        commit("setError", error);
                    })
                    .finally(() => {
                        commit("setLoading", false);
                    });
            }
    }
}
const mutations = {
    setLoading(state, value) {
        state.loading = value;
    },
    resetState(state) {
        state = Object.assign(state, initialState());
    },
    setData(state, payload) {
        // We make sure for every fetched job shifts we have a key that is the ID of the job and the value is the job shifts.
        state.data[`${payload.jobID}`] = payload.shifts;
    },
    setEror(state, value) {
        state.error = value;
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
