import ApiService from "../../../core/services/ApiService";
import store from "@/store/index.ts";

/* eslint-disable */
function initialState() {
    return {
        nextShift: {},
        error: ''
    }
}
const getters = {
    getNextShift: state => state.nextShift
}

const actions = {
    fetchNextShift({commit}) {
        return new Promise((resolve, reject) => {
            ApiService.get(`api/v2` , `getNextShift`).then(
                response => {
                    commit('setNextShift' , response.data.data)
                    resolve();
                }).catch(error => {
                    commit('setError', error);
                    reject(error);
                })
        });
    }
}
const mutations = {
    setNextShift(state, value) {
        state.nextShift = value
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
