/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable prettier/prettier */
import ApiService from "../../../core/services/ApiService";
import Swal from "sweetalert2";
import moment from "moment";
import store from "@/store/index.ts"
import { fireToast } from "@/core/helpers/swal";

function initialState() {
    return {
        item: null,
        loading: false,
        bookableUsers: "",
        skills: "",
        shiftsWithSkill: "",
        currentlySelectedSkill: "",
        isAwaitingDelete: null,
        isAwaitingBook: null,
        allShiftsAreExpanded: false,
        pagination: {
            currentPage: 1,
            totalPages: null
        },
        successfullyBooked: "",
        isFetchingUsers: null,
        isAwaitingApproval: [],
        isReportingHours: [],
        isDeletingReport: [],
        isAwaitingDisapproval: [],
        isUpdatingHours: [],
        possibleWorkerSlots: [],
        clientRates: {
            rates: [],
            skills: []
        },
        workerRates: {
            historicalRates: [],
            skills: []
        },
        changeSkillPayload: null,
        statuses: ['Draft', 'Published', 'Cancelled', 'Report!', 'Reported', 'Approved', 'Invoiced', 'Paid'],
    };
}

const getters = {
    item: (state) => state.item,
    loading: (state) => state.loading,
    allShiftsAreExpanded: (state) => state.allShiftsAreExpanded,
    changeSkillPayload: (state) => state.changeSkillPayload,
    getPagination: (state) => state.pagination,
    statuses: (state) => state.statuses,
    bookableUsers: (state) => state.bookableUsers,
    isFetchingUsers: (state) => state.isFetchingUsers,
    shiftsWithSkill: (state) => state.shiftsWithSkill,
    getJobSkills: (state) => state.skills,
    isAwaitingDelete: (state) => state.isAwaitingDelete,
    isAwaitingBook: (state) => state.isAwaitingBook,
    successfullyBooked: (state) => state.successfullyBooked,
    isReportingHours: (state) => state.isReportingHours,
    isAwaitingApproval: (state) => state.isAwaitingApproval,
    isDeletingReport: (state) => state.isDeletingReport,
    isAwaitingDisapproval: (state) => state.isAwaitingDisapproval,
    isUpdatingHours: (state) => state.isUpdatingHours,
    getClientRates: (state) => state.clientRates,
    getSkillRates: (state) => state.workerRates,
    getPossibleWorkerSlots: (state) => state.possibleWorkerSlots
}; 

const actions = {
    async fetchOne({ commit, dispatch }, id) {
        commit('setLoading', true);
        await ApiService.get(`/api/v2/jobs/fetchJobForView`, id).then((response) => {
            commit('setItem', response.data.data)
            return response.data.data;
        }).catch((error) => {
            const message = error.response.data.message || error.message;
            const { errors } = error.response.data;
            dispatch('Alert/setAlert', { message, errors, color: 'danger' }, { root: true });
        }).finally(() => {
            commit("setJobSkills")
            commit('setLoading', false);
        });
    },
    openAdminJobChat({state, dispatch}, jobId) {
        return new Promise((resolve, reject) => {
            if(!jobId) {
                reject("Missing param: jobId");
            }
            ApiService.post('api/v2/jobs/admin-job-chat', {job_id: jobId})
              .then(( { data } ) => {
                  resolve(data.chat_id);
              })
              .catch((err) => {
                  reject(err);
              });
        });
    },
    async changeStatus({ state, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            const DBfriendly = {
                ...payload,
                shift_slots: [payload.shiftSlotId]
            }
            ApiService.put(`api/v2/applications/${payload.id}`, DBfriendly)
              .then((response) => {
                  resolve(response.data.data);
              })
              .catch((error) => {
                  reject(error)
              })
        });
    },
    async addCrewBoss({ state, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('api/v2/shifts/add-crew-boss', payload)
              .then((response) => {
                  resolve(response.data.data);
              })
              .catch((error) => {
                  reject(error)
              })
        });
    },
    async removeCrewBoss({ state, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('api/v2/shifts/remove-crew-boss', payload)
              .then((response) => {
                  resolve(response.data.data);
              })
              .catch((error) => {
                  reject(error)
              })
        });
    },
    updateAfterStatusChange({ commit, state }) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/api/v2/jobs/fetchJobForView`, state.item.id).then((response) => {
                commit('setItem', response.data.data)
                resolve(response.data.data);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },
    updateShiftSlot( { commit }, value) {
        commit("changeShiftSlotStatus", value)
    },
    changeCurrentPage({ commit }, payload) {
        commit("changePage", payload);
    },
    async fetchBookableUsers({ state, commit }, query) {
        const branches = `&branches=${query.bookFromBranches}`
        const searchString = `&username=${query.search}`
        return new Promise((resolve, reject) => {
            commit("setIsFetchingUsers", true);
            const queryString =
            `bookable-users?page=${state.pagination.currentPage}&skill=${query.skill}${branches}${searchString}`
            ApiService.get(
              'api/v2', 
              queryString
            ).then(response => {
                commit("setBookableUsers", response.data.data)
                commit("setTotalPages", response.data.last_page)
            }).catch(error => {
                reject(error)
            }).finally(() => {
                commit("setIsFetchingUsers", false)
                resolve();
            })
        })
    },
    changeBookedWorkerStatus({ commit }, payload) {
        return new Promise((resolve, reject) => {
            ApiService.put(`api/v2/applications/${payload.pivot.application_id}`, payload.payload)
              .then((response) => {
                  resolve(response.data);
              })
              .catch((error) => {
                  reject(error);
              });
        })
    },
    downloadJobReport({ state, commit }, jobID) {
        return new Promise((res, rej) => {
            ApiService.download(`api/v2`, `jobs/${jobID}/report`)
                      .then(response => {
                        const start = moment(state.item.start, "DD/MM/YYYY").format("YYYY.MM.DD");
                        const fileName = `${start} - ${state.item.company.name} - ${state.item.name}.xls`;
                        const url = URL.createObjectURL(new Blob([response.data], {
                            type: 'application/vnd.ms-excel'
                        }));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', fileName);
                        document.body.appendChild(link);
                        link.click();
                        res();
                      })
                      .catch(err => {
                        rej(err);
                      })
        })
    },
    deleteApplication({ commit, dispatch }, id) {
        return new Promise((resolve, reject) => {
            commit("isAwaitingDelete", id)
            ApiService
                .delete(
                    "/api/v2/applications/" + id
                )
                .then(() => {
                    dispatch("fetchUpdatedShift", "Delete");
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: `Application deleted successfully`,
                        showConfirmButton: false,
                        timer: 1200
                    })
                })
                .catch(error => {
                    reject(error);
                })
                .finally(() => {
                    resolve();
                });
        })
    },
    getClientRatesForJob({ commit }, id) {
        return new Promise((resolve, reject) => {
            ApiService.get('api/v2', `jobs/${id}/client-rates`)
                .then(response => {
                    commit('setClientRates', response.data)
                })
                .catch(err => {
                    reject(err)
                })
                .finally(() => {
                    resolve();
                })
        })
    },
    downloadCrewlistPDF({ state, commit }, query) {
        return new Promise((resolve, reject ) => {
            ApiService.download('api/v2', `crew-list-pdf/${state.item.id}${query.length ? `?shifts=${query}` : ''}`)
                    .then(response => {
                        const fileName = `${state.item.name} - crewlist.pdf`;
                        const url = URL.createObjectURL(new Blob([response.data], {
                            type: 'application/vnd.ms-excel'
                        }));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', fileName);
                        document.body.appendChild(link);
                        link.click();
                        resolve();
                    })
                    .catch(error => {
                        reject(error);
                    })
                })
    },
    setShiftsWithSkill({ commit }, skillID) {
        commit("saveCurrentSkill", skillID);
        commit("setShiftsWithSelectedSkill", skillID);
    },
    setAllShiftsAreExpanded({ commit }) {
        commit("setAllShiftsAreExpanded");
    },
    saveWorkerRate({ commit }, payload) {
        return new Promise((res, rej) => {
            ApiService.post(`api/v2/jobs/${payload.job_id}/save-worker-rates`, payload)
                .then(response => {
                    res(response);
                })
                .catch(err => {
                    rej(err);
                })
        })
    },
    checkIsRateEmpty({ commit }, data) {
      return new Promise((resolve , reject) => {
         if(!data) {
             reject();
         }
         commit("isAwaitingBook", data.assigneeId);
         const payload = {
             job_id: data.jobId,
             skill_id: data.skillId,
             assignee_id: data.assigneeId,
         };
         ApiService.post("api/v2/jobs/check-has-rate", payload)
           .then((response) => {
               resolve(response)
           })
           .catch((err) => {
               commit("isAwaitingBook", null);
               reject(err.response);
           })
      });
    },
    saveMissingRate({ commit }, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post("api/v2/rates/missing-rates-book", payload)
              .then(() => {
                resolve();
              })
              .catch(() => {
                reject();
              });
        })
    },
    bookUser({ state, commit, dispatch }, data) {
        const shifts = state.item.shifts.filter( el => {
            return data.shifts.some( f => {  //get the selected shifts for booking
              return f === el.id;
            });
        });
        const shiftSlots = shifts.map(a => a.shift_slots).flat() //From the selected shifts take the shifts slots and find
         .filter(a => a.skill_id == data.skill)                     //the ones where the skill required is the same as the skill selected in card
         .map(a => a.id);
        const payload = {
            assignee_id: data.assigneeId,
            shiftIds: data.shifts,
            created_by_id: data.createdBy,
            shift_slots: shiftSlots,
            status: "Booked",
            book_anyway: !!data.book_anyway,
            booked_at: moment().format("YYYY-MM-DD HH:mm:ss")
        };
        if(data.closely) payload['closely'] = true
        return new Promise((resolve, reject) => {
            ApiService
                .post(
                    "/api/v2/applications/", payload
                )
                .then(() => {
                    dispatch("fetchUpdatedShift", "Book");
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: `Application added successfully`,
                        showConfirmButton: false,
                        timer: 1000
                    }).then(() => {
                        commit("wasSuccessfullyBooked", data.assigneeId)
                    })
                })
                .catch(error => {
                    commit("isAwaitingBook", null)
                    if(error.response && error.response.status == 409) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: error.response.data.message,
                            showConfirmButton: false,
                            timer: 1000
                        })
                    } else if (error.response && error.response.status == 410) {
                        Swal.fire({
                            icon: 'error',
                            title: error.response.data.message,
                            showDenyButton: true,
                            showCancelButton: true,
                            confirmButtonText: 'Book',
                            denyButtonText: `Cancel`,
                        }).then((result) => {
                            if (result.isConfirmed) {
                            const confirmedData = {
                                ...data,
                                closely: true
                            }
                            dispatch("bookUser", confirmedData);
                            }
                        })
                    }
                    reject(error.response);
                })
                .finally(() => {
                    resolve();
                });
        })
    },
    fetchWorkerRates({ commit }, job_id) {
        return new Promise((resolve, reject) => {
            ApiService.get("api/v2", `jobs/${job_id}/worker-rates`)
                .then(response => {
                    commit('setWorkerRates', response.data);
                })
                .catch(err => {
                    reject(err);
                })
                .finally(() => {
                    resolve();
                })
        });
    },
    fetchUpdatedShift({ commit, state }, action) {
            ApiService
                .get(
                    `/api/v2/jobs/${state.item.id}/updateAfterBook`
                )
                .then((response) => {
                    commit("updateDetails", response.data.data);
                    commit("setShiftsWithSelectedSkill", state.currentlySelectedSkill)
                }).finally(() => {
                    if(action == "Delete") commit("isAwaitingDelete", null)
                    else commit("isAwaitingBook", null)
                })
    },
    deleteReportedHours({ commit, state, dispatch }, payload) {
        const reportedHours = state.item.shifts.map(shift => shift.shift_slots).flat().find(shiftSlot => shiftSlot.id == payload.shiftSlotId).reported_hours;
        reportedHours.filter(hour => hour.shift_slot_id !== payload.shiftSlotId)
        const data = reportedHours.find(reportedHour => reportedHour.created_by_id == payload.assignee_id);
        commit("isDeletingReport", payload.id);
        if(data) {
            ApiService
                .delete(
                    "/api/v2/reported-hours/" + data.id
                )
                .then(() => {
                    dispatch("fetchNewShiftSLot", payload.shiftSlotId).then(() => {
                        commit("resetIsDeletingReport", []);
                    });
                })
                .catch(error => {
                    commit("setError", error);
                })
        }else {
            const application = {
                id: payload.id,
                status: "Report!",
                assignee_id: payload.assignee_id,
                created_by_id: payload.created_by_id,
                shift_slots: [payload.shiftSlotId]
            }
            ApiService.update(`api/v2/applications`, application.id, application)
                      .then(() => {
                        dispatch("fetchNewShiftSLot", payload.shiftSlotId).then(() => {
                            commit("resetIsDeletingReport", []);
                        });
                      })
        }
    },
    updateReportedHours({ commit, dispatch, state }, payload) {
        commit("isUpdatingHours", payload.application.id);
        if(payload.reportedHour) {
            const start = `${payload.reportedHour.start.substring(0,10)} ${payload.inputs.startTime}`
            let end = `${payload.reportedHour.start.substring(0,10)} ${payload.inputs.endTime}`;
            if(moment(start,"DD/MM/YYYY HH:mm").valueOf() > moment(end,"DD/MM/YYYY HH:mm").valueOf()) {
                end = moment(end,"DD/MM/YYYY HH:mm").add(1, 'day').format("DD/MM/YYYY HH:mm");
            }
            const data = {
                id: payload.reportedHour.id,
                start: start,
                end: end,
                created_by_id: payload.reportedHour.created_by_id,
                shift_slot_id: payload.reportedHour.shift_slot_id,
                i_was_late: payload.inputs.iwasLate ? 1 : 0
            }
            ApiService.update(`api/v2/reported-hours`, payload.reportedHour.id, data)
                      .then(() => {
                        Swal.fire({
                            toast: true,
                            position: 'top-end',
                            icon: 'success',
                            title: `Report updated!`,
                            showConfirmButton: false,
                            timer: 1800
                        })
                        dispatch("fetchNewShiftSLot", payload.reportedHour.shift_slot_id).then(() =>{
                            commit("resetIsUpdatingHours");
                        });
                      }).catch((err) => {
                        Swal.fire({
                            toast: true,
                            position: 'top-end',
                            icon: 'error',
                            title: `${err}`,
                            showConfirmButton: false,
                            timer: 1300
                        })
                        commit("resetIsUpdatingHours");
                      })
        } else {
            const shiftID = state.item.shifts.map(shift => shift.shift_slots).flat().find(shiftSlot => shiftSlot.id == payload.application.shiftSlotId).shift_id;
            const shift = state.item.shifts.find(shift => shift.id == shiftID);
            const data = {
                start: `${shift.start.substring(0, 10)} ${payload.inputs.startTime}`,
                end: `${shift.end.substring(0, 10)} ${payload.inputs.endTime}`,
                i_was_late: payload.inputs.iwasLate ? 1 : 0,
                shift_slot_id: payload.application.shiftSlotId,
                created_by_id: payload.application.assignee_id
            };
            ApiService
            .post(
                "/api/v2/reported-hours", data
            )
            .then(() => {
                Swal.fire({
                    toast: true,
                    position: 'top-end',
                    icon: 'success',
                    title: `Report updated!`,
                    showConfirmButton: false,
                    timer: 1800
                })
                dispatch("fetchNewShiftSLot", payload.application.shiftSlotId).then(() =>{
                    commit("resetIsUpdatingHours");
                });
            })
            .catch(error => {
                Swal.fire({
                    toast: true,
                    position: 'top-end',
                    icon: 'error',
                    title: `${error}`,
                    showConfirmButton: false,
                    timer: 800
                })
                commit("resetIsUpdatingHours");
            })
        }
    },
    reportHours({ commit, dispatch }, payload) {
        const start = `${payload.data.start.substring(0, 10)} ${payload.reportedHours.startTime}`
        let end = `${payload.data.start.substring(0, 10)} ${payload.reportedHours.endTime}`;
        if (moment(start, "DD/MM/YYYY HH:mm").valueOf() > moment(end, "DD/MM/YYYY HH:mm").valueOf()) {
            end = moment(end,"DD/MM/YYYY HH:mm").add(1,'day').format("DD/MM/YYYY HH:mm");
        }
        const data = {
            start: start,
            end: end,
            i_was_late: payload.reportedHours.iwasLate ? 1 : 0,
            shift_slot_id: payload.data.data.shiftSlotId,
            created_by_id: payload.data.data.assignee_id
        };
        commit("setIsReportingHours", payload.data.data.id);
        return new Promise((resolve, reject) => {
            ApiService
            .post(
                "/api/v2/reported-hours", data
            )
                .then((res) => {
                    Swal.fire({
                        toast: true,
                        position: 'top-end',
                        icon: 'success',
                        title: `Report successfull!`,
                        showConfirmButton: false,
                        timer: 1800
                    })
                    resolve();
                    dispatch("fetchNewShiftSLot", payload.data.data.shiftSlotId).then(() =>{
                        commit("resetIsReportingHours", []);
                    });
                })
                .catch(error => {
                    commit("resetIsReportingHours", []);
                    reject(error);
                    Swal.fire({
                        toast: true,
                        position: 'top-end',
                        icon: 'error',
                        title: `${error}`,
                        showConfirmButton: false,
                        timer: 800
                    })
                })
        });
    },
    SendMailToColAndPM({ commit }, payload) {
        return new Promise((resolve,reject)=> {
            ApiService.post('api/v2/sendMailToColAndPM', payload).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            })
        })
    },
    approveHours({ commit, dispatch }, payload) {
        const data = {
            id: payload.id,
            status: "Approved",
            created_by_id: store.getters.currentUser.id,
            assignee_id: payload.assignee_id,
            shift_slots: payload.shiftSlotId
        }
        return new Promise((resolve, reject) => {
            commit("isAwaitingApproval", payload.id)
            ApiService.put(`api/v2/applications/${payload.id}`, data)
                      .then(() => {
                          Swal.fire({
                            toast: true,
                            position: 'top-end',
                            icon: 'success',
                            title: `Report approved!`,
                            showConfirmButton: false,
                            timer: 1800
                          })
                          resolve();
                          dispatch("fetchNewShiftSLot", payload.shiftSlotId).then(() => {
                            commit("resetIsAwatingApproval", []);
                          });
                      })
                      .catch((error) => {
                        Swal.fire({
                            toast: true,
                            position: 'top-end',
                            icon: 'error',
                            title: `${error}`,
                            showConfirmButton: false,
                            timer: 800
                        })
                        reject(error)
                      })
        })
    },
    disapproveReport({ commit, dispatch }, payload) {
            const data = {
                id: payload.application.id,
                created_by_id: payload.application.created_by_id,
                assignee_id: payload.application.assignee_id,
                status: payload.reportedHour == null ? "Report!" : "Reported",
                shift_slots: payload.application.shiftSlotId
            }
            commit("setIsAwaitingDisapproval", payload.application.id);
            ApiService.put(`api/v2/applications/${payload.application.id}`, data)
            .then(() => {
                Swal.fire({
                  toast: true,
                  position: 'top-end',
                  icon: 'error',
                  title: `Report Dissapproved!`,
                  showConfirmButton: false,
                  timer: 1800
                })
                dispatch("fetchNewShiftSLot", payload.application.shiftSlotId).then(() => {
                    commit("resetIsAwaitingDisapproval", []);
                });
            })
            .catch((error) => {
              Swal.fire({
                  toast: true,
                  position: 'top-end',
                  icon: 'error',
                  title: `${error}`,
                  showConfirmButton: false,
                  timer: 800
              })
            })
    },
    fetchNewShiftSLot({ commit }, id) {
        return new Promise((resolve, reject) => {
        ApiService.get(`/api/v2/shift-slots/${id}`)
                  .then((response) => {
                    commit("updateShift", response.data.data)
                    resolve();
                  })
                  .catch((err) => {
                    reject(err)
                  })
        })
    },
    fetchPossibleSlots({commit}, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get(`api/v2/application-change/${payload.shiftID}/${payload.application.id}`)
                .then(response => {
                    commit("setPossibleWorkerSlots", response.data)
                })
                .catch(err => {
                    reject(err);
                })
                .finally(() => {
                    resolve();
                })
        })
    },
    saveChangedSlot({commit}, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`api/v2/application-change/`, payload)
                .catch(err => {
                    reject(err);
                })
                .finally(() => {
                    resolve();
                })
        })
    },
    updateAfterSlotChange({commit}, id) {
        return new Promise( (resolve, reject) =>
            ApiService.get(`/api/v2/jobs/fetchJobForView`, id)
                .then((response) => {
                    commit('setItem', response.data.data);
                    commit("setJobSkills");
                })
                .catch((err) => {
                    reject(err);
                })
                .finally(() => {
                    resolve();
                })
        );
    },
    setChangeSkillPayload({commit}, payload) {
        commit("mutateSkillPayload", payload);
    },
    getJobCrewStatus({commit}, jobId) {
        return new Promise((resolve, reject) => {
            ApiService.get('api/v2', "job-crew-status/" + jobId)
              .then(response => {
                  resolve(response.data.crew);
              })
              .catch(err => {
                  reject(err);
              })
        })
    },
    updateShiftCrew({commit}, shiftId) {
        return new Promise((resolve, reject) => {
            ApiService.get('api/v2', "shift-crew-status/" + shiftId)
              .then(response => {
                  commit("changeShiftCrew", {id: shiftId, crew: response.data.crew })
              })
              .catch(err => {
                  reject(err);
              })
        });
    },
    clearNote({ commit }, id) {
        commit('clearNote', id);
    },
    updateNote({ commit }, payload) {
        commit('updateNote', payload)
    },
    saveJobNote({ state, commit }, note) {
        return new Promise((resolve, reject) => {
            if (!state.item) {
                reject("No job found");
            }
            const payload = {
                job_id: state.item.id,
                note: note
            }
            ApiService.post("api/v2/jobs/save-note", payload)
              .then(response => {
                  commit("addNewNote", response.data.note);
                  resolve();
              })
              .catch(err => {
                  reject(err);
              })
        });
    },
    downloadJobSalaryReport({ state }, replaceZero) {
        return new Promise((res, rej) => {
            ApiService.post(`api/v2/jobs/salaries`, { jobId: state.item.id, replaceZero: replaceZero })
              .then(response => {
                  const link = document.createElement('a');
                  link.href = response.data;
                  document.body.appendChild(link);
                  link.click();
                  res();
              })
              .catch(err => {
                  console.log(err.response)
                  if(err.response) {
                      if(err.response.data && err.response.data.type === "ERR.NO_REPORTS_FOR_JOB") {
                        fireToast("No reports for requested job", false);
                      }
                      if(err.response.data.type === "MissingRates") {
                          rej(err.response.data);
                      }
                  }
                  rej(err);
              })
        })
    },
    pinNote({ commit }, noteId) {
        return new Promise((resolve, reject) => {
            ApiService.post("api/v2/jobs/pin-note", { noteId: noteId })
              .then(() => {
                 commit("pinNote", noteId)
                 resolve();
              })
              .catch((err) => {
                 reject(err);
              });
        });
    }
};

const mutations = {
    pinNote(state, noteId) {
        if(state.item) {
            const noteIndex = state.item.notes.findIndex((i) => i.id === noteId);
            if(-1 !== noteIndex) {
                state.item.notes[noteIndex].is_pinned = !state.item.notes[noteIndex].is_pinned;
            }
        }
    },
    updateNote(state, payload) {
        if(state.item) {
            const noteIndex = state.item.notes.findIndex((i) => i.id === payload.noteId);
            if(-1 !== noteIndex) {
                state.item.notes[noteIndex].comment = payload.content;
            }
        }
    },
    clearNote(state, id) {
        if(state.item) {
            const noteIndex = state.item.notes.findIndex((i) => i.id === id);
            if(-1 !== noteIndex) {
                state.item.notes.splice(noteIndex, 1);
            }
        }
    },
    addNewNote(state, payload) {
      if(state.item) {
          if(!state.item.notes) {
              state.item.notes = [];
          }
          state.item.notes = [payload, ...state.item.notes];
      }
    },
    changeShiftCrew(state, data) {
        const shift = state.item.shifts.find(shift => shift.id === data.id);
        if(shift) {
            shift.crew = data.crew;
        }
    },
    setItem(state, item) {
        state.item = item;
    },
    mutateSkillPayload(state, data) {
        state.changeSkillPayload = data;
    },
    updateShift(state, payload) {
        const index = state.item.shifts.find(shift => shift.id == payload.shift_id).shift_slots.findIndex(shiftSlot => shiftSlot.id == payload.id)
        const shift_slot = state.item.shifts.find(shift => shift.id == payload.shift_id).shift_slots[index];
        if(shift_slot) {
            shift_slot.applications = payload.applications;
            shift_slot.reported_hours = payload.reported_hours;
        }
    },
    resetIsReportingHours(state) {
        state.isReportingHours = [];
    },
    setIsReportingHours(state, value) {
        state.isReportingHours.push(value);
    },
    setPossibleWorkerSlots(state, value) {
        state.possibleWorkerSlots = value;
    },
    setBookableUsers(state, value) {
        state.bookableUsers = value;
    },
    saveCurrentSkill(state, value) {
        state.currentlySelectedSkill = value;
    },
    resetIsDeletingReport(state, payload) {
        state.isDeletingReport = payload;
    },
    isUpdatingHours(state, value) {
        state.isUpdatingHours.push(value);
    },
    setWorkerRates(state, payload) {
        state.workerRates = payload;
    },
    resetIsUpdatingHours(state) {
        state.isUpdatingHours = [];
    },
    resetIsAwaitingDisapproval(state) {
        state.isAwaitingDisapproval = [];
    },
    setIsAwaitingDisapproval(state, value) {
        state.isAwaitingDisapproval.push(value);
    },
    isDeletingReport(state, payload) {
        state.isDeletingReport.push(payload);
    },
    setStatus(state, value) {
        state.item.status = value;
    },
    setAllShiftsAreExpanded(state) {
      state.allShiftsAreExpanded = !state.allShiftsAreExpanded;
    },
    setTotalPages(state, value) {
        state.pagination.lastPage = value;
    },
    setCurrentPage(state, value) {
        state.pagination.currentPage = value;
    },
    setLoading(state, value) {
        state.loading = value;
    },
    setClientRates(state, payload) {
        state.clientRates = payload;
    },
    changeShiftSlotStatus(state, values) {
       const application = state.item.shifts.find(shift => shift.id == values.shiftID)
          .shift_slots.find(slot => slot.id == values.shiftSlotID)
          .applications.find(application => application.id == values.id);
        if(application) {
            application.status = values.newStatus;
            if(values.booked_by) {
                application.booked_by = values.booked_by;
            }
        }
    },
    changePage(state, value) {
        state.pagination.currentPage = value;
    },
    setIsFetchingUsers(state, value) {
        state.isFetchingUsers = value;
    },
    setJobSkills(state) {
        const skills = state.item.shifts
              .map(a => a.shift_slots)
              .flat()
              .map(a => a.skill);
        const removeDuplicateSkills = Array.from(new Set(skills.map(a => a.id))).map( 
            id => {
            return skills.find(a => a.id === id);
            }
        );
        state.skills = removeDuplicateSkills;
    },
    setShiftsWithSelectedSkill(state, value) {
        const shiftsWithSkill = state.item.shifts.map(a => a.shift_slots)
        .flat().filter(a => a.skill_id == value).map(a => a.shift_id) // take the shift ids of the shift slots with the selected skill
        const shifts = state.item.shifts.filter( el => {
            return shiftsWithSkill.some( f => {
              return f === el.id;
            });
          });
        state.shiftsWithSkill = shifts;
    },
    resetIsAwatingApproval(state) {
        state.isAwaitingApproval = []
    },
    isAwaitingApproval(state, value) {
        state.isAwaitingApproval.push(value);
    },
    updateDetails(state, value) {
        state.item.crew = value.crew;
        state.item.shifts = value.shifts;
    },
    isAwaitingDelete(state, value) {
        state.isAwaitingDelete = value;
    },
    isAwaitingBook(state, value) {
        state.isAwaitingBook = value;
    },
    wasSuccessfullyBooked(state, value) {
        state.successfullyBooked = value;
    }
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
