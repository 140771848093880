import ApiService from "../../../core/services/ApiService";
import Swal from "sweetalert2";

/* eslint-disable */
function initialState() {
  return {
    documentTypesList: [],
    loading: false,
    error: '',
    pagination: {
      current_page: 1,
      last_page: 1
    }
  }
}

const getters = {
  documentTypesList: state => state.documentTypesList,
  getPaginationData: state => state.pagination,
  loading: state => state.loading
}

const actions = {
  fetchData({commit, state}) {
    commit('setLoading', true);

    ApiService.get('api/v2',
        'document-types?page=' + state.pagination.current_page + '&perPage=10')
        .then(
        response => {
          commit('setDocumentTypesList' , response.data.data)
          commit("setPagination", response.data)
        }).catch(error => {
      commit('setError', error)
      console.log(error)
    }).finally(() => {
      commit('setLoading' , false)
    });
  },

  createNew({commit, dispatch }, payload) {
    ApiService
        .post(
            "/api/v2/document-types" , payload
        )
        .then(() => {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: `Document type created successfully`,
            showConfirmButton: false,
            timer: 2000
          })
        })
        .catch(error => {
          commit("setError", error);
        })
        .finally(() => {
          dispatch("fetchData");
        });
  },

  update({ commit , dispatch}, payload) {
    commit("setLoading", true);
    ApiService
        .update(
            "/api/v2/document-types", payload.id, payload.values
        )
        .then(() => {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: `Document type edited successfully`,
            showConfirmButton: false,
            timer: 2000
          })
        })
        .catch(error => {
          commit("setError", error);
        })
        .finally(() => {
          dispatch("fetchData");
          commit("setLoading", false)
        });
  },

  destroyData({commit, dispatch}, id) {
    ApiService
        .delete(
            "/api/v2/document-types/" + id
        )
        .then(() => {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: `Document type deleted successfully`,
            showConfirmButton: false,
            timer: 2000
          })
        })
        .catch(error => {
          commit("setError", error);
        })
        .finally(() => {
          dispatch("fetchData");
        });
  },
  resetPageNumber({commit}){
    commit('resetPageNumber')
  },
  setPageNumber({commit} , value){
    commit('setPageNumber', value)
  },
  resetState({ commit }) {
    commit("resetState");
  }
}
const mutations = {
  setDocumentTypesList(state, value) {
    state.documentTypesList = value
  },
  setError(state, value) {
    state.error = value
  },
  setLoading(state, value) {
    state.loading = value
  },
  setPagination(state, value) {
    state.pagination = value;
  },
  setPageNumber(state, value){
    state.pagination.current_page = value
  },
  resetState(state) {
    state = Object.assign(state, initialState())
  }
}

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
}
