import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import DashboardModule from "@/store/modules/Dashboard/index.js";
import ListsModule from "@/store/modules/Lists/index.js";
import MyDocumentsModule from "@/store/modules/Documents/myDocuments.js"
import UsersModule from "@/store/modules/Users/index.js";
import RulesModule from "@/store/modules/rules.js";
import { Ability } from '@casl/ability';
import AbilityModule from "@/store/modules/AbilityModule.js"
import ClientsModule from "@/store/modules/Clients/index.js";
import LevelsModule from "@/store/modules/Levels/index.js";
import BranchesModule from "@/store/modules/Branches/index.js";
import DresscodesModule from "@/store/modules/Dresscodes/index.js";
import DocumentsModule from "@/store/modules/Documents/index.js";
import DocumentTypesModule from "@/store/modules/DocumentTypes/index.js";
import SkillsModule from "@/store/modules/Skills/index.js";
import EquipmentCategoriesModule from "@/store/modules/EquipmentCategories/index.js";
import FreelancersModule from "@/store/modules/Freelancers/index.js";
import CountriesModule from "@/store/modules/Countries/index.js";
import LanguagesModule from "@/store/modules/Languages/index.js";
import PermissionsModule from "@/store/modules/Permissions/index.js";
import RolesModule from "@/store/modules/Roles/index.js";
import VenuesModule from "@/store/modules/Venues/index.js";
import SalaryReportsModule from "@/store/modules/Reports/salary.js"
import CreateUserModule from "@/store/modules/Users/create.js";
import HelpModule from "@/store/modules/Help/index.js";
import JobModule from "@/store/modules/Jobs/single.js";
import NotesModule from "@/store/modules/Notes/index.js"
import HelpCategoriesModule from "@/store/modules/HelpCategories/index.js";
import ShiftsModule from "@/store/modules/Shifts/index.js";
import MyDashboardModule from "@/store/modules/My-dashboard/index.js";
import JobCreateModule from "@/store/modules/Jobs/create.js";
import CalendarModule from "@/store/modules/CalendarModule.js";
import MySkillsModule from "@/store/modules/My-skills/index.js";
import EditJobModule from "@/store/modules/Jobs/edit.js";
import JobTemplatesModule from "@/store/modules/Job-templates/index.js";
import SingleJobTemplateModule from "@/store/modules/Job-templates/single.js";
import AdminCalendarModule from "@/store/modules/AdminCalendarModule.js";
import ActivitiesModule from "@/store/modules/Activities/index.js";
import RatesModule from "@/store/modules/Rates/index.js";
import WidgetsModule from "@/store/modules/Widgets/index.js";
import RegistrationModule from "@/store/modules/Registration/index.js";
import ApplicantsModule from "@/store/modules/Applicants/index.js";
import StatisticsModule from "@/store/modules/Statistics/index.js";
import NewRatesModule from "@/store/modules/Rates/newRates.js";
import PriceEstimationModule from "@/store/modules/Rates/priceEstimates.js";
import WorkerRatesModule from "@/store/modules/Rates/workerRates.js";
import AdditionalCostsModule from "@/store/modules/Jobs/additional-costs.js";
import LiveChatModule from "@/store/modules/Live-chat/index.js";
import OtpModule from "@/store/modules/OtpModule.js";
import RosterModule from "@/store/modules/Roster/index.js"
import JobQuotesModule from "@/store/modules/JobQuotes/index.js";
import OnCallUsersModule from "@/store/modules/OnCall/index.js";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    OnCallUsersModule,
    LiveChatModule,
    ConfigModule,
    JobQuotesModule,
    EditJobModule,
    WorkerRatesModule,
    NewRatesModule,
    AdminCalendarModule,
    DashboardModule,
    PriceEstimationModule,
    AdditionalCostsModule,
    ListsModule,
    RulesModule,
    UsersModule,
    LevelsModule,
    ClientsModule,
    BranchesModule,
    DresscodesModule,
    DocumentsModule,
    DocumentTypesModule,
    SkillsModule,
    EquipmentCategoriesModule,
    FreelancersModule,
    CountriesModule,
    LanguagesModule,
    PermissionsModule,
    RolesModule,
    VenuesModule,
    SalaryReportsModule,
    CreateUserModule,
    JobModule,
    NotesModule,
    MyDocumentsModule,
    HelpModule,
    ShiftsModule,
    HelpCategoriesModule,
    JobCreateModule,
    MyDashboardModule,
    CalendarModule,
    MySkillsModule,
    JobTemplatesModule,
    SingleJobTemplateModule,
    ActivitiesModule,
    RatesModule,
    WidgetsModule,
    RegistrationModule,
    ApplicantsModule,
    StatisticsModule,
    OtpModule,
    RosterModule
  },
  plugins: [AbilityModule],
  state: {
    rules: []
  },
  getters: {
    ability() {
      return new Ability([]);
    }
  },
});

export default store;
