/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable prettier/prettier */
import ApiService from "../../../core/services/ApiService";
import Swal from "sweetalert2";
import store from "@/store/index.ts";
import moment from "moment";
import * as lodash from "lodash";
import ability from "@/config/ability";

function initialState() {
    return {
        blueprint: {
            dateStart: null,
            dateEnd: null,
            duration: null,
            startHour: null,
            finishHour: null,
            skills: {}
        },
        selectedCompany: null,
        skills: [],
        isFetchingFreqSkills: false,
        JobData: {
            name: null,
            company_id: null,
            country_id: null,
            city: null,
            COLid: null,
            dresscode: null,
            venue_id: null,
            entrance: null,
            invoice: null,
            address: null,
            details: null,
            book_from_branches: [],
            zip: null,
            province: null,
            project_manager_id: null,
            reason: "",
            bookerId: null,
        },
        templatesPagination: {
            currentPage: 1,
            lastPage: 1
        },
        hasTemplates: false,
        isSavingTemplate: false,
        jobTemplates: [],
        shifts: [],
        details: [],
        loading: false,
        bookerSearchResults: [],
    };
}

const getters = {
    blueprint: (state) => state.blueprint,
    shifts: (state) => state.shifts,
    JobData: (state) => state.JobData,
    loading: (state) => state.loading,
    jobTemplates: (state) => state.jobTemplates,
    isSavingTemplate: (state) => state.isSavingTemplate,
    isLoadingTemplates: (state) => state.isLoadingTemplates,
    getPagination: (state) => state.templatesPagination,
    skills: (state) => state.skills,
    isFetchingFreqSkills: (state) => state.isFetchingFreqSkills,
    hasTemplates: state => state.hasTemplates,
    bookerSearchResults: state => state.bookerSearchResults,
    selectedCompany: state => state.selectedCompany
};

const actions = {
    addShift({ commit }, shift) {
        commit('addShift', shift);
    },
    changePage({ commit, dispatch }, value) {
        commit("setCurrentPage", value);
        dispatch("fetchTemplates");
    },
    setDetails({ commit }, values) {
        commit("handleDetailsSet", values)
    },
    resetShifts({ commit }) {
        commit('resetShifts')
    },
    setShiftSkills({ commit }, payload) {
        commit('setShiftSkills', payload)
    },
    setJobData({commit}, data) {
        commit('setJobData', data)
    },
    fetchTemplates({ commit, state }) {
        if(state.selectedCompany == null) {
            commit("resetTemplates");
            return;
        }
        commit("isLoadingTemplates", true);
        commit("resetTemplates");
        ApiService.get('api/v2', `job-templates?${state.selectedCompany ? `filter[company_id]=${state.selectedCompany}&` : ''}perPage=5&page=${state.templatesPagination.currentPage}`)
          .then((response) => {
              commit("setTemplates", response.data.data);
              commit("setHasTemplates", response.data.data.length > 0 ? true : false);
              commit("setPagination", response.data.meta)
              commit("isLoadingTemplates", false)
          })
    },
    setBlueprint({ commit }, data) {
        commit("setBlueprint", data);
    },
    searchBookers({ commit }, queryString) {
      return new Promise((resolve, reject) => {
         ApiService.get("api/v2", "search-bookers?queryString=" + queryString)
           .then((response) => {
             commit("setSearchResults", response.data.data);
             resolve();
           })
           .catch((err) => {
               reject(err);
           });
      });
    },
    setSelectedCompany({commit}, payload) {
      commit('setSelectedCompany', payload);
    },
    createJob({ state, commit }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            const shifts = [];
            for(let i = 0; i < state.shifts.length ; i++) {
                shifts.push ({
                    startDate: state.shifts[i].startDate,
                    start: `${state.shifts[i].startDate} ${state.shifts[i].startHour}`,
                    endHour: state.shifts[i].endHour,
                    slots: state.shifts[i].skills,
                    ...state.details.find(a => a.id == state.shifts[i].id).details
                })
            }
            if(shifts.length === 0) {
                reject({
                    response: {
                        data: {
                            type: "shifts_invalid",
                            message: "Please enter valid shifts."
                        }
                    }
                });
                commit('setLoading', false);
            }
            const payload = {
                name: state.JobData.name,
                company_id: state.JobData.company,
                country_id: state.JobData.country,
                city: state.JobData.city,
                is_past: false,
                status: "Published", //we will make that change depending on user choice just for publish functionality for now
                venue_id: state.JobData.venueId,
                entrance: state.JobData.entrance,
                invoice_reference: state.JobData.invoice,
                contact_on_location_id: state.JobData.COLid,
                address: state.JobData.address,
                details: state.JobData.details,
                book_from_branches: state.JobData.branches,
                zip: state.JobData.postal,
                province: state.JobData.area,
                project_manager_id: state.JobData.projectManager,
                reason: "",
                dresscode_id: state.JobData.dresscode,
                start: state.blueprint.dateStart ? state.blueprint.dateStart : shifts[0].startDate,
                end: state.blueprint.dateEnd,
                shifts: shifts
            }
            if(ability.can('can_select_job_booker', 'all')) {
                payload['booker_id'] =  state.JobData.bookerId ? state.JobData.bookerId : null;
            }
            ApiService.post(`api/v2/jobs`, payload)
            .then(() => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: `Job created successfully!`,
                    showConfirmButton: false,
                    timer: 2000
                })
            })
            .catch(response => {
                reject(response);
                commit('setLoading', false);
            })
            .finally(() => {
                commit('setLoading', false);
                resolve();
            });
        })
    },
    deleteShift({ commit }, id) {
        commit("deleteShift", id);
    },
    saveTemplate({ commit, state }, payload) {
        const shifts = []
        state.shifts.forEach(el => {
            shifts.push({
                ...el,
                ...state.details.find(a => a.id == el.id).details
            })
        })
        const toJSON = () => {
          return JSON.stringify({
                JobData: payload.data,
                shifts: shifts
          });
        }
        const data = {
          created_by_id: store.getters.currentUser.id,
          company_id: payload.data.company !== "" ? payload.data.company : null,
          data: toJSON(),
          name: payload.name
        }
        commit("setIsSavingTemplate", true)
        return new Promise((resolve, reject) => {
            ApiService.post('api/v2/job-templates', data)
                .then(() => {
                    resolve();
                }).finally(() => {
                    commit("setIsSavingTemplate", false)
                    reject();
                });
        })
    },
    setShiftEndHour({ commit }, data) {
        commit("changeEndHour", data);
    },
    deleteSkillFromShift({commit}, payload) {
        commit("removeSkill", payload);
    },
    populateShifts({ commit }, payload) {
        commit("populateShiftsFromTemplate", payload)
    },
    addSingleShift({ commit }, payload) {
        commit("addSingleShift", payload);
    },
    deleteBlueprintSkill({ commit }, payload) {
        commit("deleteBlueprintSkill", payload);
    },
    removeZeroSlotSkill({ commit }, data) {
        commit("removeZeroSlotSkill", data);
    },
    fetchSkills({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            dispatch("fetchFrequentlyUsedSkills");
            ApiService.get(`/api/v2`, `skills?sort=order&order=asc&filter[parent_id]=null`)
            .then(response => {
                commit('setSkills', response.data.data);
                resolve()
            })
            .catch(() => {
                reject()
            })
        });
    },
    changeShiftSkillCount({ commit }, data) {
        commit("mutateShiftSkillCount", data);
    },
    fetchFrequentlyUsedSkills({ commit, state }, id) {
        return new Promise((resolve, reject) => {
            commit("setIsFetchingFreqSkills", true);
            ApiService.get(`/api/v2`, `frequentSkills${id ? '?company_id=' + id : ''}`).then(response => {
                commit("setIsFetchingFreqSkills", false);
                if(response.data.preferredSkills) {
                    commit('addFrequentlyUsedSkills', { data: response.data.preferredSkills , type: "Client preferred skills"});
                } else {
                    commit('addFrequentlyUsedSkills', { data: response.data.frequentSkills, type: "Frequently used skills"});
                }
                resolve();
            }).catch(() => {
                commit("setIsFetchingFreqSkills", false);
                reject();
            });
        });
    },
    clearPreviousFrequentSkills({ commit }) {
        commit('clearPreviousFrequentSkills');
    },
    setSearchResults({ commit }, payload) {
        commit("setSearchResults", payload);
    },
    resetState({ commit }) {
        commit("resetState");
    }
};

const mutations = {
    addShift(state, shift) {
        state.shifts.push(lodash.cloneDeep(shift));
    },
    setSkills(state, data) {
        state.skills = data;
    },
    clearPreviousFrequentSkills(state) {
        if(state.skills[0] && state.skills[0].id == "freq_1") {
            state.skills.shift();
        }
    },
    setSelectedCompany(state, payload) {
        state.selectedCompany = payload;
    },
    setIsFetchingFreqSkills(state, status) {
        state.isFetchingFreqSkills = status;
    },
    addFrequentlyUsedSkills(state, data) {
        if(data.data.length > 0 && (state.skills[0] && state.skills[0].id != "freq_1")){
            state.skills.unshift({
                name: data.type,
                id: "freq_1",
                children: data.data
            })
        }
    },
    setTemplates(state, payload) {
        for(let i = 0; i < payload.length; i++) {
            state.jobTemplates.push({
                created_by_id: payload[i].created_by_id,
                id: payload[i].id,
                JobData: JSON.parse(payload[i].json_data).JobData,
                shifts: JSON.parse(payload[i].json_data).shifts,
                company_id: payload[i].company_id,
                created_by: payload[i].created_by,
                company_name: payload[i].company_name,
                name: payload[i].name
            })
        }
    },
    populateShiftsFromTemplate(state, payload) {
        if(payload.shift.length > 0){
            state.shifts = [];
            const firstShiftDate = moment(payload.shift[0].startDate, "DD/MM/YYYY")
            const difference = moment(payload.userSelectedDate, "DD/MM/YYYY").diff(firstShiftDate , "days");
            for(let i = 0; i < payload.shift.length ; i++) {
                const shift = Object.assign({} ,payload.shift[i]);
                shift.details = {
                    pm: shift.pm ? shift.pm : "",
                    col: shift.col ? shift.col : "",
                    dresscodeID: shift.dresscodeID ? shift.dresscodeID : "",
                    dresscodeNotes: shift.dresscodeNotes ? shift.dresscodeNotes : "",
                    workerNotes: shift.workerNotes ? shift.workerNotes : "",
                    description: shift.description ? shift.description : ""
                };
                shift.startDate = moment(shift.startDate , "DD/MM/YYYY").add(difference, "days").format("DD/MM/YYYY")
                shift.startLabel = moment(shift.startLabel , "ddd DD/MM/YYYY").add(difference, "days").format("ddd DD/MM/YYYY")
                state.shifts.push(shift);
            }
        }
    },
    resetTemplates(state) {
        state.jobTemplates = [];
    },
    addSingleShift(state, payload) {
        const shift = {
            id: state.shifts.length + 1,
            startHour: payload.startHour,
            endHour: payload.endHour,
            startDate : payload.dateStart,
            skills : payload.skills,
            status: true,
            startLabel: moment(payload.dateStart, "DD/MM/YYYY").format("ddd DD/MM/YYYY")
        };
        state.shifts.push(lodash.cloneDeep(shift));
        state.shifts.sort((a, b) => {
            return moment(a.startDate, "DD/MM/YYYY").valueOf() - moment(b.startDate, "DD/MM/YYYY").valueOf()
        })
    },
    changeEndHour(state, data) {
        const startHour = state.shifts.find(shift => shift.id == data.shiftID).startHour;
        const minutes = moment.duration(data.duration, 'kk:mm').asMinutes()
        state.shifts.find(shift => shift.id == data.shiftID).endHour = moment(startHour, "kk:mm").add(minutes, 'minutes').format("kk:mm");
    },
    setBlueprint(state, value) {
        state.blueprint = lodash.cloneDeep(value);

    },
    removeSkill(state, payload) {
        const shift = state.shifts.find(shift => shift.id == payload.id);
        const skills = Object.assign({}, shift.skills);
        delete skills[payload.skill.id];
        shift.skills = skills;
    },
    deleteBlueprintSkill(state, payload) {
        delete state.blueprint.skills[payload.id];
    },
    setPagination(state, value) {
        state.templatesPagination.currentPage = value.current_page;
        state.templatesPagination.lastPage = value.last_page;
    },
    resetShifts(state) {
        state.shifts = [];
    },
    setSearchResults(state, payload) {
        state.bookerSearchResults = payload;
    },
    deleteShift(state, id) {
       state.shifts = state.shifts.filter(shift => shift.id != id);
    },
    mutateShiftSkillCount(state, data) {
        state.shifts[data.shiftID].skills[data.skillID].qty = data.qty;
    },
    isLoadingTemplates(state, value) {
        state.isLoadingTemplates = value;
    },
    setShiftSkills(state, payload) {
        state.shifts[payload.shiftKey].skills = Object.assign({}, payload.skills);
    },
    setJobData(state, payload) {
        state.JobData = payload;
    },
    removeZeroSlotSkill(state, payload) {
        delete state.shifts[payload.id].skills[payload.skillID];
    },
    handleDetailsSet(state, value) {
        state.details.push(value);
    },
    setCurrentPage(state, value) {
        state.templatesPagination.currentPage = value;
    },
    setLoading(state, payload) {
        state.loading = payload;
    },
    setIsSavingTemplate(state, value) {
        state.isSavingTemplate = value;
    },
    setHasTemplates(state, value) {
        state.hasTemplates = value
    },
    resetState(state) {
        state = Object.assign(state, initialState());
    }
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
