/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable prettier/prettier */
import ApiService from "../../../core/services/ApiService";
import router from '@/router/index.ts'
import Swal from "sweetalert2";

function initialState() {
  return {
    template: null,
    loading: false,
    shifts: []
  };
};

const getters = {
  getTemplate: state => state.template,
  loading: state => state.loading,
  templateShifts: state => state.shifts
};

const actions = {
  fetchSingle({ commit }, id) {
    commit("setLoading", true);

    ApiService.get("api/v2", `job-templates/${id}`)
              .then((response) => {
                commit("setLoading", false);
                commit("setData", response.data.data);
                commit("setShifts", response.data.data);
              })
              .catch((error) => {
                console.log(error)
              })
  },
  update({ commit, state }, payload) {
    const data = {
        created_by_id: state.template.created_by_id,
        company_id: payload.data.company,
        data: JSON.stringify({JobData: payload.data, shifts: state.shifts}),
        name: payload.name
    } 
    commit("setLoading", true);
    ApiService.update("/api/v2/job-templates", state.template.id, data)
              .catch((error) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: error,
                    showConfirmButton: false,
                    timer: 1000
                });
                commit("setLoading", false);
              })
              .finally(() => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: `Job template edited successfully`,
                    showConfirmButton: false,
                    timer: 1000
                });
                router.push("/jobs/templates");
                commit("setLoading", false);
              })
  },
  setShiftSkills({ commit, state }, payload) {
    commit("addSkillsToShift", payload);
  },
  addNewShift({ commit }, payload) {
    commit("addShiftToExisting", payload);
  },
  deleteSkillsFromShifts({ commit }, payload) {
    commit("removeSkillFromShift", payload);
  },
  deleteShift({ commit }, shiftID) {
    commit("removeShift", shiftID);
  },
  resetState({ commit }) {
    commit("resetState");
  }
};

const mutations = {
  setLoading(state, value) {
    state.loading = value;
  },
  addShiftToExisting(state, payload) {
    const data = {
      startDate: payload.dateStart,
      startHour: payload.startHour,
      endHour: payload.endHour,
      skills: payload.skills,
      status: true,
      id: state.shifts.length == 0 ? 0 : state.shifts.map(a => a.id).reduce((a, b) => a + b) + 1
    };
    state.shifts.push(data);
  },
  removeShift(state, shiftID) {
    state.shifts.splice(state.shifts.findIndex(shift => shift.id == shiftID), 1)
    for(let i = 0; i < state.shifts.length; i++) {
      state.shifts[i].id--;
    }
  },
  addSkillsToShift(state, payload) {
    console.log(state.shifts, payload)
    state.shifts[payload.shiftKey].skills = payload.skills;
  },
  removeSkillFromShift(state, payload) {
    delete state.shifts.find(shift => shift.id == payload.shiftID).skills[payload.skillID];
  },
  setShifts(state, value) {
    const shifts = JSON.parse(value.json_data).shifts;
    if(shifts.length > 0){ 
      let id = 0
      for(let i = 0; i < shifts.length; i++) {
        shifts[i].id = id;
        id++;
      }
      state.shifts = shifts;
    }
  },
  setData(state, value) {
    state.template = value;
  },
  resetState(state) {
    Object.assign(state, initialState());
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
};
