import ApiService from "../../../core/services/ApiService";
import Swal from "sweetalert2";

/* eslint-disable */
function initialState() {
    return {
        data: {},
        loading: false,
        pagination: {
            currentPage: 1,
            lastPage: 1
        },
        sortParams: {
            sortedBy: "id", //Default Values
            isOrderAsc: true
        }
    };
};

const getters = {
    loading: state => state.loading,
    getData: state => state.data,
    getPaginationData: state => state.pagination,
    getSortData: state => state.sortParams
}

const actions = {
    fetchData({ commit , state}) {
            commit("setLoading", true);
            ApiService
            .get(
                "/api/v2",
                "languages?page="+ state.pagination.currentPage +
                "&perPage=10" +
                "&sort="+ state.sortParams.sortedBy + 
                "&order=" + ((state.sortParams.isOrderAsc) ? "asc" : "desc")
              )
                .then(response => {
                    commit("setData", response.data.data);
                    commit("setPaginationData", response.data.last_page)
                })
                .catch(error => {
                    commit("setError", error);
                })
                .finally(() => {
                    commit("setLoading", false);
                });
    },

    update({ commit , dispatch}, payload) {
        commit("setLoading", true);
        ApiService
        .update(
            "/api/v2/languages", payload.id, payload.values
          )
            .then(() => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: `Language edited successfully`,
                    showConfirmButton: false,
                    timer: 2000
                })
            })
            .catch(error => {
                commit("setError", error);
            })
            .finally(() => {
                dispatch("fetchData");
                commit("setLoading", false)
            });
    },
    createNew({commit, dispatch }, payload) {
        ApiService
        .post(
            "/api/v2/languages" , payload
          )
            .then(() => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: `Language created successfully`,
                    showConfirmButton: false,
                    timer: 2000
                })
            })
            .catch(error => {
                commit("setError", error);
            })
            .finally(() => {
                dispatch("fetchData");
            });
    },
    destroyData({commit, dispatch}, value) {
        ApiService
         .delete(
             "/api/v2/languages/" + value
           )
            .then(() => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: `Language deleted successfully`,
                    showConfirmButton: false,
                    timer: 2000
                })
            })
             .catch(error => {
                 commit("setError", error);
            })
            .finally(() => {
                dispatch("fetchData");
             });
    },
    setCurrentPage({commit}, value) {
        commit("setCurrentPage", value);
    },
    resetState({commit}) {
        commit("resetState");
    },
    setSortParams({commit}, value){
        commit("setSortParams" , value)
    }
}
const mutations = {
    setLoading(state, value) {
        state.loading = value;
    },
    resetState(state) {
        state = Object.assign(state, initialState());
    },
    setData(state, value) {
        state.data = value;
    },
    setEror(state, value) {
        state.error = value;
    },
    setPaginationData(state, value) {
        state.pagination.lastPage = value;
    },
    resetPageNumber(state){
        state.pagination.currentPage = 1;
    },
    setCurrentPage(state, value) {
        state.pagination.currentPage = value;
    },
    setSortParams(state, value){
        state.sortParams = value
    }
    
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
