import ApiService from "@/core/services/ApiService";

function initialState() {
  return {
    onCallUsers: []
  };
}

const getters = {
  onCallUsers: state => state.onCallUsers
};

const actions = {
  fetchOnCallUsers({ commit }) {
    return new Promise((resolve, reject) => {
      ApiService.get("api/v2", "on-call")
        .then(({ data }) => {
          commit("setOnCallUsers", data.data);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  addOnCallUser({ commit }, userId) {
    return new Promise((resolve, reject) => {
      ApiService.post("api/v2/on-call", { userId: userId })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  removeOnCallUser({ commit }, id) {
    return new Promise((resolve, reject) => {
      ApiService.delete("api/v2/on-call/" + id)
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  searchUsers({ commit }, queryString) {
    return new Promise((resolve, reject) => {
      if ("string" !== typeof queryString) {
        reject("Badly formed data");
      }
      ApiService.get("api/v2/on-call", `search-users?userName=${queryString}`)
        .then(response => {
          resolve(response.data.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

const mutations = {
  setOnCallUsers(state, payload) {
    state.onCallUsers = payload;
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
};
