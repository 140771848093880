/* eslint-disable */
import ApiService from "../../../core/services/ApiService";
import Swal from "sweetalert2";

function initialState() {
  return {
    data: [],
    loading: false,
    pagination: {
      currentPage: 1,
      lastPage: 1
    },
    searchByClient: "",
    searchByName: ""
  };
}

const getters = {
  getData: state => state.data,
  getPagination: state => state.pagination,
  loading: state => state.loading
}

const actions = {
  fetchData({ commit, state }, params = {}) {
    commit("setLoading", true);
    commit("resetData");
     ApiService.get('api/v2',
     `job-templates?perPage=8&page=${state.pagination.currentPage}${state.searchByClient !== "" ? `&filter[company]=${state.searchByClient}` : ''}${state.searchByName !== "" ? `&filter[name]=${state.searchByName}` : ''}`)
               .then((response) => {
                   commit("setData", response.data.data);
                   commit("setPagination", response.data.meta)
               })
               .catch((error) => {
                   console.log(error);
               })
               .finally(() => {
                   commit("setLoading", false)
               })
  },
  resetState({ commit }) {
    commit("resetState");
  },
  searchByClient({ commit, dispatch }, ID) {
    commit("setClientID", ID);
    dispatch("fetchData");
  },
  searchByName({ commit, dispatch }, data) {
    commit("setSearchByName", data);
    dispatch("fetchData");
  },
  changeCurrentPage({ commit, dispatch }, page) {
    commit("setCurrentPage", page);
    dispatch("fetchData");
  },
  destroyData({commit, dispatch}, id) {
    commit("setLoading", true);
    ApiService
        .delete(
            "/api/v2/job-templates/" + id
        )
        .then(() => {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: `Record deleted`,
            showConfirmButton: false,
            timer: 1200
          })
        })
        .catch(error => {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: error,
            showConfirmButton: false,
            timer: 1200
          })
        })
        .finally(() => {
          dispatch("fetchData");
        });
  }
};

const mutations = {
    setData(state, payload) {
        for(let i = 0; i < payload.length; i++) {
            state.data.push({
                created_by_id: payload[i].created_by_id,
                id: payload[i].id,
                JobData: JSON.parse(payload[i].json_data),
                company_id: payload[i].company_id,
                created_by: payload[i].created_by,
                company_name: payload[i].company_name,
                name: payload[i].name
            })
        }
    },
  setLoading(state, loading) {
    state.loading = loading;
  },
  resetState(state) {
    state = Object.assign(state, initialState());
  },
  setPagination(state, value) {
      state.pagination.currentPage = value.current_page;
      state.pagination.lastPage = value.last_page;
  },
  setCurrentPage(state, value) {
      state.pagination.currentPage = value;
  },
  resetData(state) {
    state.data = [];
  },
  setClientID(state,value) {
    state.searchByClient = value;
  },
  setSearchByName(state, value) {
    state.searchByName = value;
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
};
