import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import AccessService from "@/core/services/AccessService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { connectToLiveChat, leaveLiveChat } from "@/pusher.js";
import RememberTokenService from "@/core/services/RememberTokenService";
import store from "@/store";

export interface UserRole {
  id: number;
  title: string;
}

export interface User {
  id: number;
  name: string;
  surname: string;
  email: string;
  password: string;
  token: Record<string, any>;
  rules: Array<string>;
  google2fa_secret: string;
  google2fa_enabled: boolean;
  mandatory_2fa: boolean;
  role: UserRole[];
}

export interface UserAuthInfo {
  errors: Array<string>;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = [];
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();
  isSubscribedToChat = false;
  fetchUser = false;

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Get  whether the current user is Admin
   * @returns boolean
   */
  get isAdmin(): boolean {
    if (Object.keys(this.currentUser).length > 0) {
      const roles = this.currentUser.role.map(i => i.title);
      return roles.includes("Administrator");
    }
    return false;
  }

  get getFetchUser(): boolean {
    return this.fetchUser;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors(): Array<string> {
    return this.errors;
  }

  get getIsSubscribedToChat(): boolean {
    return this.isSubscribedToChat;
  }


  @Mutation
  [Mutations.SET_IS_SUBSCRIBED](value) {
    this.isSubscribedToChat = value;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.user = user;
    this.errors = [];
    JwtService.saveToken(this.user.token.access_token);
    AccessService.saveRules(this.user.rules);
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.SET_FETCH_USER](fetchUser: boolean): void {
    this.fetchUser = fetchUser;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyToken();
    AccessService.destroyRules();
    store.dispatch("RulesModule/purgeRules");
    if (this.isSubscribedToChat) {
      leaveLiveChat();
      this.isSubscribedToChat = false;
    }
  }

  @Action({ rawError: true })
  [Actions.LOGIN](credentials) {
    return new Promise<void>((resolve, reject) => {
      const payload = credentials;
      if (RememberTokenService.getToken()) {
        payload["rememberToken"] = RememberTokenService.getToken();
      }
      ApiService.post("api/v2/auth/login", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          if (credentials.rememberDevice) {
            if (data.remember_token) {
              RememberTokenService.saveToken(data.remember_token);
            }
          }
          console.log('asdasdasdasdasdasdsad', this.isSubscribedToChat)
          if (data.id && !this.context.getters["isSubscribedToChat"]) {
            connectToLiveChat(data.id);
          }
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  }

  @Action
  [Actions.LOGOUT]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("api/v2/auth/logout", {})
        .then(({ data }) => {
          leaveLiveChat();
          if (typeof data.id !== "undefined") {
            this.context.commit(Mutations.SET_AUTH, data);
            location.reload();
            reject();
          } else {
            this.context.commit(Mutations.PURGE_AUTH);
            this.context.commit(Mutations.SET_IS_SUBSCRIBED, false);
            resolve();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH);
          reject();
        });
    });
  }

  @Action
  [Actions.REGISTER](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("registration", credentials)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.SET_WEBSOCKET_CONNECTED](value: boolean): void {
    this.context.commit(Mutations.SET_IS_SUBSCRIBED, value);
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("api/v2/auth/forgot_password", payload)
        .then(({ data }) => {
          resolve();
        })
        .catch(({ response }) => {
          reject();
        });
    });
  }

  @Action
  [Actions.FORGOT_PASSWORD_SAVE](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("api/v2/auth/forgot_password_save", payload)
        .then(({ data }) => {
          resolve();
        })
        .catch(({ response }) => {
          reject();
        });
    });
  }

  @Action
  [Actions.SET_FETCH_USER](value: boolean): void {
    this.context.commit(Mutations.SET_FETCH_USER, value);
  }

  @Action({ rawError: true })
  [Actions.VERIFY_AUTH]() {
    return new Promise<void>((resolve, reject) => {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        if (Object.keys(this.currentUser).length == 0) {
          this.context.commit(Mutations.SET_FETCH_USER, true);
        }
        ApiService.post("api/v2/auth/refresh", {
          data: { fetchUser: this.getFetchUser }
        })
          .then(({ data }) => {
            if (this.fetchUser) {
              this.context.commit(Mutations.SET_USER, data);
              if (data.id && !this.isSubscribedToChat) {
                connectToLiveChat(data.id);
              }
              this.context.commit(Mutations.SET_FETCH_USER, false);
            }
            resolve(data);
          })
          .catch(({ response }) => {
            this.context.commit(Mutations.SET_ERROR, response.data.errors);
            window.localStorage.removeItem("id_token");
            this.context.commit(Mutations.PURGE_AUTH);
            reject();
            window.location.reload();
          });
      } else {
        this.context.commit(Mutations.PURGE_AUTH);
        reject();
      }
    });
  }

  @Action
  [Actions.UPDATE_USER](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("update_user", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USER, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }
}
