import ApiService from "../../../core/services/ApiService";

/* eslint-disable */
function initialState() {
    return {
        data: [],
        loading: false,
        isAwaitingResponse: {
            ids: []
        },
        error: '',
        pagination: {
            currentPage: 1,
            lastPage: 1
        },
        exportsHistoryPagination: {
            currentPage : 1,
            lastPage : 1
        },
        searchParams: "",
        showTaxIdWorkers: false,
        date: null,
        userShifts: {},
        shiftsLoading: false,
        salaryReportsHistory: [],
        summary: null
    }
}

const getters = {
    getData: state => state.data,
    loading: state => state.loading,
    isAwaitingResponse: state => state.isAwaitingResponse,
    getSalaryReportsHistory: state => state.salaryReportsHistory,
    getExportHistoryPagination: state => state.exportsHistoryPagination,
    getPagination: state => state.pagination,
    getShiftsData: state => state.userShifts,
    getShiftsLoading: state => state.shiftsLoading,
    summary:          state => state.summary
}

const actions = {
    fetchData({commit, state}) {
        commit('setLoading', true);
        ApiService.get('api/v2' , 
        'salary?page=' + state.pagination.currentPage +
        "&perPage=10" +
        ((state.searchParams == "") ?  "" : "&filter[search]=" + state.searchParams) +
        (state.showTaxIdWorkers ?  "&filter[taxIdWorkers]=1" : "" ) +
        ((state.date) ? "&filter[from]=" + state.date[0] + "&filter[to]=" + state.date[1] : "")
        ).then(
            response => {
                commit('setData' , response.data.data)
                commit('setPagination', response.data)
            }).catch(error => {
                commit('setError', error)
            }).finally(() => {
                commit('setLoading' , false)
            });   
    },
    fetchSkillsForMissingRates({}, payload) {
        return new Promise((res, rej) => {
            ApiService.post("api/v2/skillsForMissingRates", payload)
                .then(response => {
                    res(response);
                })
                .catch(err => {
                    rej(err);
                })
        })
    },
    downloadPaySlip({commit} , query) {
        commit("setIsAwaitingResponse", { id: query.userId });
        return new Promise((res, rej) => {
            ApiService.get('api/v2', 'salaryReport', {
                params: query
            })
                .then(response => {
                    res(response);
                })
                .catch(error => {
                    rej(error.response);
                })
                .finally(() => {
                    commit("setIsAwaitingResponse", { id: query.userId });
                })
        })
    },
    fetchExportHistory({ commit, state }, query) {
        commit('clearSalaryReportsHistory');
        return new Promise((res, rej) => {
            ApiService.post('api/v2/salary/history', query).then(response => {
                commit('setSalaryReportsHistory', response.data.data)
                commit("setExportHistoryPages", response.data.meta);
                res(response);
            })
            .catch(error => {
                rej(error);
            });
        })
    },
    fetchSummary({ commit }, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post("api/v2/salary-reports-summary", { date: payload.date, taxId: payload.taxId, search: payload.search })
              .then((response) => {
                  commit("setSummary", response.data);
                  resolve();
              })
              .catch((err) => {
                  reject(err);
              })
        })
    },
    fetchShiftsByID({commit, state}, value) {
        commit("setShiftsLoading", true)
        ApiService.get('api/v2' , 
        'salary/slip?filter[userId]=' + value +
        ((state.date) ? "&filter[from]=" + state.date[0] + "&filter[to]=" + state.date[1] : "")
        ).then(
            response => {
                commit("setShifts", response.data)
            }).catch(error => {
                commit('setError', error)
            }).finally(() => {
                commit('setShiftsLoading' , false)
            });   
    },
    downloadAllReports({commit}, query) {
        return new Promise((resolve, reject) => {
            ApiService.post('api/v2/salary/all', query)
                .then(response => {
                    resolve(response.data.monitor_id);
                })
                .catch(err => {
                    reject(err);
                })
        })
    },
    getProgress({commit}, id) {
        return new Promise((resolve, reject) => {
            ApiService.get('api/v2', `job-progress/${id}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    },
    handlePayButton({ commit }, data) {
        return new Promise((resolve, reject) => {
            ApiService.post('api/v2/salary/pay', data)
              .then(res => {
                  resolve(res.data);
              })
              .catch(err => {
                  reject(err);
              })
        });
    },
    setCurrentPage({commit}, value) {
        commit("setCurrentPage", value)
    },
    setSearchParams({commit}, value) {
        commit("setSearchParams", value)
    },
    setTaxIdFilter({commit}, value) {
        commit("setTaxIdFilter", value)
    },
    setSearchByDate({commit} ,value) {
        commit("setSearchByDate", value);
    },
    clearSummary({ commit }) {
        commit("setSummary", null)
    },
    resetPageNumber({commit}) {
        commit("setCurrentPage", 1)
    }   
}
const mutations = {
    setError(state, value) {
        state.error = value
    },
    setSummary(state, payload) {
        state.summary = payload;
    },
    setData(state, value) {
        state.data = value
    },
    setLoading(state, value) {
        state.loading = value
    },
    setSalaryReportsHistory(state, value) {
        state.salaryReportsHistory = value;
    },
    clearSalaryReportsHistory(state) {
        state.salaryReportsHistory = [];
    },
    setExportHistoryPages(state, value) {
        state.exportsHistoryPagination.lastPage = value.last_page;
        state.exportsHistoryPagination.currentPage = value.current_page;
    },
    setIsAwaitingResponse(state, values) {
        const index = state.isAwaitingResponse.ids.indexOf(values.id);
        if (index > -1){
            state.isAwaitingResponse.ids.splice(index, 1);
        } else {
            state.isAwaitingResponse.ids.push(values.id);
        }
    },
    setPagination(state, value) {
        state.pagination.lastPage = value.last_page
    },
    setCurrentPage(state, value) {
        state.pagination.currentPage = value;
    },
    setSearchParams(state, value) {
        state.searchParams = value;
    },
    setTaxIdFilter(state, value) {
        state.showTaxIdWorkers = value;
    },
    setSearchByDate(state, value) {
        state.date = value;
    },
    setShifts(state, value) {
        state.userShifts = value;
    },
    setShiftsLoading(state, value) {
        state.shiftsLoading = value
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
