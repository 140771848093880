import { AbilityBuilder, Ability } from '@casl/ability';
import {useAbility} from "@casl/vue";

export default (store) => {
    store.subscribe((mutation) => {
        console.log(mutation);
        switch (mutation.type) {
            case 'setUser': {
                // const ability = useAbility();
                // const { can, rules } = new AbilityBuilder(Ability);
                // mutation.rules.forEach((rule) => {
                //     can(rule, 'all');
                // })
                //
                // ability.update(rules);
                break
            }

        }
    })
}