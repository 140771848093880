/* eslint-disable */
import ApiService from "../../../core/services/ApiService";

function initialState () {
    return {
        companies:             [],
        permissions:           [],
        companyCols:           [],
        countries:             [],
        countriesWithBranches: [],
        countriesWithVenues:   [],
        venues:                [],
        branches:              [],
        users:                 [],
        helpCategories:        [],
        pms:                   [],
        roles:                 [],
        activityTypes:         [],
        skills:                [],
        subSkills:             [],
        levels:                [],
        languages:             [],
        documentTypes:         [],
        dresscodes:            [],
        currencies:            [],
        preferredBranches:     [],
        preferredVenues:       [],
        companiesDropdown:     [],
        crewHandlers:          [],
        loading:               false,
        queryString:           '',
        isFetchingPMs:         false,
        isFetchingSkills:      false,
        isFetchingDresscodes:  false,
        isFetchingCH:          false
    }
}

const getters = {
    companies:             state => state.companies,
    companyCols:           state => state.companyCols,
    countries:             state => state.countries,
    venues:                state => state.venues,
    branches:              state => state.branches,
    users:                 state => state.users,
    pms:                   state => state.pms,
    roles:                 state => state.roles,
    loading:               state => state.loading,
    queryString:           state => state.queryString,
    countriesWithBranches: state => state.countriesWithBranches,
    countriesWithVenues  : state => state.countriesWithVenues,
    skills:                state => state.skills,
    activityTypes:         state => state.activityTypes,
    subSkills:             state => state.subSkills,
    levels:                state => state.levels,
    getCurrencies:         state => state.currencies,
    languages:             state => state.languages,
    getPermissions:        state => state.permissions,
    documentTypes:         state => state.documentTypes,
    dresscodes:            state => state.dresscodes,
    getHelpCategoriesList: state => state.helpCategories,
    preferredBranches:     state => state.preferredBranches,
    preferredVenues:       state => state.preferredVenues,
    companiesDropdown:     state => state.companiesDropdown,
    crewHandlers:          state => state.crewHandlers
}

const actions = {
    async fetchCompanies ({ commit, state, dispatch }, params = {} ) {
        // page, perPage = 10, filters = null
        if (!params.page) params.page = 1
        if (!params.perPage) params.perPage = 100
        if (!params.filters) params.filters = null
        params.dropdown = true

        commit('setQueryString', params.filters)
        const dropdownString = 'dropdown=' + params.dropdown + '&'
        return await ApiService.get(`/api/v2/companies?${state.queryString}${dropdownString}`).then(response => {
            commit('setCompanies', response.data)
            return response.data
        })
    },
    fetchDocumentTypes({commit, state}) {
        commit('setLoading', true);
    
        ApiService.get('api/v2',
            'document-types')
            .then(
            response => {
              commit('setDocumentTypes' , response.data.data)
            }).catch(error => {
          commit('setError', error)
          console.log(error)
        }).finally(() => {
          commit('setLoading' , false)
        });
      },

    fetchPreferredBranches({commit}, query) {
        return new Promise((resolve, reject) => {
           ApiService.get("api/v2", `preferred-branches${query ? `?company_id=${query}` : ''}`)
             .then((response) => {
                 commit("setPreferredBranches", response.data.data);
                 resolve();
             })
             .catch((err) => {
                 reject(err);
             })
        });
    },

    fetchPreferredVenues({ commit }, query) {
        return new Promise((resolve, reject) => {
            ApiService.get("api/v2", `preferred-venues${query ? `?company_id=${query}` : ''}`)
              .then((response) => {
                  commit("setPreferredVenues", response.data.data);
                  resolve();
              })
              .catch((err) => {
                  reject(err);
              })
        });
    },

    fetchDresscodes({ state, commit }) {
        if(!state.isFetchingDresscodes){
        commit('isFetchingDresscodes', true)
            ApiService.get('api/v2',
            'dresscodes?')
            .then(
            response => {
                commit('setDresscodesList' , response.data.data)
            }).catch(error => {
                commit('setError', error)
            }).finally(() => {
                commit('isFetchingDresscodes', false)
            });
        }
    },
    async fetchCompaniesAll ({ commit, state, dispatch }, params = {}) {
        if (!params.filters) params.filters = null;
        params.dropdown = true;
        commit('setQueryString', params.filters);
        const dropdownString = 'dropdown=' + params.dropdown + '&'
        await ApiService.get(`/api/v2/companies?${state.queryString}${dropdownString}`).then(response => {
            commit('setCompanies', response.data.data)
            return response.data.data
        })
    },

    async fetchCompaniesDropdown ({ commit, state, dispatch }, params = {}) {
        const dropdownString = 'dropdown=true';
        await ApiService.get(`/api/v2/companies` ,`?${dropdownString}`).then(response => {
            commit('setCompaniesDropdown', response.data.data)
            return response.data.data
        })
    },

    clearCols({ commit }) {
        commit("setCompanyCols", []);
    },

    async fetchCompanyCols ({ commit }, id) {
        commit("setCompanyCols", []);
        await ApiService.get(`/api/v2/companies/${id}/users`)
            .then(response => {
                commit('setCompanyCols', response.data.data)
                return response.data.data
            })
    },

    async fetchSkills ({ commit, state, dispatch }, params = {}) {
        if (!params.filters) params.filters = null;
        commit('setQueryString', params.filters);
        if(!state.isFetchingSkills){
            commit("isFetchingSkills", true)
            return  ApiService.get(`/api/v2`,
            `skills?sort=order&order=asc`+
            `&filter[parent_id]=null`
            ).then(response => {
                commit('setSkills', response.data.data)
                commit("isFetchingSkills", false)
                return response.data.data
            })
        }
    },

    async fetchSubSkills ({ commit, state, dispatch }, params = {}) {
        if (!params.filters) params.filters = null;
        commit('setQueryString', params.filters);
        await ApiService.get(`/api/v2/skills?${state.queryString}`).then(response => {
            commit('setSubSkills', response.data.data)
            return response.data.data
        })
    },

    fetchUserById({ commit }, id) {
        return new Promise((resolve, reject) => {
           ApiService.get("api/v2", "users/" + id)
             .then((response) => {
                 resolve(response.data.data);
             })
             .catch((err) => {
                 reject(err);
             })
        });
    },

    fetchActivityTypes({commit}) {
        ApiService.get('api/v2', 'activity-log?types=true')
            .then(response => {
                commit('setActivityTypes', response.data)
            })

    },

    fetchSingleVenue({commit}, venueId) {
      return new Promise((resolve, reject) => {
          if("number" !== typeof venueId) {
              reject("A valid branch id is required");
          }
          ApiService.get("api/v2", `venues/${venueId}`)
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((err) => {
                reject(err);
            })
      });
    },

    async fetchLevels ({ commit, state, dispatch }, params = {}) {
        if (!params.filters) params.filters = null;
        commit('setQueryString', params.filters);
        await ApiService.get(`/api/v2/levels?${state.queryString}`).then(response => {
            commit('setLevels', response.data.data)
            return response.data.data
        })
    },

    async fetchCountries ({ commit }, filters = null) {
        await ApiService.get('/api/v2/countries').then(response => {
            commit('setCountries', response.data.data)
            return response.data.data
        })
    },

    async fetchCountriesWithBranches ({ commit, state, dispatch  }, params = {}) {
        if (!params.filters) params.filters = null;
        params.dropdown = true;
        commit('setQueryString', params.filters);
        const dropdownString = 'dropdown=' + params.dropdown + '&';
        await ApiService.get(`/api/v2/countries?withBranches=true&${state.queryString}${dropdownString}`).then(response => {
            commit('countriesWithBranches', response.data.data)
            return response.data.data
        })
    },

    fetchHelpCategories({commit}) {
        ApiService.get('api/v2', 'help-categories')
            .then(response => {
                commit('setHelpCategoriesList' , response.data.data);
            })
    },

    fetchVenues ({ commit }, countryId = null) {
        return new Promise((resolve, reject) => {
            const queryString = countryId ? `filter[country_id]=${countryId}` : ''
            ApiService.get('/api/v2', 'venues?' + `${queryString}`)
                .then(response => {
                    commit('setVenues', response.data.data)
                    resolve(response.data.data);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    },
    fetchAllPermissions({ commit }) {
        ApiService
        .get(
            "/api/v2",
            "permissions")
            .then(response => {
                commit("setPermissions", response.data.data);
            });
    },

    fetchCrewHandlers ({ state, commit }) {
        if(!state.isFetchingPMs){
            commit("isFetchingCH", true);
            ApiService.get('/api/v2', 'users?filter[roles]=Crew Handler').then(response => {
                commit('setCrewHandlers', response.data.data);
                commit("isFetchingCH", false);
                return response.data.data;
            })
        }
    },

    fetchBranches ({ commit, state, dispatch }, params = {}) {
        if (!params.filters) params.filters = null;
        commit('setQueryString', params.filters);
        ApiService.get(`/api/v2/branches?${state.queryString}`).then(response => {
            commit('setBranches', response.data.data)
            return response.data.data
        })
    },

    fetchUsers ({ commit }) {
        ApiService.get('/api/v2/users').then(response => {
            commit('setUsers', response.data.data)
            return response.data.data
        })
    },

    fetchPms ({ state, commit }) {
        if(!state.isFetchingPMs){
        commit("isFetchingPMs", true)
        ApiService.get('/api/v2', 'users?filter[roles]=Project Manager').then(response => {
            commit('setPms', response.data.data)
            commit("isFetchingPMs", false)
            return response.data.data
        })
        }
    },

    fetchRoles ({ commit }) {
        ApiService.get('/api/v2/roles').then(response => {
            commit('setRoles', response.data.data)
            return response.data.data
        })
    },

    fetchLanguages ({ commit }) {
        ApiService.get('/api/v2/languages')
            .then(response => {
                commit('setLanguages', response.data.data);
            });
    },

    fetchCurrencies ({ commit }) {
        ApiService.get('/api/v2/currencies')
            .then(response => {
                commit('setCurrencies', response.data.data);
            });
    },

    resetState ({ commit }) {
        commit('resetState')
    },
}

const mutations = {
    setCompanies (state, value) {
        state.companies = value
    },
    setCompaniesDropdown(state, value) {
        state.companiesDropdown = value
    },
    setCrewHandlers(state, payload) {
        state.crewHandlers = payload;
    },
    setDresscodesList(state, value) {
        state.dresscodes = value;
    },
    setPreferredBranches(state, payload) {
      state.preferredBranches = payload;
    },
    setPreferredVenues(state, payload) {
        state.preferredVenues = payload;
    },
    setCompanyCols (state, value) {
        state.companyCols = value
    },
    setDocumentTypes(state, value) {
        state.documentTypes = value;
    },
    setCountries (state, value) {
        state.countries = value
    },
    countriesWithBranches (state, value) {
        state.countriesWithBranches = value
    },
    countriesWithVenues (state, value) {
        state.countriesWithVenues = value
    },
    setVenues (state, value) {
        state.venues = value;
    },
    setBranches (state, value) {
        state.branches = value
    },
    setUsers (state, value) {
        state.users = value
    },
    setPms (state, value) {
        state.pms = value
    },
    setRoles (state, value) {
        state.roles = value
    },
    setSkills (state, value) {
        state.skills = value
    },
    setSubSkills (state, value) {
        state.subSkills = value
    },
    setLevels (state, value) {
        state.levels = value
    },
    setLanguages (state, value) {
        state.languages = value
    },
    setActivityTypes(state, value) {
      state.activityTypes = value;
    },
    setHelpCategoriesList(state, payload) {
        state.helpCategories = payload;
    },
    setPermissions(state, value) {
        state.permissions = value;
    },
    setCurrencies(state, data) {
        state.currencies = data;
    },
    setLoading (state, loading) {
        state.loading = loading
    },
    isFetchingPMs(state, value) {
        state.isFetchingPMs = value;
    },
    isFetchingSkills(state, value) {
        state.isFetchingSkills = value;
    },
    isFetchingCH(state, loading) {
        state.isFetchingCH = loading;
    },
    isFetchingDresscodes(state, value) {
        state.isFetchingDresscodes = value;
    },
    setQueryString(state, filters) {
        let queryString = '';
        if (filters) {
            for (const field in filters) {
                if (filters[field]) {
                    if (Array.isArray(filters[field]) && !filters[field].length) continue;
                    queryString += 'filter[' + field + ']=' + filters[field] + '&'
                }
            }
        }
        state.queryString = queryString;
    },
    resetState (state) {
        state = Object.assign(state, initialState())
    },
}

export default {
    namespaced: true,
    state:      initialState,
    getters,
    actions,
    mutations,
}
