import ApiService from "../../../core/services/ApiService";
import Swal from "sweetalert2";

/* eslint-disable */
function initialState() {
    return {
        data: [],
        recentlyPromoted: [],
        loading: false,
        query: {
            pagination: {
                currentPage: 1,
                totalPages: 1
            },
            searchString: ""
        },
        isAwaitingApproval: [],
        count: 0
    };
};

const getters = {
    applicants: state => state.data,
    recentlyPromoted: state => state.recentlyPromoted,
    loading: state => state.loading,
    pagination: state => state.query.pagination,
    awaitingApproval: state => state.isAwaitingApproval,
    count: state => state.count
}

const actions = {
    fetchData({ state, commit }) {
        commit("setLoading", true)
        ApiService.get("api/v2", `applicants?page=${state.query.pagination.currentPage}&perPage=10${state.query.searchString != "" ? `&filter[search]=${state.query.searchString}` : ''}`)
        .then(response => {
            commit("setApplicants", response.data.data);
            commit("setPagination", response.data.meta);
        }).catch(error => {
            console.log(error);
            commit("setLoading", false)
        }).finally(() => {
            commit("setLoading", false)
        })
    },
    approve({ commit, dispatch }, applicantID) {
        commit("isAwaitingApproval", applicantID);
        ApiService.post(`/api/v2/applicants/approve`, { applicant_id : applicantID})
            .then(() => { 
                commit("wasApproved", applicantID);
                Swal.fire({
                    icon: 'success',
                    toast: true,
                    position: "top-end",
                    heightAuto: false,
                    title: "User approved succesfully.",
                    showConfirmButton: false,
                    timer: 1500,
                })
                dispatch("getCount");
            })
            .catch(() => {
                commit("wasNotApproved", applicantID);
                Swal.fire({
                    icon: 'error',
                    toast: true,
                    position: "top-end",
                    heightAuto: false,
                    title: "There was an error.",
                    showConfirmButton: false,
                    timer: 1500,
                  })
            })
    },
    fetchRecentlyPromoted({ commit }) {
        ApiService.get("api/v2", "applicants/recently-promoted").then(response => {
            commit("setRecentlyPromotedApplicants", response.data.data);
        }).catch(error => {
            console.log(error);
        })
    },
    getCount({commit}) {
        ApiService.get("api/v2", "applicants/count").then(response => {
            commit("setCount", response.data.count);
        }).catch(error => {
            console.log(error);
        })
    },
    changeCurrentPage({ commit }, page) {
        commit("changePage", page);
    },
    changeSearch({ commit }, search) {
        commit("changePage", 1);
        commit("changeSearchString", search);
    },
    resetState({ commit }) {
        commit("resetState");
    }
}

const mutations = {
    setApplicants(state, value) {
        state.data = value;
    },
    setRecentlyPromotedApplicants(state, value) {
        state.recentlyPromoted = value;
    },
    setCount(state, value) {
        state.count = value;
    },
    isAwaitingApproval(state, value) {
        state.isAwaitingApproval.push(value);
    },
    wasApproved(state, value) {
        state.data = state.data.filter(item => item.id != value);
        state.isAwaitingApproval = state.isAwaitingApproval.filter(a => a != value);
    },
    wasNotApproved(state, value) {
        state.isAwaitingApproval = state.isAwaitingApproval.filter(a => a != value);
    },
    setLoading(state, value) {
        state.loading = value;
    },
    setPagination(state, data) {
        state.query.pagination.currentPage = data.current_page;
        state.query.pagination.totalPages = data.last_page;
    },
    changePage(state, page) {
        state.query.pagination.currentPage = page;
    },
    changeSearchString(state, search) {
        state.query.searchString = search;
    },
    resetState(state) {
        let reset = initialState();
        reset['count'] = state.count;
        state = Object.assign(state, reset)
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
