import ApiService from "../../../core/services/ApiService";

/* eslint-disable */
function initialState() {
    return {
        data: [],
        loading: false,
        error: '',
        isAwaitingResponse: false
    }
}

const getters = {
    getData: state => state.data,
    getError: state => state.error,
    loading: state => state.loading,
    isAwaitingResponse: state => state.isAwaitingResponse
}

const actions = {
    fetchData({commit}, userId) {
        commit('setLoading', true);

        ApiService.get('api/v2' , `notes?filter[noteable_id]=${userId}`).then(
            response => {
                commit('setData' , response.data.data)
            }).catch(error => {
                commit('setError', error)
            }).finally(() => {
                commit('setLoading' , false)
            });   
    },

    createNew({commit, dispatch, state}, payload) {
        commit("setAwaitResponse", true)
        const data = {
            ...payload,
            noteable_type: "users"
        };
        ApiService
            .post(
                "/api/v2/notes" , data
            )
            .then(() => {
                dispatch("fetchData", payload.noteable_id);
            })
            .catch(error => {
                commit("setError", error.response.data.message);
            })
            .finally(() => {
                commit("setAwaitResponse", false)
            });
    },

    update({ commit , dispatch}, payload) {
        return new Promise((resolve, reject) => {
            commit("setAwaitResponse", true);
            ApiService
              .update(
                "/api/v2/notes", payload.id, payload
              )
              .then(() => {
                  if(payload.userID) {
                      dispatch("fetchData", payload.userID);
                  }
              })
              .catch(error => {
                  commit("setError", error.response.data.message);
                  commit("setAwaitResponse", false)
                  reject(error);
              })
              .finally(() => {
                  commit("setAwaitResponse", false)
                  resolve();
              });
        })
    },

    destroyData({commit, dispatch}, payload) {
        return new Promise((resolve, reject) => {
        ApiService
            .delete(
                "/api/v2/notes/" + payload.noteID
            )
            .then(() => {
                if(payload.userID) {
                    dispatch("fetchData", payload.userID);
                }
                resolve();
            })
            .catch(error => {
                commit("setError", error);
                reject(error);
            })
        })
    },
    clearErrors({ commit }) {
        commit("clearError");
    },
    resetState({ commit }) {
        commit("resetState")
    }
}
const mutations = {
    setError(state, value) {
        state.error = value
    },
    setAwaitResponse(state, value) {
        state.isAwaitingResponse = value;
    },
    setData(state, value) {
        state.data = value
    },
    setLoading(state, value) {
        state.loading = value
    },
    clearError(state) {
        state.error = ''
    },
    resetState(state) {
        state = Object.assign(state, initialState());
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
