import moment from "moment";
export const DateTimeFormat = "DD/MM/YYYY HH:mm";
export type timeSelectorStartEnd = {
   start: string;
   end: string;
}
export const DefaultTimeSelectorStartEnd = {
   start : "00:00",
   end: "23:30"
}
export const getHoursFromString = (string, format): string => {
   return moment(string, format).format("HH:mm");
}
export const getDateFromString = (string, format): string => {
   return moment(string, format).format("DD/MM/YYYY");
}
export const buildDateTimeString = (date, hours) => {
   return getDateFromString(date, DateTimeFormat) + " " + hours
}