/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable prettier/prettier */
import ApiService from "../../../core/services/ApiService";
import moment from "moment";
import router from '@/router/index.ts'
import Swal from "sweetalert2"
import {DateTimeFormat} from "@/components/jobs/ReportHours/helpers";
import ability from "@/config/ability";

function initialState() {
    return {
        existingShifts: [],
        item: {},
        shifts: [],
        details: [],
        loading: false,
        updating: false
    };
}

const getters = {
    job: (state) => state.item,
    existingShifts: (state) => state.existingShifts,
    loading: (state) => state.loading,
    updating: (state) => state.updating
};

const actions = {
    fetchJob({ commit, dispatch }, id) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            ApiService.get(`/api/v2/jobs/edit`, id).then((response) => {
                commit('setItem', response.data.data)
                commit('setLoading', false);
                resolve()
            }).catch((error) => {
                reject(error)
            });
        })
    },
    deleteExistingSkill({ commit }, data) {
        commit("removeExistingSlot", data);
    },
    generateExistingShifts({commit, state}) {
        for(let i = 0; i < state.item.shifts.length ; i++)  {
            const shiftSlots = {};
            for(let x = 0; x < state.item.shifts[i].shift_slots.length; x++) {
                shiftSlots[state.item.shifts[i].shift_slots[x].skill.id] = { ...state.item.shifts[i].shift_slots[x].skill, qty: state.item.shifts[i].shift_slots[x].positions, shiftSlotID: state.item.shifts[i].shift_slots[x].id, isDeleted: false}
            }
            commit('generateExistingShifts',{
               id: state.item.shifts[i].id,
               startHour: state.item.shifts[i].start.substring(11),
               endHour: state.item.shifts[i].end.substring(11),
               startLabel: state.item.shifts[i].label.substring(16),
               startDate: state.item.shifts[i].label.substring(0,14),
               duration: state.item.shifts[i].duration,
               status: true,
               shiftSlots: shiftSlots,
               isDeleted: false,
               isEdited: false,
               details: {
                project_manager_id: state.item.shifts[i].project_manager_id ? state.item.shifts[i].project_manager_id : '',
                contact_on_location_id: state.item.shifts[i].contact_on_location_id ? state.item.shifts[i].contact_on_location_id : '' , 
                dresscode_id:state.item.shifts[i].dresscode_id ? state.item.shifts[i].dresscode_id : '',
                dresscode_notes: state.item.shifts[i].dresscode_notes ? state.item.shifts[i].dresscode_notes : '',
                notes_for_workers: state.item.shifts[i].notes_for_workers ? state.item.shifts[i].notes_for_workers : '',
                description: state.item.shifts[i].description ? state.item.shifts[i].description : '',
              },
              shiftTimes: {
                start: state.item.shifts[i].start,
                end: state.item.shifts[i].end
              }
             });
        }
        state.existingShifts.sort((a, b) => {
            return moment(a.startDate, "qqq DD/MM/YYYY").unix - moment(b.startDate, "qqq DD/MM/YYYY").unix
        })
    },
    changeDate({ commit }, data) {
        commit("changeShiftDate", data);
    },
    addNewShift({commit}, data) {
        commit("addShiftToExisting", data)
    },
    flagForDelete({ commit }, shiftID) {
        commit("flagForDelete", shiftID);
    },
    removeDeletionFlag({ commit }, shiftID) {
        commit("removeDeleteFlag", shiftID);
    },
    update({ commit, state}, data) {
        const payload = {
            name: data.name,
            city: data.city,
            address: data.address,
            project_manager_id: data.projectManager ? data.projectManager : "",
            province: data.area,
            book_from_branches: data.branches,
            company_id: data.company,
            country_id: data.country,
            details: data.details,
            dresscode_id: data.dresscode ? data.dresscode : null,
            entrance: data.entrance,
            contact_on_location_id: data.colID,
            invoice_reference: data.invoice,
            zip: data.postal,
            venue_id: data.venueId,
            shifts: state.existingShifts
        }
        if(ability.can('can_select_job_booker', 'all')) {
            payload['booker_id'] = data.bookerId ? data.bookerId : null;
        }
        commit("setUpdating", true)
        ApiService.put(`api/v2/jobs/${state.item.id}`, payload)
            .then().catch(() => {
                //handle error
            }).finally(() => {
                commit("setUpdating", false)
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: `Job successfully updated!`,
                    showConfirmButton: false,
                    timer: 700
                }).then(() => {
                    router.push(`/jobs/${state.item.id}/shifts`);
                });
            })
    },
    changeDetails({ commit }, payload) {
        commit("changeDetails", payload);
    },
    setShiftSkills({commit}, payload) {
        commit("changeShiftSkills", payload)
    },
    flagForEdited({ commit }, payload) {
        commit("flagForEdit", payload);
    },
    changeStart({ commit }, id) {
        commit("changeStart", id);
    },
    changeEnd({ commit }, id) {
        commit("changeEnd", id);
    },
    addNewShiftSlot({ commit }, payload) {
        commit("addNewShiftSlot", payload);
    },
    changeShiftSlotPositions({ commit }, payload) {
        commit("changeExistingShiftSlotQty", payload);
    },
    resetState({ commit }) {
        commit("resetState");
    },
    changeDuration({commit}, data) {
        commit("changeDuration", data);
    }
};

const mutations = {
    setItem(state, value) {
        state.item = value;
    },
    removeExistingSlot(state, payload) {
        state.existingShifts.find(shift => shift.id == payload.shiftID).shiftSlots[payload.skill].isDeleted = true;
        state.existingShifts.find(shift => shift.id == payload.shiftID).isEdited = true;
    },
    changeDuration(state, data) {
        const startHour = state.existingShifts.find(shift => shift.id == data.shiftID).startHour;
        console.log(state.existingShifts.find(shift => shift.id == data.shiftID))
        const minutes = moment.duration(data.duration, 'kk:mm').asMinutes()
        const shift = state.existingShifts.find(shift => shift.id == data.shiftID);
        shift.endHour = moment(startHour, "kk:mm").add(minutes, 'minutes').format("kk:mm");
        shift.startLabel = `${shift.startHour} - ${shift.endHour}`;
        shift.duration = minutes/60;
        shift.shiftTimes.end = `${moment(shift.startDate, "qqq DD/MM/YYYY").format("DD/MM/YYYY")} ${shift.endHour}`
        if(moment(shift.shiftTimes.end , "DD/MM/YYYY kk:mm").valueOf() < moment(shift.shiftTimes.start , "DD/MM/YYYY kk:mm").valueOf()) {
            shift.shiftTimes.end = moment(shift.shiftTimes.end, "DD/MM/YYYY kk:mm").add(1, "days").format("DD/MM/YYYY kk:mm");
        }
        shift.isEdited = true;
    },
    changeShiftDate(state, data) {
        const shift = state.existingShifts.find(shift => shift.id == data.id);
        const temp = {
            start : `${data.data} ${shift.startHour}`,
            end: `${data.data} ${shift.endHour}`
        }
        shift.shiftTimes = temp;
    },
    setUpdating(state, value) {
        state.updating = value;
    },
    setLoading(state, payload) {
        state.loading = payload;
    },
    generateExistingShifts(state, value) {
        state.existingShifts.push(value);
    },
    addShiftToExisting(state, value) {
        const shift = {
            id: `new_${state.existingShifts.length}`,
            startHour: value.startHour,
            endHour: value.endHour,
            shiftSlots: value.skills,
            startLabel: `${value.startHour} - ${value.endHour}`,
            startDate: moment(value.dateStart, "DD/MM/YYYY").format("ddd DD/MM/YYYY"),
            dateStart: value.dateStart,
            status: true,
            details: {}
        }
        state.existingShifts.push(shift)
        state.existingShifts.sort((a, b) => {
            return moment(a.startDate, "qqq DD/MM/YYYY").valueOf() - moment(b.startDate, "qqq DD/MM/YYYY").valueOf()
        })
    },
    addNewShiftSlot(state, payload) {
        const newSlots = Object.keys(payload.skill);
        const shiftSlotsKeys = Object.keys(state.existingShifts.find(shift => shift.id == payload.id).shiftSlots);
        for(let i = 0; i < newSlots.length; i++) {
            if(shiftSlotsKeys.includes(newSlots[i])) {
                state.existingShifts.find(shift => shift.id == payload.id).shiftSlots[newSlots[i]].qty = parseInt(payload.skill[newSlots[i]].qty)
            } else {
                state.existingShifts.find(shift => shift.id == payload.id).shiftSlots[payload.skill[newSlots[i]].id] = payload.skill[newSlots[i]]
            }
        }
        state.existingShifts.find(shift => shift.id == payload.id).isEdited = true;
    },
    changeExistingShiftSlotQty(state, payload) {
        state.existingShifts.find(shift => shift.id == payload.shiftID).shiftSlots[payload.skillID].qty = payload.qty;
        state.existingShifts.find(shift => shift.id == payload.shiftID).isEdited = true;
    },
    flagForDelete(state, id) {
        state.existingShifts.find(shift => shift.id == id).isDeleted = true;
    },
    flagForEdit(state, id) {
        state.existingShifts.find(shift => shift.id == id).isEdited = true;
    },
    removeDeleteFlag(state, id) {
        state.existingShifts.find(shift => shift.id == id).isDeleted = false;
    },
    changeDetails(state, payload) {
        state.existingShifts.find(shift => shift.id == payload.shiftID).details = payload.details;
        state.existingShifts.find(shift => shift.id == payload.shiftID).isEdited = true;
    },
    changeStart(state, id) {
        state.existingShifts.find(shift => shift.id == id).shiftTimes.start = 
        `${state.existingShifts.find(shift => shift.id == id).shiftTimes.start.substring(0, 10)} ${state.existingShifts.find(shift => shift.id == id).startHour}`

        state.existingShifts.find(shift => shift.id == id).isEdited = true;
    },
    changeEnd(state, id) {
        state.existingShifts.find(shift => shift.id == id).shiftTimes.end = 
        `${state.existingShifts.find(shift => shift.id == id).shiftTimes.start.substring(0, 10)} ${state.existingShifts.find(shift => shift.id == id).endHour}`
        const shiftTimes = state.existingShifts.find(shift => shift.id == id).shiftTimes
        if(moment(shiftTimes.end, DateTimeFormat).valueOf() < moment(shiftTimes.start, DateTimeFormat).valueOf()) {
            const endDate = moment(state.existingShifts.find(shift => shift.id == id).shiftTimes.end, DateTimeFormat).add(1, 'days').format(DateTimeFormat);
            state.existingShifts.find(shift => shift.id == id).shiftTimes.end = endDate;
        } else {
            state.existingShifts.find(shift => shift.id == id).shiftTimes.end = 
            `${state.item.shifts.find(shift => shift.id == id).start.substring(0, 10)} ${state.existingShifts.find(shift => shift.id == id).endHour}`;
        }
        state.existingShifts.find(shift => shift.id == id).isEdited = true;
    },
    resetState(state) {
        state = Object.assign(state, initialState());
    }
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
