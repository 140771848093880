import ApiService from "../../../core/services/ApiService";
import Swal from "sweetalert2";

/* eslint-disable */
function initialState() {
    return {
        data: [],
        loading: false,
        isSavingWeights: false,
        error: ''
    }
}

const getters = {
    getData: state => state.data,
    loading: state => state.loading,
    isSavingWeights: state => state.isSavingWeights
}

const actions = {
    fetchData({commit}, hideLoading) {
        if(!hideLoading) commit('setLoading', true);

        ApiService.get('api/v2' , 'levels?').then(
            response => {
                commit('setData' , response.data.data)
            }).catch(error => {
                commit('setError', error)
            }).finally(() => {
                commit('setLoading' , false)
            });   
    },
    saveWeights({ dispatch, commit }, data) {
        commit("setIsSavingWeights", true);
        return new Promise((resolve, reject) => {
            ApiService.post('api/v2/levels/saveWeights', data).then(response => {
                dispatch('fetchData', true);
                commit("setIsSavingWeights", false);
                resolve(response.data);
            }).catch(err => {
                dispatch('fetchData', true);
                commit("setIsSavingWeights", false);
                reject(err.response.data.message);
            })
        })  
    }, 
    createNew({commit, dispatch }, payload) {
        ApiService
            .post(
                "/api/v2/levels" , payload
            )
            .then(() => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: `Level created successfully`,
                    showConfirmButton: false,
                    timer: 2000
                })
            })
            .catch(error => {
                commit("setError", error);
            })
            .finally(() => {
                dispatch("fetchData");
            });
    },

    update({ commit , dispatch}, payload) {
        commit("setLoading", true);
        ApiService
            .update(
                "/api/v2/levels", payload.id, payload.values
            )
            .then(() => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: `Level edited successfully`,
                    showConfirmButton: false,
                    timer: 2000
                })
            })
            .catch(error => {
                commit("setError", error);
            })
            .finally(() => {
                dispatch("fetchData");
                commit("setLoading", false)
            });
    },

    destroyData({commit, dispatch}, id) {
        ApiService
            .delete(
                "/api/v2/levels/" + id
            )
            .then(() => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: `Level deleted successfully`,
                    showConfirmButton: false,
                    timer: 2000
                })
            })
            .catch(error => {
                commit("setError", error);
            })
            .finally(() => {
                dispatch("fetchData");
            });
    },
}
const mutations = {
    setError(state, value) {
        state.error = value
    },
    setData(state, value) {
        state.data = value
    },
    setLoading(state, value) {
        state.loading = value
    },
    setIsSavingWeights(state, value) {
        state.isSavingWeights = value;
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
