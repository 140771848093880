import ApiService from "../../../core/services/ApiService";

/* eslint-disable */
function initialState() {
    return {
        query: {
            company_id: null,
            branch_id: null
        },
        clientCurrency: "",
        estimate: {}
    }
}

const getters = {
    query: state => state.query,
    data: state => state.estimate.data,
    total: state => state.estimate.total,
    clientCurrency: state => state.clientCurrency
}

const actions = {
    fetch({ commit }, query) {
        ApiService.post("api/v2/rates/client/estimate-costs", query).then(response => {
            commit("setEstimates", response.data);
        })
    },
    fetchClientCurrency({ commit }, query) {
        ApiService.post("api/v2/rates/client/get-currency", ...query).then(response => {
            commit("setClientCurrency", response.data);
        });
    },
    setClientID({ commit }, payload) {
        commit("setCompany", payload);
    },
    clearEstimates({ commit }, ) {
        commit("clearEstimates");
    },
    setBranchesID({ commit }, payload) {
        commit("setBranches", payload);
    },
}
const mutations = {
    setEstimates(state, data) {
        state.estimate = data;
    },
    setClientCurrency(state, data) {
        state.clientCurrency = data;
    },
    setBranches(state, data) {
        if(data.length > 0) {
            state.query.branch_id = data[0];
        }else {
            state.query.branch_id = null;
        }
    },
    clearEstimates(state) {
        state.estimate = [];
    },
    setCompany(state, data) {
        state.query.company_id = data;
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
