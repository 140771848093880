import ApiService from "../../../core/services/ApiService";

/* eslint-disable */
function initialState() {
    return {
        data: [],
        loading: false,
        singleArticle: null,
        error: ''
    }
}

const getters = {
    getArticles: state => state.data,
    getSingleArticle: state => state.singleArticle,
    loading: state => state.loading
}

const actions = {
    fetchArticles({commit}, roleId) {
        commit('setLoading', true);

        ApiService.get('api/v2/help?' + ((roleId) ? 'roleId=' + roleId : '')).then(
            response => {
                commit('setData' , response.data.data)
            }).catch(error => {
                commit('setError', error)
            }).finally(() => {
                commit('setLoading' , false)
            });   
    },
    fetchSingleArticle({commit}, articleId) {
        return new Promise((res, rej) => {
            ApiService.get("api/v2", `help/articles/${articleId}`)
                .then(response => {
                    commit("setSingleArticle", response.data.data);
                })
                .catch(err => {
                    rej(err);
                })
                .finally(() => {
                    res();
                })
        })
    },
    saveArticle({commit}, article) {
        return new Promise((res, rej) => {
            ApiService.update("/api/v2/help/articles" , article.id, article)
                .then(() => {
                    res();
                })
                .catch(err => {
                    rej(err)
                })
        })
    },
    createArticle({commit}, article) {
        return new Promise((res, rej) => {
            ApiService.post("/api/v2/help/articles" , article)
                .then(() => {
                    res();
                })
                .catch(err => {
                    rej(err)
                })
        })
    },
    destroyArticle({commit}, articleId) {
        return new Promise((res, rej) => {
            ApiService.delete("/api/v2/help/articles/" + articleId)
                .then(response => {
                    res(response)
                })
                .catch((error) => {
                    rej(error);
                })
        })
    },
    clearEditArticleState({commit}) {
        commit("setSingleArticle", null);
    },
    resetState({ commit }) {
        commit("resetState");
    }
}
const mutations = {
    setError(state, value) {
        state.error = value
    },
    setSingleArticle(state, payload) {
        state.singleArticle = payload;
    },
    setData(state, value) {
        state.data = value
    },
    setLoading(state, value) {
        state.loading = value
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
