/* eslint-disable */
import Swal from "sweetalert2";
import ApiService from "../../core/services/ApiService";

function initialState() {
    return {
        shifts: [],
        loading: false
    };
}

const getters = {
    shifts: state => state.shifts,
    loading: state => state.loading
};

const actions = {
    fetchShifts({ commit , state}, parameters) {
        commit("setLoading", true);
        ApiService
            .get(
                `/api/v2/calendar`, 'shifts')
            .then(response => {
                console.log(response)
                commit("setShifts", response.data.data);
            })
            .catch(error => {
                commit("setError", error);
            }).finally(() => {
                commit("setLoading", false);
            });
    },
    setShifts({ commit, dispatch }, value) {
        commit("setShifts", value);
    },
};

const mutations = {
    setShifts(state, items) {
        state.shifts = items;
    },
    setLoading(state, value) {
        state.loading = value
    }
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
};
