/* eslint-disable */
import ApiService from "../../../core/services/ApiService";
import Swal from "sweetalert2";

function initialState() {
  return {
    helpCategoriesList: [],
    loading: false,
    error: "",
    query: {
      roleId: ""
    },
    pagination: {
      current_page: 1,
      last_page: 1
    }
  };
}

const getters = {
  helpCategoriesList: state => state.helpCategoriesList,
  getPaginationData: state => state.pagination,
  loading: state => state.loading
}

const actions = {
  fetchData({ commit, state }, params = {}) {
    commit("setLoading", true);

    ApiService
      .get(
        "/api/v2",
        'help-categories?page='+
          state.pagination.current_page +
          "&perPage=10" +
        (state.query.roleId ? '&roleId=' + state.query.roleId : ""))
        .then(
            response => {
              commit('setHelpCategoriesList' , response.data.data)
              commit("setPagination", response.data)
            }).catch(error => {
      commit('setError', error)
      console.log(error)
    }).finally(() => {
      commit('setLoading' , false)
    });
  },

  createNew({commit, dispatch }, payload) {
    ApiService
        .post(
            "/api/v2/help-categories" , payload
        )
        .then(() => {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: `Help category created successfully`,
            showConfirmButton: false,
            timer: 2000
          })
        })
        .catch(error => {
          commit("setError", error);
        })
        .finally(() => {
          dispatch("fetchData");
        });
  },

  update({ commit , dispatch}, value) {
    commit("setLoading", true);
    const payload = {
      name : value.payload.name,
      description: value.payload.description,
      target_roles: value.payload.target_roles
    };
    ApiService
        .update(
            "/api/v2/help-categories" , value.id, payload
        )
        .then(() => {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: `Help category edited successfully`,
            showConfirmButton: false,
            timer: 2000
          })
        })
        .catch(error => {
          commit("setError", error);
        })
        .finally(() => {
          dispatch("fetchData");
          commit("setLoading", false)
        });
  },

  destroyData({commit, dispatch}, id) {
    ApiService
        .delete(
            "/api/v2/help-categories/" + id
        )
        .then(() => {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: `Help category deleted successfully`,
            showConfirmButton: false,
            timer: 2000
          })
        })
        .catch(error => {
          commit("setError", error);
        })
        .finally(() => {
          dispatch("fetchData");
        });
  },

  saveWeights({ commit, dispatch }, value) {
    ApiService
        .post(
            '/api/v2/help-categories/save-weights' , value
        )
        .then()
        .catch(error => {
          commit("setError", error);
        })
        .finally(() => {
          dispatch("fetchData");
        });

  },

  setQuery({commit}, value) {
    commit("setQuery", value);
  },
  resetPageNumber({commit}){
    commit('resetPageNumber')
  },
  setPageNumber({commit} , value){
    commit('setPageNumber', value)
  },
  resetState({ commit }) {
    commit("resetState");
  }
};

const mutations = {
  setHelpCategoriesList(state, items) {
    state.helpCategoriesList = items;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  resetState(state) {
    state = Object.assign(state, initialState());
  },
  setQuery(state, query) {
    state.query = query;
    console.log(state.query);
  },
  setPagination(state, value) {
    state.pagination = value;
  },
  setPageNumber(state, value){
    state.pagination.current_page = value
  },
  setFetchedSingle(state , value) {
    state.singleClient = value;
  },
  resetPageNumber(state){
    state.pagination.current_page = 1
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
};
