import { createApp } from "vue";
import App from "./App.vue";
import { abilitiesPlugin } from '@casl/vue';
import localeDayJs from 'dayjs/locale/en-gb';
import localeElementPlus from 'element-plus/lib/locale/lang/en'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import SmoothScroll from "vue3-smooth-scroll";
import VueHotjar from 'vue-hotjar-next';

import "@/core/plugins/keenthemes";
import "@/core/plugins/prismjs";

const app = createApp(App);

app.use(store);
app.component("VueDatePicker", VueDatePicker);
app.use(router);
app.use(ElementPlus, {
    locale: {
        ...localeDayJs,
        el: localeElementPlus.el
    }
});
app.use(SmoothScroll);
app.use(abilitiesPlugin, store.getters.ability, {
    useGlobalProperties: true
})
ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

app.use(VueHotjar, {
    id: 2819198,
    isProduction: process.env.NODE_ENV === "production"
});

app.mount("#app");
