import ApiService from "../../core/services/ApiService";
import Swal from "sweetalert2";
import moment from "moment";

/* eslint-disable */
function initialState() {
  return {
    data: [],
    loading: false,
    branches: [],
    clients: [],
    countryID: "",
    showOwn: false,
    userId: null,
    dates: {
        start: moment().startOf('month').format("DD/MM/YYYY HH:mm"),
        end: moment().endOf('month').format("DD/MM/YYYY HH:mm")
    }
  }
}

const getters = {
  getData: state => state.data,
  loading: state => state.loading
}

const actions = {
  fetchData({commit, state}) {
    state.data = [];
    commit('setLoading', true);
    const countryFilter = `${state.countryID === "" ? '' : `&filter[country_id]=${state.countryID}`}`;
    const branchesFilter = `${state.branches.length == 0 ? '' : `&filter[branches]=${state.branches}`}`;
    const clientsFilter = `${state.clients.length == 0 ? '' : `&filter[companies]=${state.clients}`}`;
    const filterOwn = `${state.showOwn ? `&filter[own]=true` : ``}`;
    const userFilter = `${state.userId ? `&userId=${state.userId}` : ``}`;

    ApiService.get("api/v2", `calendar-jobs?startDate=${state.dates.start}&endDate=${state.dates.end}${countryFilter}${branchesFilter}${clientsFilter}${filterOwn}${userFilter}`)
              .then((res) =>{
                  commit("setData", res.data.data);
              })
              .catch((err) => {
                  console.log(err);
              })
              .finally(() => {
                  commit("setLoading", false);
              })
  },
  searchUsers({commit}, queryString) {
      return new Promise((resolve, reject) => {
          if("string" !== typeof queryString) {
              reject("Badly formed data");
          }
          ApiService.get('api/v2', `users-calendar-filter?userName=${queryString}`)
              .then(response => {
                resolve(response.data.data);
              })
              .catch(err => {
                reject(err);
              });
      })
  },
  setJobsDates({ commit, dispatch }, payload) {
    commit('setFilterDates', {
        start: moment(payload.start).format("DD/MM/YYYY HH:mm"),
        end: moment(payload.end).format("DD/MM/YYYY HH:mm")
    });
    dispatch("fetchData");
  },
  showOnlyMyJobs({ commit, dispatch }, payload) {
    commit('setOwnFilter', payload);
    dispatch("fetchData");
  },
  setSearchByCountry({ commit, dispatch }, payload) {
    commit("setCountryFilter", payload);
    dispatch("fetchData");
  },
  setSearchByBranch({ commit, dispatch }, payload) {
    commit("setBranchesFilter", payload);
    dispatch("fetchData");
  },
  setSearchByClient({ commit, dispatch }, payload) {
    commit("setClientsFilter", payload);
    dispatch("fetchData");
  },
  setSearchByUser({ commit }, payload) {
    commit("setUserSearch", payload);
  },
  resetState({ commit }) {
    commit("resetState");
  }
}

const mutations = {
  setData(state, value) {
    state.data = [];
    for(let i = 0; i < value.length ; i ++) {
        for(let x = 0; x < value[i].shifts.length; x++) {
            const event = {
                id: value[i].shifts[x].id,
                title: value[i].name,
                start: moment(value[i].shifts[x].start, "DD/MM/YYYY HH:mm").format(),
                end: moment(value[i].shifts[x].end, "DD/MM/YYYY HH:mm").format(),
                display: "block",
                shiftID: value[i].shifts[x].id,
                jobID: value[i].id,
                crew: value[i].shifts[x].crew,
                shift_slots: value[i].shifts[x].shift_slots 
            }
            state.data.push(event);
        }
    }
  },
  setUserSearch(state, payload) {
    state.userId = payload;
  },
  setCountryFilter(state, data) {
    state.countryID = data;
  },
  setBranchesFilter(state, data) {
    state.branches = data;
  },
  setClientsFilter(state, data) {
    state.clients = data;
  },
  setFilterDates(state, payload) {
      state.data = [];
      state.dates = payload;
  },
  resetState(state) {
    state = Object.assign(state, initialState());
  },
  setLoading(state, value) {
    state.loading = value;
  },
  setOwnFilter(state, value) {
    state.showOwn = value;
  }
}

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
}
