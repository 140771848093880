/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable prettier/prettier */
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2";
import router from "@/router";

function initialState() {
  return {
    roleId: [],
    user: {},
    fields: [],
    loading: false,
    possibleRoles: []
  };
}

const getters = {
  user: state => state.user,
  role: state => state.roleId,
  possibleRoles: state => state.possibleRoles,
  fields: state => state.fields,
  loading: state => state.loading
};

const actions = {
  setRoleId({ commit }, value) {
    commit("setRoleId", value);
  },
  getFields({ commit }, data) {
    return new Promise((resolve, reject) => {
        commit("setLoading", true);
        ApiService.get('api/v2', `userCreate/fields?role=${data.roleID}&step=${data.step}${data.fields ? `&payment=${data.fields}` : ''}`).then(res => {
            commit("setFields", res.data)
            commit("setLoading", false);
            resolve();
        }).catch(() => {
            reject()
        })
    })
  },
  fetchPossibleRoles({commit}) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/v2', `userCreate/possible-roles`).then(res => {
        commit("setPossibleRoles", res.data.data)
        resolve();
      }).catch(() => {
        reject()
      })
    })
  },
  resetState({commit}) {
    commit("resetState");
  },
  clearFieldsAndRole({ commit }) {
    commit("clearFieldsAndRole");
  },
  saveData({ commit }, data) {
    commit("saveData", data);
  },
  store({ commit, state }) {
    commit("setLoading", true)
    const data = {...state.user, role: state.roleId, approved: 1, status: "active"}
    ApiService.post('api/v2/users', data).then(() => {
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: `User created successfully!`,
        showConfirmButton: false,
        timer: 1500
      }).then(() => {
        commit("setLoading", false);
        router.push("/users");
      })
    }).catch(error => {
      commit("setLoading", false);
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: error.response.data.message,
        showConfirmButton: false,
        timer: 1500
      })
    })
  },
  
};

const mutations = {
  setRoleId(state, role) {
    if(state.roleId.includes(role)){
      state.roleId.splice(state.roleId.indexOf(role), 1)
    }else {
      state.roleId.push(role);
    }
  },
  setFields(state, data) {
    state.fields = data;
  },
  setLoading(state, status) {
    state.loading = status;
  },
  clearFieldsAndRole(state) {
    state.fields = [];
    state.user = {};
    state.roleId = [];
  },
  setPossibleRoles(state, payload) {
    state.possibleRoles = payload;
  },
  saveData(state, data) {
    if(!data.payment.isAtPaymentSettings) {
      state.user = { ...state.user, ...data.value};
    } else {
      const paymentInfo = {
        ...data.value,
        has_tax_id: data.payment.accountType == "TAXID" ? true : false,
        has_danish_bank: data.payment.accountType == "danish" ? true : false
      }
      state.user['payment_settings'] = JSON.stringify(paymentInfo);
    }
  },
  resetState(state) {
    state = Object.assign(state, initialState());
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
};
