import ApiService from "../../../core/services/ApiService";
import Swal from "sweetalert2";
import router from "@/router/index";
import { forEach } from "lodash";
/* eslint-disable */
function initialState() {
    return {
      isLoading : false
    }
}

const getters = {
  isLoading: state => state.isLoading
}

const actions = {
    createNew({ commit }, payload) {
        const formData = new FormData();
        forEach(Object.keys(payload), key => {
          formData.append(key, payload[key] != null ? payload[key] : "");
        })
        commit("setIsLoading", true);
        if(payload.issues_invoice == null) payload.issues_invoice = 0;
        ApiService.post('api/v2/register', formData)
                  .then(response => {
                    Swal.fire({
                        icon: 'success',
                        title: response.data.message,
                        showConfirmButton: true,
                        heightAuto: false,
                        timer: 2500
                      }).then(() => {
                        commit("setIsLoading", false);
                        router.push('/sign-in')
                      })
                  })
                  .catch(error => {
                    commit("setIsLoading", false);
                    let errorString = '';
                    if(!error.response.data.errors) {
                      errorString = "Sorry there was an error";
                    }else {
                      for (const key in error.response.data.errors) {
                          if(error.response.data.errors[key]. length == 1) errorString = error.response.data.errors[key][0];
                          else errorString = error.response.data.errors[key][1];
                      }
                    }
                      Swal.fire({
                        icon: 'error',
                        position: "top-end",
                        heightAuto: false,
                        title: errorString,
                        showConfirmButton: false,
                        timer: 1500,
                      })
                  })
    }
}
const mutations = {
    setIsLoading(state, payload) {
      state.isLoading = payload;
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
